import React from "react";

import styles from "./LoadingPage.module.scss";

export const LoadingCat = () => (
  <div className={styles.loadingCat}>
    <div className={styles.catBody}></div>
    <div className={styles.catAnimationMask}></div>
    <div className={styles.catHead}>
      <div className={styles.catFace}></div>
      <div className={styles.catEar}></div>
      <div className={styles.catHand}></div>
      <div className={styles.catEye}></div>
      <div className={styles.catEyeLight}></div>
      <div className={styles.catMouth}></div>
      <div className={styles.catBeardLeft}></div>
      <div className={styles.catBeardRight}></div>
    </div>
    <div className={styles.catFoot}>
      <div className={styles.catBelly}></div>
      <div className={styles.catLeg}></div>
      <div className={styles.catTail}></div>
    </div>
  </div>
);

const LoadingPage = () => {
  return (
    <div className={styles.loadingPage}>
      <LoadingCat />
    </div>
  );
};

export default LoadingPage;
