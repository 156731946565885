@import "colors.scss";

.loginScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: $grey;
  .loginWrapper {
    background: $white;
    box-shadow: 0 0 30px #00000025;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
  }
}
