import { Flex } from "@chakra-ui/react";
import { SpinnyCat } from "@ui-lib/index";
import { withRouter } from "react-router";
import { VelocityPrototypeLayout } from "../../components/Velocity";
import { useEffect, useState } from "react";
import { fetchIframeSourceDoc } from "../../services/studio";
import { useVelocityTestData } from "../../hooks/useVelocityTestData";

export const VelocityPrototype = withRouter(
  ({
    match: {
      params: { buildID },
    },
  }) => {
    const velocityTestData = useVelocityTestData(buildID) || [];
    const isLoading = false;
    const [creativeID] = buildID.split("-v");
    const [iframeSrcDoc, setIframeSrcDoc] = useState(null);

    useEffect(() => {
      if (creativeID && !iframeSrcDoc) {
        try {
          fetchIframeSourceDoc(creativeID).then((srcDoc) => {
            setIframeSrcDoc(srcDoc);
          });

          // fetchMetas(creativeID).then((meta) => {
          //   console.log("meta", meta);
          // }); <- doesn't seem to work as we don't have what it expects for velocity yet
        } catch (error) {
          console.error(error);
        }
      }
    }, [creativeID]);

    return (
      <Flex height="100%" direction="column" w="100%">
        {isLoading ? (
          <Flex mt="50px">
            <SpinnyCat />
          </Flex>
        ) : (
          <VelocityPrototypeLayout
            rechartsData={velocityTestData}
            iframeSrcDoc={iframeSrcDoc}
            buildId={buildID}
          />
        )}
      </Flex>
    );
  }
);
