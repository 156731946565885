import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "./CATComparisonSelector.module.scss";
import firebase from "@services/firestore";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { CATRouselViewerStore } from "../../store/CATRouselViewerStore";

const CATComparisonSelector = ({ scriptVersion, buildID }) => {
  const [creativeData, loadingCreativeData, errorCreativeData] =
    useCollectionOnce(
      firebase
        .firestore()
        .collection("CAT3")
        .where("scriptVersion", "==", scriptVersion)
    );
  const [availableBuilds, setAvailableBuilds] = useState([]);

  const comparisonCreative = CATRouselViewerStore(
    (state) => state.comparisonCreativeID
  );
  const setComparisonCreativeID = CATRouselViewerStore(
    (state) => state.setComparisonCreativeID
  );

  const comparisonCreativeChanged = CATRouselViewerStore(
    (state) => state.comparisonCreativeChanged
  );
  const setComparisonCreativeChanged = CATRouselViewerStore(
    (state) => state.setComparisonCreativeChanged
  );

  useEffect(() => {
    if (!loadingCreativeData && !errorCreativeData && creativeData) {
      const docs = creativeData.docs;
      const buildIDs = docs.map((doc) => {
        const info = doc.data();
        return {
          label: info.creativeNames + "-" + doc.id,
          value: doc.id,
        };
      });
      setAvailableBuilds(buildIDs);
    } else if (errorCreativeData) {
      console.error(errorCreativeData);
    }
  }, [loadingCreativeData, creativeData]);

  const handleChange = (selectedBuild) => {
    console.log(selectedBuild);
    if (selectedBuild === null) {
      // clear comparisonCreative in the database
      setComparisonCreativeID(null);
    } else {
      // set comparisonCreative in the database
      setComparisonCreativeID(selectedBuild.value);
    }
    setComparisonCreativeChanged(true);
  };

  const writeComparisonCreative = (id) => {
    const dataRef = firebase.firestore().collection("CAT-Rousel").doc(buildID);
    dataRef.set({ comparisonCreative: id }, { merge: true });
    setComparisonCreativeChanged(false);
  };

  return (
    <div className={styles.comparisonSelector}>
      <div className={styles.title}>Standard Creative Build ID:</div>
      <Select
        className={styles.select}
        options={availableBuilds}
        isClearable={true}
        value={
          comparisonCreative !== null
            ? { label: comparisonCreative, value: comparisonCreative }
            : null
        }
        onChange={handleChange}
      />
      <div
        className={`${styles.actionButton} ${
          comparisonCreativeChanged ? styles.save : null
        }`}
        onClick={() =>
          comparisonCreativeChanged
            ? writeComparisonCreative(comparisonCreative)
            : null
        }
      >
        Save Changes
      </div>
    </div>
  );
};

export default CATComparisonSelector;
