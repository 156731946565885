import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  ChakraProps,
  Divider,
  Highlight,
  Icon,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useRef } from "react";
import ruler from "../../assets/images/ruler.png";

export const Hangtime = ({ ...rest }: ChakraProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const hoveringRef = useRef(false);

  const handleMouseLeave = () => {
    setTimeout(() => {
      if (!hoveringRef.current) {
        onClose();
      }
    }, 500);
  };

  return (
    <>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        placement="left"
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <Text
            {...linkStyle}
            backgroundColor={isOpen ? "gray.50" : "transparent"}
            color={isOpen ? "teal.700" : "gray.600"}
            onClick={onOpen}
            onMouseEnter={() => (onOpen(), (hoveringRef.current = true))}
            onMouseLeave={() => {
              handleMouseLeave();
              hoveringRef.current = false;
            }}
            {...rest}
          >
            <Icon as={InfoIcon} mr={2.5} mt="-0.5" />
            How do we measure this?
          </Text>
        </PopoverTrigger>
        <PopoverContent
          {...popoverContainerStyle}
          onMouseEnter={() => (hoveringRef.current = true)}
          onMouseLeave={() => {
            handleMouseLeave();
            hoveringRef.current = false;
          }}
        >
          <PopoverArrow />
          <PopoverBody
            as={VStack}
            spacing={4}
            alignItems="flex-start"
            pos="relative"
          >
            <Text
              fontSize="lg"
              color="gray.600"
              fontWeight="bold"
              fontFamily={"roboto mono, monospace"}
            >
              How do we measure this?
            </Text>
            <Divider />
            <Text fontSize="sm" color="gray.600">
              As your creative ran, we measured how users scrolled while the ad
              filled the screen. When a user backtracks or pauses for long
              enough, we classify them as ‘engaged,’ and we start measuring
              exactly which parts of the ad they are scrubbing, pausing, and
              backtracking on.
            </Text>
            <Text fontSize="sm" color="gray.600">
              We can then tally up to 5000 of these engaged sessions and chart
              them, highlighting the creative elements of your ad where people
              are playing or staying.
            </Text>
            <Box
              boxShadow="rgba(0, 0, 0, 0.2) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset, rgba(20, 20, 20, 0.1) 0px 30px 150px"
              position="absolute"
              bottom={-10}
              right={-24}
              transform={"rotate(10deg)"}
              background="white"
              borderRadius="md"
            >
              <Image src={ruler} w="78px" opacity={0.7} />
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

const linkStyle = {
  fontSize: ["xs", "sm"],
  cursor: "pointer",
  px: 2.5,
  py: 1,
  textUnderlineOffset: 3,
  textDecoration: "underline",
  textDecorationStyle: "dotted",
  borderRadius: "md",
  ml: "-1.5", // align with the text
};

const popoverContainerStyle = {
  zIndex: 100,
  mt: "50px",
  minWidth: "400px",
  py: 4,
  pb: 8,
  pl: 2,
  pr: 20,

  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 2px 4px, rgba(0, 0, 0, 0.15) 0px 7px 13px -3px, rgba(0, 0, 0, 0.1) 0px -3px 0px inset, rgba(20, 20, 20, 0.3) 0px 30px 150px",
};
