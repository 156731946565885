import styled from "@emotion/styled";
import React, { forwardRef } from "react";
interface TextProps extends React.HTMLProps<HTMLParagraphElement> {
  type: "baseline" | "body01" | "body02";
  as?: "p" | "span" | "b" | "a";
}

const StyledText = styled.p<{ kind: TextProps["type"] }>`
  font-style: normal;
  font-size: var(--text-${({ kind }) => kind}-fontSize);
  line-height: var(--text-${({ kind }) => kind}-lineHeight);
  letter-spacing: var(--text-${({ kind }) => kind}-letterSpacing);
  color: var(--colors-text);
`;

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  ({ type, as = "p", children, ...restProps }: TextProps, ref = null) => {
    return (
      <StyledText as={as} ref={ref} kind={type} {...restProps}>
        {children}
      </StyledText>
    );
  }
);
