import { css, Global } from "@emotion/react";
import { useCreatives } from "@stores/creatives";
import { FalafelLoader } from "@ui-lib";
import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { getChartColor } from "../../util/dcoaUtils";

export const VideoDropOffCompare = ({
  buildId,
  onAreaSelected,
  recommendations,
  selectedVideo,
}) => {
  const [creative = null] = useCreatives((state) => state.items);
  const addCreatives = useCreatives((state) => state.create);
  const loadCreativeSequences = useCreatives(
    (state) => state.loadCreativeSequences
  );

  useEffect(() => {
    addCreatives([buildId]);
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    !creative?.sequences && loadCreativeSequences();

    if (creative?.videoSequences) {
      setData(
        Object.entries(creative.videoSequences).map(
          ([key, { videoDropOffCollector }]) => {
            const [_, filename] = key.split("_");
            const { id = null } =
              recommendations.find((item) => {
                return new RegExp(filename).test(item.videoSource);
              }) || {};

            return { key, id, videoDropOffCollector };
          }
        )
      );
    }
  }, [creative]);

  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    setRefAreaLeft("");
    setRefAreaRight("");
  }, [selectedVideo]);

  return data.length ? (
    <>
      <Global
        styles={css`
          .col-resize-hover {
            path {
              transition: var(--transitions-0);
            }

            &:hover {
              ${selectedVideo ? `cursor: col-resize;` : ""}
            }
          }
        `}
      />
      <ResponsiveContainer width={"80%"} aspect={5 / 3} maxHeight={270}>
        <AreaChart
          data={Array.from({ length: 100 }, (_, i) => ({
            timeline: i,
            ...data
              .map(({ key, videoDropOffCollector }) => ({
                key,
                value: videoDropOffCollector[i],
              }))
              .reduce((acc, { key, value }) => {
                acc[key] = value;
                return acc;
              }, {}),
          }))}
          margin={{
            top: 0,
            right: 10,
            left: 50,
            bottom: 20,
          }}
          onMouseDown={(e) => {
            if (!selectedVideo) return;
            setDragging(true);
            setRefAreaLeft(e.activeLabel);
            setRefAreaRight(null);
          }}
          onMouseMove={(e) => {
            if (!selectedVideo) return;
            dragging && refAreaLeft && setRefAreaRight(e.activeLabel);
          }}
          onMouseUp={() => {
            if (!selectedVideo) return;
            setDragging(false);

            onAreaSelected(
              refAreaLeft < refAreaRight
                ? {
                    start: refAreaLeft / 100,
                    end: refAreaRight / 100,
                  }
                : {
                    start: refAreaRight / 100,
                    end: refAreaLeft / 100,
                  }
            );
          }}
        >
          <CartesianGrid stroke="#e8e8e8" strokeDasharray="3 3" />
          <XAxis
            dataKey="timeline"
            type="number"
            tickLine={true}
            domain={[0, 100]}
            allowDataOverflow={true}
            axisLine={false}
            ticks={["Video start", "Video end"]}
          >
            <Label
              value="Video start"
              offset={0}
              position="insideLeft"
              style={{
                fontSize: "100%",
                fill: "#e0e0e0",
              }}
            />
            <Label
              value="Video end"
              offset={0}
              position="insideRight"
              style={{
                fontSize: "100%",
                fill: "#e0e0e0",
              }}
            />

            <Label
              value="Video Drop off Rate (%)"
              offset={-40}
              angle={-90}
              position="insideTopLeft"
              style={{
                fontSize: "130%",
                fill: "#c5c5c5",
              }}
            />
          </XAxis>
          <defs>
            {data.map(({ key }, i) => (
              <linearGradient
                key={key}
                id={`color${key}`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor={getChartColor(i)}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={getChartColor(i)}
                  stopOpacity={0}
                />
              </linearGradient>
            ))}
          </defs>
          <YAxis axisLine={false} tickLine={false} hide></YAxis>

          {data.map(({ key, id }, i) => {
            const stroke = getChartColor(i);
            const fill = `url(#color${key})`;

            return (
              <Area
                className="col-resize-hover"
                opacity={selectedVideo && selectedVideo !== id ? 0.2 : 1}
                key={key}
                type="monotone"
                dataKey={key}
                stroke={stroke}
                strokeWidth={selectedVideo && selectedVideo !== id ? 2 : 4}
                fill={fill}
                isAnimationActive={false}
                {...(selectedVideo && selectedVideo !== id
                  ? { strokeDasharray: "5,5" }
                  : {})}
              />
            );
          })}

          {refAreaLeft && refAreaRight && (
            <ReferenceLine
              x={refAreaLeft}
              stroke="var(--colors-neutral-2)"
              strokeWidth="1px"
              isFront={true}
            />
          )}
          {refAreaLeft && refAreaRight && (
            <ReferenceLine
              x={refAreaRight}
              stroke="var(--colors-neutral-2)"
              strokeWidth="1px"
              isFront={true}
            />
          )}
          {refAreaLeft && refAreaRight && (
            <ReferenceArea
              x1={refAreaLeft}
              x2={refAreaRight}
              stroke="var(--colors-neutral-3)"
              strokeOpacity={0.3}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </>
  ) : (
    <FalafelLoader />
  );
};
