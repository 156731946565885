import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

export const InputSlider = ({ showTrack = true, ...props }) => {
  return (
    <Slider {...props}>
      <SliderTrack opacity={showTrack ? 1 : 0} h="1.5" borderRadius="full">
        <SliderFilledTrack opacity={showTrack ? 1 : 0} transition='0s linear' as={motion.div} />
      </SliderTrack>
      <SliderThumb height="32px" width="6px" bg="gray.500" transition='0s linear' as={motion.div} />
    </Slider>
  );
};
