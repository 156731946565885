import {
  Box,
  Button,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { Subheader } from "@composites/Subheader/Subheader";
import {
  LeftPanel,
  RightPanel,
} from "@controllers/CreativeViewer/indexCreativeViewer";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { useRef, useState } from "react";
import { AdLayout } from "./AdLayout";
import { GaugeDistance } from "./demos/GaugeDistance";
import { SimpleGauge } from "./demos/SimpleGauge";
import { GaugeScroll } from "./demos/GaugeScroll";
import { GaugeLiveScribble } from "./demos/GaugeLiveScribble";
import { SimpleScrub } from "./demos/SimpleScrub";

export const VelocityPrototypeLayout = ({
  rechartsData,
  iframeSrcDoc,
  buildId,
}) => {
  const mainFrameRef = useRef(null);
  const [demoTabIdx, setDemoTabIdx] = useState(0);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [isHoveringIframe, setIsHoveringIframe] = useState(false);

  const iframeState = {
    iframeLoaded,
    isHoveringIframe,
  };

  return (
    <VStack width="100%" height="calc(100vh - 50px)" pt={"50px"}>
      <Subheader
        title={
          <HStack spacing={4} w="100%" alignItems="center" color="gray.600">
            <Text as="span" color="gray.600">
              Velocity Prototype:
            </Text>
            <Tabs
              isLazy
              index={demoTabIdx}
              onChange={setDemoTabIdx}
              variant="soft-rounded"
              colorScheme="gray"
              size="sm"
            >
              <TabList>
                <Tab>Simple Scrub</Tab>
                <Tab>Simple Gauge</Tab>
                <Tab>Gauge and Distance</Tab>
                <Tab>Gauge and Scroll Chart</Tab>
                <Tab>Gauge with Live Scribble</Tab>
                {/* <Divider orientation="vertical" />
                <Tab>Scroll chart</Tab>
                <Tab>Session Pressure</Tab> */}
              </TabList>
            </Tabs>
          </HStack>
        }
      ></Subheader>
      <HStack
        maxWidth="var(--maxScreenWidth)"
        width="100%"
        height="100%"
        align="flex-start"
      >
        <LeftPanel>
          {iframeSrcDoc && (
            <AdLayout
              srcDoc={iframeSrcDoc}
              onIframeLoad={() => setIframeLoaded(true)}
              mainFrameRef={mainFrameRef}
              onMouseEnter={() => setIsHoveringIframe(true)}
              onMouseLeave={() => setIsHoveringIframe(false)}
            />
          )}
        </LeftPanel>
        <RightPanel>
          <Tabs index={demoTabIdx} isLazy>
            <TabPanels>
              {/* Simple Scrub ------------------------------------------------ */}
              <TabPanel as={VStack} spacing={4}>
                <SimpleScrub
                  rechartsData={rechartsData}
                  buildId={buildId}
                  mainFrameRef={mainFrameRef}
                  iframeState={iframeState}
                />
              </TabPanel>
              {/*  */}
              {/* Simple Gauge  ------------------------------------------------ */}
              <TabPanel as={VStack} spacing={1} w="100%">
                <SimpleGauge
                  rechartsData={rechartsData}
                  iframeState={iframeState}
                  buildId={buildId}
                  mainFrameRef={mainFrameRef}
                />
              </TabPanel>
              {/*  */}
              {/* Gauge and Distance ------------------------------------------------ */}
              <TabPanel as={Flex} direction={"column"} alignItems={"center"}>
                <GaugeDistance
                  mainFrameRef={mainFrameRef}
                  rechartsData={rechartsData}
                  iframeState={iframeState}
                  buildId={buildId}
                />
              </TabPanel>
              {/*  */}
              {/* Gauge and Scrub ------------------------------------------------ */}
              <TabPanel as={VStack} spacing={4}>
                <GaugeScroll
                  rechartsData={rechartsData}
                  buildId={buildId}
                  mainFrameRef={mainFrameRef}
                  iframeState={iframeState}
                />
              </TabPanel>

              {/*  */}
              {/* Gauge with Live scribble ------------------------------------------------ */}
              <TabPanel as={VStack} spacing={4}>
                <GaugeLiveScribble
                  rechartsData={rechartsData}
                  buildId={buildId}
                  mainFrameRef={mainFrameRef}
                  iframeState={iframeState}
                />
              </TabPanel>

              {/* OLD  ------------------------------------------------ */}

              {/*  */}
              {/* Scroll Chart ------------------------------------------------ */}
              {/* <TabPanel as={VStack} spacing={4}> */}
              {/* <ScrollControlls
                  scrollStatus={scrollStatus}
                  scrollActions={scrollActions}
                /> */}
              {/* <MockChart
                  data={scrollSquiggle}
                  scrollStatus={scrollStatus}
                  scrollActions={scrollActions}
                />
              </TabPanel> */}
              {/*  */}
              {/* Pressure Chart ------------------------------------------------ */}
              {/* <TabPanel as={VStack} spacing={4}> */}
              {/* <ScrollControlls
                  scrollStatus={scrollStatus}
                  scrollActions={scrollActions}
                /> */}
              {/* <PressureChart
                  data={sessionPressureArray}
                  scrollStatus={scrollStatus}
                  scrollActions={scrollActions}
                />
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </RightPanel>
      </HStack>
    </VStack>
  );
};

const PressureChart = ({ data, scrollStatus, scrollActions }) => {
  return (
    <>
      <Box position="relative" w="100%">
        <ResponsiveContainer width={"100%"} aspect={5 / 3} maxHeight={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            {/* type="number" makes sure the x-axis scales correctly */}
            <XAxis
              dataKey="accumulatedTime"
              hide={true}
              type="number"
              interval={"preserveStart"}
              domain={["dataMin", "dataMax"]}
            />
            <YAxis dataKey="pressure" hide={true} />
            <Line
              strokeWidth={2}
              dot={false}
              type="monotone"
              dataKey="pressure"
              stroke="#8884d8"
            />
          </LineChart>
        </ResponsiveContainer>
        <Slider
          key="scroll-slider"
          position="absolute!important"
          focusThumbOnChange={false}
          overflow="hidden"
          top="0"
          display="flex"
          alignItems="flex-end"
          h="100%"
          value={(scrollStatus.currentProgress / 100) * 100}
          onChange={(value) => {
            scrollActions.playFromPercent(value);
          }}
          colorScheme="blackAlpha"
        >
          <SliderTrack bottom="0" bg="none" h="100%">
            <SliderFilledTrack
              bg="teal.100"
              opacity={0.1}
              style={{ boxShadow: "1px 0 10px 0px teal" }}
            />
          </SliderTrack>
          <SliderThumb h="100%" w="3px" bg="teal.200" />
        </Slider>
      </Box>
    </>
  );
};
