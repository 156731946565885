@import "colors.scss";

.demoControls {
  width: 100%;
  display: flex;
  justify-content: center;
  > div {
    cursor: pointer;
    user-select: none;
    padding: 10px 20px;
    border-radius: 20px;
    color: $white;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: 10px;
    }
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    a {
      text-decoration: none;
      color: $grey;
    }
  }
  .flush {
    background: $darkpinkgradient;
  }
  .howTo {
    color: $grey;
    cursor: pointer;
  }
}
