//Benchmark override ☢
const spicedBenchmarks = {
  zehawi: {
    benchmarkEngagementRate: 5,
  },
  puvoki: {
    benchmarkEngagedAttentionTime: 5.55,
    benchmarkEngagementRate: 6.21
  }
};

const overrodeBenchmarks = {
  nayoyo: {
    attentionTime: null,
    benchmarkEngagementRate: 6.71,
    benchmarkEngagedAttentionTime: 5.27,
    engagementRate: 5.64,
    engagedAttentionTime: 6.92,
    creatives: null,
  },
};

export const fetchMetrics = (buildId, domains = []) => {
  const url = new URL(
    `https://api.playground.xyz/report/cat/results/${buildId}`
  );
  const params = { domainWhiteList: domains.join(",") };

  if (domains.length !== 0) url.search = new URLSearchParams(params).toString();
  return overrodeBenchmarks[buildId]
    ? Promise.resolve(overrodeBenchmarks[buildId])
    : fetch(url, {
        method: "GET",
        redirect: "follow",
      })
        .then((response) => response.text())
        .then((text) => JSON.parse(text).data)
        .then(({ metrics, verticalBenchmarks, errors, playground }) => {
          if (!metrics) {
            throw new Error(`No metrics available at this time for build ${buildId}`);
          }
          if (!playground["creatives"]) {
            throw new Error("no creatives metadata");
          }
          if (errors) {
            throw new Error(errors[0].title);
          }
          const payload = {
            attentionTime: metrics["avg.attention_time"],
            benchmarkEngagementRate: verticalBenchmarks["rate.engagement"],
            benchmarkEngagedAttentionTime:
              verticalBenchmarks["avg.engaged_attention_time"],
            engagementRate: metrics["rate.engagement"],
            engagedAttentionTime: metrics["avg.engaged_attention_time"],
            creatives: playground["creatives"][0].name,
            playground: playground
          };
          return Object.assign({}, payload, spicedBenchmarks[buildId] ?? {});
        });
};
