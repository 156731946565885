export function interpolateColor({
  value,
  minValue,
  maxValue,
  startColor,
  endColor,
}) {
  // Ensure the value is within bounds
  const clampedValue = Math.min(Math.max(value, minValue), maxValue);

  // Calculate the ratio of the value within the range
  const ratio = (clampedValue - minValue) / (maxValue - minValue);

  // Interpolate the color components
  const r = Math.round(startColor.r + (endColor.r - startColor.r) * ratio);
  const g = Math.round(startColor.g + (endColor.g - startColor.g) * ratio);
  const b = Math.round(startColor.b + (endColor.b - startColor.b) * ratio);

  // Return the interpolated color in hex format
  return `rgb(${r}, ${g}, ${b})`;
}

export function averageArrayOfInts(arr) {
  return arr.reduce((acc, curr) => acc + curr, 0) / arr.length;
}
