import { Flex } from "@chakra-ui/react";
import React, { useRef } from "react";
import {
  Area,
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ScrubLabels } from "../Charts/ScrubLabels";
import { InfoTooltipText } from "../../components/Chakra/InfoTooltipText";

export const Chart = ({
  data,
  progress,
  showBaseline = true,
  videoDuration,
  videoChange,
  showLegacyVideoChart,
}) => {
  const cursorPositionRef = useRef(0);
  // -----------------------------------------------------
  // should be in presentation mode?
  const queryParams = new URLSearchParams(location.search);
  const isPresentationMode = queryParams.get("layoutMode") === "presentation";
  // this is the URL param that is passed thorugh when gif-generator
  // is viewing the page to create a gif for the slide deck

  return (
    <>
      <Flex
        position={"relative"}
        justifyContent={"center"}
        display={isPresentationMode ? "none" : "flex"}
      >
        <InfoTooltipText
          text="Video Stickiness"
          tooltipText={`How well a video retained viewers when compared to the average for ${videoDuration} second videos.`}
        />
      </Flex>
      <ResponsiveContainer
        width={"100%"}
        aspect={5 / 3}
        maxHeight={isPresentationMode ? 500 : 270}
      >
        <ComposedChart
          onClick={() => {
            if (cursorPositionRef.current) {
              videoChange(cursorPositionRef.current);
            }
          }}
          data={data}
          margin={{
            top: 20,
            right: 10,
            left: 50,
          }}
        >
          <CartesianGrid stroke="#e8e8e8" strokeDasharray="3 3" />
          <XAxis
            dataKey="timeline"
            type="number"
            tickLine={true}
            domain={[0.5, 99.5]}
            allowDataOverflow={true}
            axisLine={false}
            ticks={["Video start", "Video end"]}
          ></XAxis>
          <Tooltip
            content={(props) => {
              if (!props.payload[0]?.payload?.timeline) return <div></div>;
              cursorPositionRef.current = props.payload[0]?.payload?.timeline;
              return <div></div>;
            }}
          />
          <defs>
            <linearGradient id="linear" gradientTransform="rotate(90)">
              <stop offset="25%" stopColor="#00CE7C" />
              <stop offset="100%" stopColor="#4AC1E0" />
            </linearGradient>
            <linearGradient id="linear2" gradientTransform="rotate(90)">
              <stop offset="25%" stopColor="#4AC1E0" />
              <stop offset="100%" stopColor="#00CE7C" />
            </linearGradient>
          </defs>
          <YAxis
            hide
            axisLine={false}
            // adding ticks back?
            // check github commits prior to september '24
            // for previous styling
          ></YAxis>

          {showLegacyVideoChart ? (
            <Area
              type="monotone"
              dataKey="inView"
              stroke="url(#linear)"
              strokeWidth={2}
              isAnimationActive={false}
              fill="#ffffff00"
            />
          ) : (
            <>
              <Bar
                type="monotone"
                dataKey="difference"
                fill="url(#linear)"
                isAnimationActive={false}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry.difference >= 0 ? "url(#linear2)" : "url(#linear)"
                    }
                  />
                ))}
              </Bar>
              <ReferenceLine
                y={0}
                fill="#000"
                stroke="#c4c4c4"
                strokeWidth="3px"
                isFront={true}
              />
            </>
          )}
          {showBaseline && showLegacyVideoChart && (
            <Area
              type="monotone"
              dataKey="baseline"
              stroke="#c5c5c5"
              strokeWidth={2}
              fillOpacity={0.1}
              fill="#c5c5c5"
              isAnimationActive={false}
            />
          )}
          <ReferenceLine
            x={progress}
            fill="#000"
            stroke="rgba(0,0,0,0.15)"
            strokeWidth="4px"
            isFront={true}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <ScrubLabels
        top="4"
        xAxisLabel="" //"Video Progress"
        yAxisLabel={
          showLegacyVideoChart
            ? "Video Attention Rate (%)"
            : `Viewer retention vs. ${videoDuration}sec Av`
        }
        bottomLeftLabel="" //"Video start"
        bottomRightLabel="" //"Video end"
      />
    </>
  );
};
