import create from "zustand";

const GUIStore = create((set, get) => ({
  showGUI: false,
  minSequenceLength: 1,
  showDwellTime: false,
  startProgress: 30,
  endProgress: 65,
  triggerType: "scrub",
  domainWhiteList: [],
  baselineOverride: null,

  // 👇 used in Single Video Chart
  showLegacyVideoChart: false,
  useLegacyBaseline: false,
  turnOffBaselineCompensation: false,

  GUIToggle: () => {
    set((prev) => {
      return { showGUI: !prev.showGUI };
    });
  },
  setShowDwellTime: (value) => {
    set({ showDwellTime: value });
  },
  setMinSequenceLength: (value) => {
    set({ minSequenceLength: value });
  },
  setStartProgress: (value) => {
    set({ startProgress: value });
  },
  setEndProgress: (value) => {
    set({ endProgress: value });
  },
  setTriggerType: (value) => {
    set({ triggerType: value });
  },
  setDomainWhiteList: (value) => set({ domainWhiteList: value }),
  setBaselineOverride: (value) => set({ baselineOverride: value }),
  // 👇 used in Single Video Chart
  setShowLegacyVideoChart: (state) => {
    set({ showLegacyVideoChart: state });
  },
  setUseLegacyBaseline: (state) => {
    set({ useLegacyBaseline: state });
  },
  setTurnOffBaselineCompensation: (state) => {
    set({ turnOffBaselineCompensation: state });
  },
}));

export { GUIStore };
