import domainsList from "@data/domainWhiteList";
import { GUIStore } from "@stores/GUIStore";
import React, { useEffect } from "react";
import { withShootRender } from "../../controllers/Portals/index.js";
import { logAnalytics } from "@utils/analyticsUtil";
import { GUIFootnote, GUIRow, GUISwitch, GUIWrapper } from "./";

export const ABGUI = withShootRender(() => {
  const showGUI = GUIStore((state) => state.showGUI);
  const GUIToggle = GUIStore((state) => state.GUIToggle);
  const setDomainWhiteList = GUIStore((state) => state.setDomainWhiteList);

  // use effects for now, probably a better way to do this
  useEffect(() => {
    if (showGUI) {
      logAnalytics.guiPanel("open");
    }
  }, [showGUI]);

  const handleWhitelistChange = (value) => {
    setDomainWhiteList(value ? domainsList : []);
    logAnalytics.guiAdjust(
      'setDomainWhiteList', value
    );
  };


  return (
    <GUIWrapper show={showGUI} onToggle={GUIToggle}>
      <GUIRow title={"Domain Filter"}>
        <GUISwitch
          onChange={handleWhitelistChange}
        />
      </GUIRow>
      <GUIFootnote>
        *This is an experimental feature. None of the settings will be stored.
      </GUIFootnote>
    </GUIWrapper>
  );
});
