import styled from "@emotion/styled";
import React, { forwardRef } from "react";
import closeIconPath from "../assets/icons/close.svg";

interface IconButtonProps {
  onClick: () => void;
  iconPath?: string;
}

const IconButtonRoot = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: var(--space-1);
  &:hover {
    opacity: 0.5;
  }
`;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ iconPath, ...restProps }, ref) => {
    return (
      <IconButtonRoot {...restProps} ref={ref}>
        <img src={(iconPath = closeIconPath as unknown as string)} />
      </IconButtonRoot>
    );
  }
);
