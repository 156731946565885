function delay(ms, cb) {
  return new Promise((resolve) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      if (cb) cb();
      resolve();
    }, ms);
  });
}

// dodgy - just need to reference this in multiple places so just want it set once
const heatmapColors = [100, 200, 300, 400, 500, 600];

function getBrightness(value) {
  const brightnessArray = heatmapColors;
  const brightnessIndex = getClosestIndex(value, brightnessArray);
  return brightnessArray[brightnessIndex];
}

function getClosestIndex(value, arr) {
  let closestIndex = 0;
  let closestDifference = Math.abs(value - arr[0]);

  for (let i = 1; i < arr.length; i++) {
    const difference = Math.abs(value - arr[i]);

    if (difference < closestDifference) {
      closestDifference = difference;
      closestIndex = i;
    }
  }
  return closestIndex;
}

function changeArrayRange(arr) {
  const newMin = heatmapColors[0];
  const newMax = heatmapColors[heatmapColors.length - 1];
  // Find the current minimum and maximum values in the array
  const currentMin = Math.min(...arr);
  const currentMax = Math.max(...arr);

  // Calculate the range of the current values
  const currentRange = currentMax - currentMin;

  // Calculate the range of the new values
  const newRange = newMax - newMin;

  // Iterate over the array and reassign the values
  const transformedArray = arr.map((value) => {
    // Calculate the percentage of the current value within the current range
    const percentage = (value - currentMin) / currentRange;

    // Map the percentage to the new range and add the new minimum
    const transformedValue = percentage * newRange + newMin;

    // Round the transformed value to the nearest integer
    return Math.round(transformedValue);
  });

  return transformedArray;
}

/*
 * Returns a color (red, green, gray) based on the value.
 */
function valueToColor(value, style = "light") {
  if (style === "light") {
    return value > 0 ? "green.400" : value < 0 ? "red.300" : "gray.400";
  }
  if (style === "dark") {
    return value > 0 ? "green.600" : value < 0 ? "red.600" : "gray.500";
  }
}

function findClosestIndex(array, value) {
  // Normalize the value to the number of indexes in the array
  const normalizedValue = value * (array.length - 1);

  // Find the closest index
  let closestIndex = 0;
  let closestDifference = Math.abs(normalizedValue - closestIndex);

  for (let i = 1; i < array.length; i++) {
    const difference = Math.abs(normalizedValue - i);
    if (difference < closestDifference) {
      closestIndex = i;
      closestDifference = difference;
    }
  }

  return closestIndex;
}

function playHistory(history, setValue, speed = 500) {
  for (let i = 0; i < history.length; i++) {
    delay(speed * i, () => setValue(i));
  }
}

/**
 * Calculates in percent, the change between 2 numbers.
 * e.g from 1000 to 500 = 50%
 *
 * @param oldNumber The initial value
 * @param newNumber The value that changed
 */
function getPercentageChange(oldNumber, newNumber) {
  var decreaseValue = oldNumber - newNumber;

  return (decreaseValue / oldNumber) * 100;
}

exports.getPercentageChange = getPercentageChange;
exports.findClosestIndex = findClosestIndex;
exports.delay = delay;
exports.valueToColor = valueToColor;
exports.getClosestIndex = getClosestIndex;
exports.getBrightness = getBrightness;
exports.changeArrayRange = changeArrayRange;
exports.playHistory = playHistory;
