import Backdrop from "@components/UI/Backdrop/Backdrop";
import { withShootRender } from "@controllers/Portals";
import styled from "@emotion/styled";
import { IconButton, Text } from "@ui-lib";
import React from "react";

const InlineModalRoot = styled.div`
  ${(props) =>
    props.isInline
      ? `

position: absolute;
right: 0;
  `
      : ""}

  min-width: 300px;

  transform: ${(props) =>
    props.show ? "translateY(0)" : "translateY(-100vh)"};
  opacity: ${(props) => (props.show ? 1 : 0)};
  padding: var(--space-3) ;

  border: solid 1px var(--colors-neutral-4);
  background: white;
  border-radius: var(--radii-0);
  z-index: 9999;
  box-shadow: 0px 13px 46px 0px rgba(0, 0, 0, 0.06);
  transition: opacity 0.1s ease-in-out;
`;

const InlineModalBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: var(--space-3);
`;

const InlineModalHeader = styled.header`
  color: var(--colors-neutral-0);
  border-bottom: 1px solid var(--colors-neutral-4);
  margin-bottom: var(--space-3);
  display: flex;
  align-items: center;
`;

const InlineModalCloseSlot = styled.div`
  position: absolute;
  right: var(--space-1);
  top: var(--space-1);
`;

const ShootBackdrop = withShootRender(Backdrop);

const InlineModal = ({ show, handleClose, title, children }) => {
  return (
    <>
      <ShootBackdrop show={show} clicked={handleClose} color="none" />
      <InlineModalRoot show={show} isInline={true}>
        <InlineModalHeader>
          <Text type="baseline">{title}</Text>
          <InlineModalCloseSlot>
            <IconButton onClick={handleClose} />
          </InlineModalCloseSlot>
        </InlineModalHeader>
        <InlineModalBody>{children}</InlineModalBody>
      </InlineModalRoot>
    </>
  );
};

export default InlineModal;
