import React from "react";
import { Link } from "react-router-dom";
import LogOut from "../../components/LogOut/LogOut";
import styles from "./Header.module.scss";
import catLogo from "../../assets/images/gg-cat-logo-lockup.svg";
import { Button, HStack } from "@chakra-ui/react";

let logo;

function Header({ loggedIn, text }) {
  const queryParams = new URLSearchParams(window.location.search);
  const isPresentationMode = queryParams.get("layoutMode") === "presentation";

  if (loggedIn) {
    logo = (
      <div className={styles.headerWrapper}>
        <Button as={Link} to="/" variant="ghost" px={0}>
          <div className={styles.cat}>
            <img src={catLogo} alt="logo" className={styles.catLogo} />
          </div>
        </Button>
        {/* <div className={styles.nav}>
          <NavLink exact={true} to="/" activeClassName={styles.active}>
            <FontAwesomeIcon icon={faList} />
            Creatives
          </NavLink>
          <NavLink
            exact={true}
            to="/creative/nayoyo"
            activeClassName={styles.active}
          >
            <FontAwesomeIcon icon={faChartBar} />
            Sample Creative
          </NavLink>
          <NavLink exact={true} to="/demo" activeClassName={styles.active}>
            <FontAwesomeIcon icon={faLaptop} />
            Demo Mode
          </NavLink>
        </div> */}
        <LogOut />
      </div>
    );
  } else {
    logo = (
      <div className={`${styles.cat} ${styles.noLink}`}>
        <img src={catLogo} alt="logo" className={styles.catLogo} />
      </div>
    );
  }

  return (
    <HStack
      className={styles.header}
      display={isPresentationMode ? "none" : "flex"}
    >
      <div className={styles.headerInner}>{logo}</div>
    </HStack>
  );
}

export default Header;
