import styled from "@emotion/styled";
import debounce from "lodash/debounce";

const IFRAME_SCALE_FACTOR = 0.8;

import mobileFrame from "../../assets/images/iphonexcrop.png";
import { useCallback, useEffect, useRef, useState } from "react";

import {
  Box,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";

export const AdLayout = ({
  srcDoc,
  mainFrameRef,
  onIframeLoad,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const canStillTriggerPopoverRef = useRef(true);
  const preventUserScroll = false;
  const showWrapper = true;

  const handleMouseEnter = (e) => {
    canStillTriggerPopoverRef.current = false;
    onClose();
    onMouseEnter(e);
  };

  const launchPopover = () => {
    if (canStillTriggerPopoverRef.current) {
      onToggle();
    }
  };
  useEffect(() => {
    setTimeout(() => {
      launchPopover();
    }, 13000);
  }, []);

  // handle scaling of the device for layout responsivness
  const [scale, setScale] = useState(0.8);
  const iframeWrapperRef = useRef(null);

  const handleResize = useCallback(() => {
    const iframe = iframeWrapperRef.current;
    if (iframe && iframe.parentElement) {
      const parentHeight = iframe.parentElement.clientHeight;
      setScale((parentHeight / 745) * IFRAME_SCALE_FACTOR);
    }
  }, []);

  const handleOnIframeLoad = (e) => {
    handleResize();
    onIframeLoad(e);
  };

  const debouncedHandleResize = debounce(handleResize, 100); // Adjust debounce time as needed

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <IframeWrapper
      ref={iframeWrapperRef}
      className="iframe-wrapper"
      alignItems="center"
    >
      <Popover
        autoFocus={false}
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <DeviceFrame transform={`scale(${scale})`} borderRadius="36px">
            <Box
              as="iframe"
              title="iframe"
              {...mobileIframeStyle}
              pointerEvents={preventUserScroll ? "none" : "auto"}
              data-xyz="phone-wrapper"
              srcDoc={srcDoc}
              ref={mainFrameRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={onMouseLeave}
              onLoad={(e) => handleOnIframeLoad(e)}
            />
          </DeviceFrame>
        </PopoverTrigger>
        <ScrollToDemoPopover />
      </Popover>
    </IframeWrapper>
  );
};

const IframeWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const mobileIframeStyle = {
  w: "100%",
  h: "auto",
  margin: "76px 26px 20px 24px",
  border: "none",
  borderRadius: "0px 0px 36px 36px",
  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset",
};

const DeviceFrame = ({ children, ...rest }) => {
  return (
    <Flex
      background={`url(${mobileFrame})`}
      width="375px"
      height="745px"
      transformOrigin="top"
      alignItems="stretch"
      transition="transform 0.15s ease"
      justifyContent="center"
      backgroundColor="white"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      border="none"
      borderRadius="10px 10px 60px 60px"
      {...rest}
    >
      {children}
    </Flex>
  );
};

const ScrollToDemoPopover = () => {
  return (
    <PopoverContent
      w="130px"
      right="200%"
      p="0"
      bg="#666666"
      borderColor="#666666"
      borderRadius={12}
    >
      <PopoverBody p="0">
        <Image
          borderRadius={16}
          src="https://studio-tool-global.production.playground.xyz/static-assets/765a9ff60d996be47d2f64738bf0356313ffe8ad/previewer/img/scroll-down.png"
        />
      </PopoverBody>
    </PopoverContent>
  );
};
