import { HStack, Text, Divider, Icon, Progress } from "@chakra-ui/react";
import { CustomTooltip } from "../../components/UI/Tooltip/CustomTooltip";
import { BiCheckCircle, BiTimeFive, BiTestTube } from "react-icons/bi";

export const DcoHeader = ({ dcoMeta, optimisationProgress }) => {

  const status = dcoMeta.active
    ? { color: 'orange.500', text: 'In Progress', icon: BiTestTube }
    : optimisationProgress === 100
    ? { color: 'green.500', text: 'Complete', icon: BiCheckCircle }
    : { color: 'gray.500', text: 'Not Started', icon: BiTimeFive };
  return (
    <HStack
      direction={"row"}
      width="100%"
      bg={"white"}
      height="100%"
      padding="10px 10px"
      alignItems="center"
      borderBottom="solid 1px #eee"
    >
      <Text fontSize="md" fontWeight="600">
        Catalyst Report
      </Text>
      <Divider orientation="vertical" mx="5" />
      <HStack alignItems="center">
        <Icon
          as={status.icon}
          height="20px"
          width="20px"
          color={status.color}
        />
        <Text fontSize="sm" fontWeight="400">
          {status.text}
        </Text>
      </HStack>
      <Divider orientation="vertical" mx="5" />
      <HStack spacing={5} alignItems="center">
        <Text fontSize="sm" fontWeight="400">
          Optimisation Progress
        </Text>
        <Progress
          borderRadius={"sm"}
          w="100px"
          colorScheme={status.color.split(".")[0]}
          size="sm"
          value={optimisationProgress}
        />
        <CustomTooltip
          label={`This campaign is ${Math.round(
            optimisationProgress
          )}% through the optimisation phase. During optimisation we're gathering data on all variations to determine the best performing.`}
        />
      </HStack>
    </HStack>
  );
};
