import styled from "@emotion/styled";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { FalafelLoader } from "./src/feedback/FalafelLoader";
import { SnackBar } from "./src/feedback/SnackBar";
import { SpinnyCat } from "./src/feedback/SpinnyCat";
import { Button } from "./src/forms/Button";
import { ButtonGroup } from "./src/forms/ButtonGroup";
import { Globals } from "./src/Global";
import { Heading } from "./src/typography/Heading";
import { Text } from "./src/typography/Text";
import { Link } from "./src/typography/Link";
import { Hr } from "./src/typography/Hr";


const LibraryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--space-4);
  gap: var(--space-3);
`;

const lorem = `TT Norms Pro is a geometric sans serif for a wide range of applications—a
trouble-free workhorse. The third version of the TT Norms Pro bestseller.
In it, we tried to bring the font to the new, unprecedented level of
functionality, visual quality and technical perfection. 1234567890`;

export const Lib = () => {
  const [successSnackBar, setSuccessSnackBar] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(false);
  const [infoSnackBar, setInfoSnackBar] = useState(false);
  const [warningSnackBar, setWarningSnackBar] = useState(false);


  return (
    <LibraryContainer>
      <Globals />
      <Heading type="heading1">Component Lib</Heading>

      <Hr />

      <Heading>Primary Button</Heading>
      <ButtonGroup>
        <Button size="sm">My Button</Button>
        <Button size="md">My Button</Button>
        <Button size="md" disabled={true}>
          My Button
        </Button>
      </ButtonGroup>

      <Heading>Secondary Button</Heading>
      <ButtonGroup>
        <Button variant="secondary" size="sm">
          My Button
        </Button>
        <Button variant="secondary" size="md">
          My Button
        </Button>
        <Button variant="secondary" size="md" disabled>
          My Button
        </Button>
      </ButtonGroup>
      <Heading>Tertiary Button</Heading>
      <ButtonGroup>
        <Button variant="tertiary" size="sm">
          My Button
        </Button>
        <Button variant="tertiary" size="md">
          My Button
        </Button>
        <Button variant="tertiary" size="md" disabled>
          My Button
        </Button>
      </ButtonGroup>
      <Hr />
      <Heading>Links</Heading>
      <Heading>
        This is a <Link> link component </Link> inside of a heading. It should be used inside of a Text or a Heading component.
      </Heading>

      <Text type="body01" as={"p"}>
        This is a <Link> link component</Link> inside of a text box.
      </Text>

      <Hr />
      <Heading>Typography</Heading>
      <Heading type="heading1">
        TT Norms Pro is a geometric sans serif for a wide range of applications.
      </Heading>
      <Heading>
        TT Norms Pro is a geometric sans serif for a wide range of applications.
      </Heading>

      <Hr />

      <Text type="baseline">{lorem}</Text>
      <Text type="baseline" as={"b"}>
        {lorem}
      </Text>

      <Text type="body01">{lorem}</Text>
      <Text type="body01" as={"b"}>
        {lorem}
      </Text>

      <Text type="body02"> {lorem}</Text>
      <Text type="body02" as={"b"}>
        {lorem}
      </Text>

      <Hr />

      <Heading>SnackBars 🍫</Heading>
      <ButtonGroup>
        <Button
          onClick={() => {
            setSuccessSnackBar(true);
          }}
        >
          Open Success snackbar
        </Button>
        {successSnackBar &&
          ReactDOM.createPortal(
            <SnackBar
              title="It worked, the thing happened, great job."
              status="success"
              delayToClose={6000}
            />,
            document.getElementById("alerts")
          )}

        <Button
          onClick={() => {
            setErrorSnackBar(true);
          }}
        >
          Open Error snackbar
        </Button>
        {errorSnackBar &&
          ReactDOM.createPortal(
            <SnackBar
              title="Something went wrong, you should probably fix it."
              status="error"
            />,
            document.getElementById("alerts")
          )}

        <Button
          onClick={() => {
            setInfoSnackBar(true);
          }}
        >
          Open Info snackbar
        </Button>
        {infoSnackBar &&
          ReactDOM.createPortal(
            <SnackBar
              title="This is some info, you should probably read it."
              status="info"
              delayToClose={6000}
            />,
            document.getElementById("alerts")
          )}

        <Button
          onClick={() => {
            setWarningSnackBar(true);
          }}
        >
          Open Warning snackbar
        </Button>
        {warningSnackBar &&
          ReactDOM.createPortal(
            <SnackBar
              title="This is a warning, you should probably read it."
              status="warning"
              delayToClose={3000}
            />,
            document.getElementById("alerts")
          )}
      </ButtonGroup>
      <Hr />

      <Heading>SpinnyCat</Heading>
      <SpinnyCat />
      <Heading>SpinnyKitten - (SpinnyCat size s)</Heading>
      <SpinnyCat size="s" />
      <Heading>FalafelLoader</Heading>
      <FalafelLoader />
      <Hr />
    </LibraryContainer>
  );
};

export * from "./src/feedback";
export * from "./src/forms";
export * from "./src/Global";
export * from "./src/typography";
