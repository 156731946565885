import { Box, Flex, HStack, VStack } from "@chakra-ui/react";
import { EditableDescription } from "@components/UI/EditableDescription";
import { useCreativeAnnotations } from "../../../hooks/useCreativeAnnotations";
import useScrollData from "../../../hooks/useScrollData";
import { UIStore } from "@stores/UIStore";
import Gauge from "../Gauge";
import { CatStat } from "@components/CatStat";
import { useAdScrollState } from "@controllers/ScrollController/useAdScrollState";
import { useEffect, useRef } from "react";
import { getScrollQueueFromScrollData } from "@controllers/ScrollController/getScrollQueue";
import { scrollInAnimation } from "../scrollInAnimation";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

export const SimpleGauge = ({
  rechartsData,
  mainFrameRef,
  buildId,
  iframeState = { iframeLoaded: true, isHoveringIframe: false },
}) => {
  const { averagePressure, avgScrollDistance } = rechartsData;
  const { currentY, scrollActions } = useAdScrollState(mainFrameRef);
  const playTimer = useRef(null);
  const userInfo = UIStore((state) => state.userInfo);

  const {
    annotations,
    setAnnotation,
    loading: annotationsLoading,
  } = useCreativeAnnotations({ buildId, format: "velocity" });

  const {
    sessionPressure,
    scrollDistance,
    liveScrollSquiggle,
    scrollSquiggle,
  } = useScrollData(currentY);

  const runSetup = () => {
    // sets up listener in the useAdScrollState hook
    // to pass through y position
    scrollActions.loadIFrame();
    const scrollConfig = {
      // initalY: 3000, //inital scroll position.
      durationScale: 1.2,
      scrollLengthScale: 1.5,
      // delayDurationScale: 2,
      minDuration: 0,
      maxDuration: 4000,
    };

    const scrollQueue = getScrollQueueFromScrollData(
      scrollInAnimation,
      scrollConfig
    );
    console.log("scrollQueue", scrollQueue);

    scrollActions.loadQueue(scrollQueue);

    if (playTimer.current) clearTimeout(playTimer.current);
    if (iframeState.isHoveringIframe) return;
    playTimer.current = setTimeout(() => {
      scrollActions.play();
    }, 3500);
  };

  useEffect(() => {
    if (iframeState.iframeLoaded) runSetup();
    return () => {
      clearTimeout(playTimer.current);
    };
  }, [iframeState.iframeLoaded]);

  useEffect(() => {
    if (!iframeState.isHoveringIframe) return;
    clearTimeout(playTimer.current);
    scrollActions.pause();
  }, [iframeState.isHoveringIframe]);

  return (
    <>
      <HStack
        w="100%"
        h="100%"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap="12"
        py={12}
        pr={3}
        pl={6}
      >
        <VStack
          borderRadius="md"
          p={6}
          border="1px solid"
          borderColor="gray.200"
          pos="relative"
        >
          {/* <Flex
            overflow="hidden"
            pos="absolute"
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="center"
            zIndex={0}
            top={0}
            left={0}
            opacity={0.2}
          >
            <LiveScrubChart
              liveScrollSquiggle={liveScrollSquiggle}
              scrollSquiggle={scrollSquiggle}
            />
          </Flex> */}
          <TopGradient />
          <Gauge
            pressure={sessionPressure}
            averagePressure={averagePressure}
            avgScrollDistance={avgScrollDistance}
            scrollDistance={scrollDistance}
          />
          <BottomGradient />
          <StatHStack opacity={sessionPressure > 0 ? 1 : 0} pb="7">
            <CatStat title="Velocity" value={sessionPressure} animate={false} />
            <CatStat
              title="Distance"
              value={scrollDistance}
              animate={false}
              unit="px"
            />
          </StatHStack>
        </VStack>

        <VStack w="100%" alignItems="flex-start" h="100%" pt="5%">
          <VStack pb={6} alignItems="flex-start">
            <EditableDescription
              textStyle={{ fontSize: "3xl", fontWeight: "medium" }}
              inputStyle={{ width: "100%" }}
              onEdit={(value) => setAnnotation("title", value)}
              isLoading={annotationsLoading}
              value={annotations?.title}
              showEditControls={userInfo?.loggedIn}
            />
            <EditableDescription
              showEditControls={userInfo?.loggedIn}
              textStyle={{ fontSize: "sm" }}
              onEdit={(value) => setAnnotation("description", value)}
              value={annotations?.description}
              isLoading={annotationsLoading}
              inputStyle={{ width: "100%", minHeight: "200px" }}
              fieldType="textarea"
            />
          </VStack>
          <HStack w="100%" gap="4">
            <CatStat
              title="Av. Velocity"
              value={averagePressure}
              benchmark={averagePressure * 0.95}
            />
            <CatStat
              title="Av. Distance"
              value={avgScrollDistance}
              benchmark={avgScrollDistance * 0.86}
              unit="px"
            />
          </HStack>
          {/* <VelocityStats
            averagePressure={averagePressure}
            avgScrollDistance={avgScrollDistance}
            scrollDistance={scrollDistance}
            pressure={sessionPressure}
            maxPressure={1}
          /> */}
        </VStack>
      </HStack>
    </>
  );
};

const TopGradient = () => (
  <Box
    pos="absolute"
    bottom={0}
    right={0}
    h="30%"
    w="100%"
    bgGradient="linear(to-b, whiteAlpha.500, transparent)"
    zIndex={1}
  />
);
const BottomGradient = () => (
  <Box
    pos="absolute"
    bottom={0}
    right={0}
    h="30%"
    w="100%"
    bgGradient="linear(to-t, whiteAlpha.500, transparent)"
    zIndex={1}
  />
);

const StatHStack = ({ children, ...rest }) => (
  <HStack
    pos="absolute"
    // justifyContent="center"
    bottom={4}
    left={6}
    transition="opacity 0.7s ease"
    w="100%"
    gap={1}
    {...rest}
  >
    {children}
  </HStack>
);

const LiveScrubChart = ({ scrollSquiggle, liveScrollSquiggle }) => {
  return (
    <ResponsiveContainer aspect={3 / 5} maxHeight={500} width="110%" zIndex={0}>
      <LineChart>
        <XAxis
          dataKey="x"
          hide={true}
          type="number"
          interval={"preserveStart"}
          domain={["dataMin", 2000]}
        />
        <YAxis
          dataKey="y"
          hide={true}
          domain={[(dataMin) => dataMin * 2, (dataMax) => dataMax * 2]}
        />
        <Line
          data={liveScrollSquiggle}
          strokeWidth={2}
          dot={false}
          type="monotone"
          dataKey="y"
          stroke="#8884d8"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
