import styled from "@emotion/styled";
import React from "react";
import { Text } from "../../../component-library/src/typography/Text";
import { HStack } from "@chakra-ui/react";

const SubheadInner = styled.div`
  display: flex;
  width: 100%;
  gap: var(--space-3);
  max-width: var(--maxScreenWidth);
  padding: 0 var(--space-4);
  margin: 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-2);
`;

const Sessions = styled.div`
  margin-right: var(--space-3);
  & * {
    white-space: nowrap;
  }
`;

export const Subheader = ({
  sessionSize,
  minSessions,
  title,
  children,
  ...rest
}) => {
  return (
    <HStack
      w="100%"
      minH="50px"
      borderBottom="1px solid var(--colors-neutral-4)"
      {...rest}
    >
      <SubheadInner>
        {title}
        <RightContainer>
          <Sessions>
            <Text type="body01" as="span" style={{ whiteSpace: "nowrap" }}>
              {sessionSize && `Sessions: ${sessionSize} / ${minSessions}`}
            </Text>
          </Sessions>
          {children}
        </RightContainer>
      </SubheadInner>
    </HStack>
  );
};
