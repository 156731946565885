import { useState, useEffect, useRef } from 'react';
import {
  processScrollDataWithTime,
  getSingleSessionTotalScrollDistance,
  getSingleSessionPressures,
  prepareScrollSquiggleChartData,
} from '../../../cat-functions/velocity';
import { aggregateScrollSquiggle } from '../../../cat-functions/modules/Velocity/utils';

const useScrollData = (
  currentY,
  RESET_INTERVAL = 500,
  minimumDistance = 10
) => {
  const [metrics, setMetrics] = useState({
    scrollDistance: 0,
    sessionPressure: 0,
    pressureArray: [],
    liveScrollSquiggle: [],
  });
  const [catPayload, setCatPayload] = useState([]);
  const [direction, setDirection] = useState(1);
  const [y, setY] = useState(0);
  const [directionChangeY, setDirectionChangeY] = useState(0);
  const [directionChangesCount, setDirectionChangesCount] = useState(0);

  const resetData = () => {
    console.log('Resetting data...');
    setDirectionChangesCount(0);
    setCatPayload([]); // Reset the payload array after saving
  };

  const resetTimer = useRef(null);
  const debounceResetData = () => {
    if (resetTimer.current) {
      clearTimeout(resetTimer.current);
    }
    resetTimer.current = setTimeout(resetData, RESET_INTERVAL);
  };

  const onScroll = (scrollY) => {
    const now = Date.now();
    const dify = scrollY - y;
    const dir = Math.sign(dify);
    const distance = Math.abs(scrollY - directionChangeY);
    if (dir !== direction) {
      setDirectionChangesCount((prev) => prev + 1);
    }

    setY(scrollY);

    if (distance > minimumDistance) {
      setDirection(dir);
      setDirectionChangeY(y);
      setCatPayload((prev) => [
        ...prev,
        {
          d: dir,
          y: Math.round(directionChangeY),
          t: now,
        },
      ]);
    }

    if (catPayload.length >= 6 && directionChangesCount >= 1) {
      debounceResetData();
    }
    // resetInactivityTimer();
  };

  useEffect(() => {
    if (currentY !== undefined) {
      onScroll(currentY);
    }
  }, [currentY]);

  useEffect(() => {
    const scrolls = processScrollDataWithTime(catPayload);
    if (scrolls.length === 0) {
      setMetrics({
        scrollDistance: 0,
        sessionPressure: 0,
        pressureArray: [],
        liveScrollSquiggle: [],
      });
      return;
    }
    const scrollSquiggle = prepareScrollSquiggleChartData([scrolls]);
    const liveScrollSquiggle = aggregateScrollSquiggle({
      avgScrollSquiggle: scrollSquiggle,
      incomingScrollSquiggle: scrollSquiggle,
      totalSessions: 1,
    });
    const distance = getSingleSessionTotalScrollDistance(scrolls);
    const pressures = getSingleSessionPressures(scrolls);
    setMetrics({
      scrollDistance: distance,
      sessionPressure: pressures[pressures.length - 2]?.pressure || 0,
      pressureArray: pressures,
      liveScrollSquiggle,
    });
  }, [catPayload]);

  return metrics;
};

export default useScrollData;
