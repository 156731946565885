import { css, Global } from "@emotion/react";
import "normalize.css";
import React from "react";
import TTNormsBold from "url:./assets/fonts/TTNorms-Bold.otf";
import TTNormsDemiBold from "url:./assets/fonts/TTNorms-DemiBold.ttf";
import TTNormsMedium from "url:./assets/fonts/TTNorms-Medium.otf";
import TTNormsRegular from "url:./assets/fonts/TTNorms-Regular.otf";
import { cssVars } from "./themes/themes";

export const Globals = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: "TTNorms";
          src: local("TTNorms"), url(${TTNormsRegular}) format("opentype");
        }

        @font-face {
          font-family: "TTNorms";
          font-weight: 500;
          src: local("TTNorms"), url(${TTNormsMedium}) format("opentype");
        }

        @font-face {
          font-family: "TTNorms";
          font-weight: 600;
          src: local("TTNorms"), url(${TTNormsDemiBold}) format("truetype");
        }

        @font-face {
          font-family: "TTNorms";
          font-weight: 700;
          src: local("TTNorms"), url(${TTNormsBold}) format("opentype");
        }

        :root {
          ${cssVars}
        }

        body {
          color: var(--colors-text);
          background: var(--colors-neutral-5);
          font-weight: var(--fontWeights-0);
        }

        b {
          font-weight: var(--fontWeights-1);
        }
      `}
    />
  );
};
