import styled from "@emotion/styled";
import React, { forwardRef } from "react";

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: "standard" | "emphasis";
  maxWidth?: string; // for ellipsis, e.g. creative names which can be very long
  children: React.ReactNode,
}

const StyledLink = styled.a<LinkProps>`
  text-decoration: none;
  padding: 0;
  margin: 0;
  ${({ maxWidth }) => maxWidth && 
    maxWidthOverrides(maxWidth)
  } 
  ${({ variant }) => variant === "emphasis" && 
    emphVarientOverrides
  }
`;

const Span = styled.span`
  padding-bottom: 2px;
  border-bottom: 2px solid var(--colors-neutral-3);
  transition: color 0.3s ease-in-out, border-color 0.5s ease-in-out;

  &:hover {
    border-color: var(--colors-primary);
    cursor: pointer;
  }

  &:active {
    padding-bottom: 3px;
    color: var(--colors-neutral-1);
    cursor: pointer;
    transition: padding-bottom 0.4s;
  }
`

// Variant overrides
const emphVarientOverrides = `
  & span {
    border-bottom: 2px solid var(--colors-primary);
    &:hover {
      color: var(--colors-primary);    
      border-color:#ff458b; //will fix
    }
  }
`;

const maxWidthOverrides = (maxWidth) => `
  display: inline-flex;
  & span{
    max-width: ${maxWidth};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>
  (({children, ...props}: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
    return (
      <StyledLink as={"a"} {...props} ref={ref} >
        <Span>{children}</Span>
      </StyledLink>
    )
  })