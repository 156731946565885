import { Switch, Text, Tooltip, Icon, Flex } from "@chakra-ui/react";
import { BiInfoCircle } from "react-icons/bi";
import { useEffect, useState } from "react";
const Toggle = ({
  onToggle,
  label = "",
  tooltipMessage = null,
  value = false,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState(value);

  const handleToggle = (event) => {
    const newState = event.target.checked;
    setIsChecked(newState);
    onToggle(newState); // Call the callback with the new state
  };

  useEffect(() => {
    if (typeof value !== "boolean") {
      console.warn(
        `The value prop of the Toggle component should be a boolean, but received ${typeof value}`
      );
      return;
    }

    setIsChecked(value);
  }, [value]);

  return (
    <Flex alignItems={"center"}>
      <Switch
        size={"sm"}
        id="switch"
        isChecked={isChecked}
        onChange={handleToggle}
        {...rest}
      />
      <Flex px="1">
        <Text fontSize="xs" color={"gray.500"}>
          {label}
        </Text>
        {tooltipMessage && (
          <Tooltip
            label={tooltipMessage}
            borderRadius={4}
            bg="gray.100"
            color={"gray.800"}
            w="180px"
            hasArrow
            p={3}
          >
            <span>
              <Icon as={BiInfoCircle} color="gray.400" />
            </span>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};

export default Toggle;
