@import "colors.scss";

.catrouselViewer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $white;
  transition: all 0.3s ease-in-out;
  max-width: 1200px;
  margin: 0 auto;
  @media (min-height: 0px) {
    overflow-y: visible;
  }
  @media (min-width: 0px) {
    padding: 0% 5%;
  }
  @media (min-width: 0px) {
    padding: 0% 5%;
  }
  @media (min-width: 1450px) {
    padding: 0% 10%;
  }
  .campaignTitle {
    width: 100%;
    padding: 20px 10px 0px 10px;
    box-sizing: border-box;
    margin: 120px 0 0 0;
    text-align: left;
    font-weight: 700;
    color: $grey;
    @media (max-width: 630px) {
      margin: 0 10px;
      width: 80%;
    }
    .brand {
      font-size: 30px;
    }
    .campaign {
      font-size: 20px;
      color: $lightgrey;
      font-weight: 400;
    }
  }
  .cardsContainer,
  .catComparison {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1300px;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    padding-top: 100px;
    .sectionTitle {
      width: 100%;
      font-size: 20px;
      padding: 0 10px;
      box-sizing: border-box;
      margin-bottom: 20px;
      font-weight: 600;
      color: $lightgrey;
      border-top: 1px solid $lightergrey;
      padding-top: 30px;
      position: relative;
      svg {
        margin-right: 10px;
      }
    }
    .showMore,
    .noMore {
      width: auto;
      font-size: 12px;
      font-weight: 400;
      padding: 10px 20px;
      border-radius: 40px;
      margin-top: 20px;
      color: $lightgrey;
    }
    .showMore {
      background: $lightergrey;
      color: $black;
      cursor: pointer;
      font-weight: 600;

      &:hover {
        background: $pink;
        color: $white;
      }
    }
  }
  .sectionNavigation {
    position: fixed;
    z-index: 999;
    top: 50%;
    right: 0;
    width: 40px;
    box-shadow: $shadow50med;
    border-radius: 10px 0 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    .sectionNavigationItem {
      height: 20px;
      width: 20px;
      margin: 10px auto;
      color: $lightergrey;
      position: relative;
      cursor: pointer;
      transition: color 0.2s ease-in-out;
      &:hover {
        color: $pink;
      }
      &.active {
        color: $black;
      }
      svg {
        height: 20px;
        width: 20px;
      }
      .tooltipText {
        visibility: hidden;
        width: 120px;
        background-color: $grey;
        color: $white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        right: calc(100% + 10px);
        top: 0;
        box-shadow: $shadow50med;
      }
      &:hover {
        .tooltipText {
          visibility: visible;
        }
      }
    }
  }
  .catComparisonContainer {
    min-height: 500px;
    margin-bottom: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .dummy {
      width: 100%;
      height: 100%;
      background-color: $lightergrey;
      margin: 10px;
      border-radius: 20px;
      filter: blur(20px);
    }
    .noMore {
      position: absolute;
      color: $grey;
      width: 50%;
      text-align: center;
    }
    .legends {
      margin-top: 10px;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-around;
      color: $lightgrey;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      background: $white;
      padding: 20px;
      box-sizing: border-box;
      opacity: 1;
      > div {
        width: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          margin-left: 20px;
          margin-right: 20px;
          width: 50%;
          height: 5px;
          display: block;
          border-radius: 4px;
        }
      }
      .actual {
        span {
          background: $orbgradient;
        }
      }
      .baseline {
        span {
          background: #c4c4c4;
        }
        svg {
          color: $white;
          background: $grey;
          padding: 10px;
          border-radius: 50%;
        }
      }
      .scrub {
        span {
          background: $lightgrey;
        }
      }
      .dwellTime {
        span {
          height: 20px;
          width: 20px;
          border-radius: 20px;
          background: $orbgradient;
          opacity: 0.9;
        }
      }
      svg {
        color: $white;
        background: $pink;
        padding: 10px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
