export const prepTopVideo = (data) =>
  data
    .reduce((acc, { performanceByAsset }) => {
      performanceByAsset.forEach((slot, i) => {
        acc = [...acc, slot];
      });
      return acc;
    }, [])
    .sort((a, b) => {
      return b.score - a.score;
    });

export const prepCarouselTopAssets = (data) =>
  data
    .reduce((acc, { performanceByAsset }) => {
      performanceByAsset.forEach((slot, i) => {
        const assets = Object.values(slot).filter((asset) => asset.score !== 0);
        acc[i] = [...(acc[i] ?? []), ...assets];
      });
      return acc;
    }, [])
    .map((slot) => {
      // Zheng's note: get the mean score for each slot
      const mean = slot.reduce(
        (acc, { score }, _, { length }) => acc + score / length,
        0
      );

      return slot.map((recommendation) => ({
        ...recommendation,
        distance: recommendation.score - mean, // Zheng's note: calculate distances between each score and its relative slot's mean
        mean,
      }));
    })
    .flat() // Zheng's note: flatten the array of recommendations per slot and sort them by distance to means
    .sort((a, b) => {
      return b.distance - a.distance;
    })
    .reduce((acc, recommendation, _, array) => {
      const { length } = acc.filter(({ id }) => recommendation.id === id);

      if (length) return acc;

      const dups = array.filter(({ id }) => recommendation.id === id);
      acc.push({
        ...recommendation,

        // Zheng's note: calculate average distance between each item
        distance: dups.reduce((acc, { distance }, _, { length }) => {
          return acc + distance / length;
        }, 0),
        isDup: dups.length > 1,
      });

      return acc;
    }, [])
    .sort((a, b) => {
      return b.distance - a.distance;
    });

export const prepCarouselChartData = (data) => {
  return data.map(({ id, score }, i) => ({
    name: id,
    value: parseInt(score),
    fill: getChartColor(i),
  }));
};

export const prepVideoTreeData = (data) => {
  return data.map(({ id, score }, i) => ({
    value: parseInt(score),
    name: id,
    fill: getChartColor(i),
  }));
};

export const getChartColor = (index) => {
  const colors = Array.from(
    { length: 6 },
    (_, i) => `var(--colors-chart-${i})`
  );
  return colors[index % 6];
};
