@import "colors.scss";
.graphParentContainer {
  box-sizing: border-box;
  // margin-top:var(--space-3);
  //compensating for extra padding inside the graph we can't really touch:
  width: 103%;
  height: 100%;

  margin-left: -2%;
}

.graphContainer {
  max-height: 40vh;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  p {
    text-align: center;
    line-height: 26px;
    max-width: 700px;
    margin-top: 0;
    @media screen and (max-height: 900px) {
      font-size: 12px;
    }
  }

  .metricWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
    @media screen and (max-height: 900px) {
      margin-top: 0;
    }
  }
  .legends {
    margin-top: 10px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: $lightgrey;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background: $white;
    padding: 20px;
    box-sizing: border-box;
    opacity: 1;
    > div {
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      span {
        margin-left: 20px;
        margin-right: 20px;
        width: 50%;
        height: 2px;
        display: block;
        border-radius: 4px;
      }
    }
    .actual {
      span {
        height: 4px;
        background: $orbgradient;
      }
    }
    .baseline {
      span {
        background: #c4c4c4;
      }
    }
    .scrub {
      span {
        background: $lightgrey;
      }
    }
    .dwellTime {
      span {
        height: 20px;
        width: 20px;
        border-radius: 20px;
        background: $orbgradient;
        opacity: 0.9;
      }
    }
  }
}
