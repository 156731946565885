function easeInOutCubic(progress) {
  return progress < 0.5
    ? 4 * progress * progress * progress
    : 1 - Math.pow(-2 * progress + 2, 3) / 2;
}

function scrollToElementById(elementId) {
  const element = document.getElementById(elementId);
  if (!element) return;

  const elementRect = element.getBoundingClientRect();
  const offsetTop = window.pageYOffset || document.documentElement.scrollTop;

  const targetScrollY = elementRect.top + offsetTop - 150; // -150 extra so the scrolled in element is not at the very top of the page
  const duration = 500; // Adjust this value as needed

  const startTime = Date.now();
  const animationStep = () => {
    const currentTime = Date.now();
    const progress = Math.min((currentTime - startTime) / duration, 1);
    const easing = easeInOutCubic(progress);

    window.scrollTo(0, offsetTop + (targetScrollY - offsetTop) * easing);

    if (progress < 1) {
      window.requestAnimationFrame(animationStep);
    }
  };

  window.requestAnimationFrame(animationStep);
}

exports.scrollToElementById = scrollToElementById;