import { Flex, Text, Tooltip } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

export const InfoTooltipText = ({ text, tooltipText, ...rest }) => {
  return (
    <Flex
      alignItems={"center"}
      justifyContent={"center"}
      {...rest}
      pt="2"
      pb="6"
    >
      <Text fontSize={"md"} fontWeight={500}>
        {text}
      </Text>
      <Tooltip label={tooltipText}>
        <InfoOutlineIcon
          mx={2}
          boxSize={"15px"}
          color={"blackAlpha.500"}
          cursor={"pointer"}
        />
      </Tooltip>
    </Flex>
  );
};
