import { Box, HStack, VStack } from "@chakra-ui/react";
import { EditableDescription } from "@components/UI/EditableDescription";
import { useCreativeAnnotations } from "../../../hooks/useCreativeAnnotations";
import useScrollData from "../../../hooks/useScrollData";
import { UIStore } from "@stores/UIStore";
import Gauge from "../Gauge";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { CatStat } from "@components/CatStat";
import { useEffect, useRef } from "react";
import { useAdScrollState } from "@controllers/ScrollController/useAdScrollState";
import { getScrollQueueFromScrollData } from "@controllers/ScrollController/getScrollQueue";
import { scrollInAnimation } from "../scrollInAnimation";
import { HowDoWeCalculate } from "@composites/HowDoWeMeasure";

export const GaugeLiveScribble = ({
  rechartsData,
  mainFrameRef,
  buildId,
  iframeState,
}) => {
  const { averagePressure, avgScrollDistance, scrollSquiggle } = rechartsData;
  const userInfo = UIStore((state) => state.userInfo);
  const playTimer = useRef(null);

  const { scrollStatus, scrollActions, currentProgress, currentY } =
    useAdScrollState(mainFrameRef);

  const {
    annotations,
    setAnnotation,
    loading: annotationsLoading,
  } = useCreativeAnnotations({ buildId, format: "velocity" });

  const {
    sessionPressure,
    scrollDistance,
    liveScrollSquiggle = [],
  } = useScrollData(currentY);

  const parentRef = useRef(null);

  const runSetup = () => {
    // sets up listener in the useAdScrollState hook
    // to pass through y position
    scrollActions.loadIFrame();
    const scrollConfig = {
      // initalY: 3000, //inital scroll position.
      durationScale: 1.3,
      scrollLengthScale: 1.3,
      // delayDurationScale: 2,
      minDuration: 0,
      maxDuration: 4000,
    };

    const scrollQueue = getScrollQueueFromScrollData(
      scrollInAnimation,
      scrollConfig
    );

    scrollActions.loadQueue(scrollQueue);

    if (playTimer.current) clearTimeout(playTimer.current);
    if (iframeState.isHoveringIframe) return;
    playTimer.current = setTimeout(() => {
      // scrollActions.play();
    }, 3500);
  };

  useEffect(() => {
    if (iframeState.iframeLoaded) runSetup();
  }, [iframeState.iframeLoaded]);

  useEffect(() => {
    if (!iframeState.isHoveringIframe) return;
    clearTimeout(playTimer.current);
    scrollActions.pause();
  }, [iframeState.isHoveringIframe]);

  // useEffect(() => {
  //   if (liveScrollSquiggle.length > 0) {
  //     const lastLiveItem = liveScrollSquiggle[liveScrollSquiggle.length - 1];
  //     const lastItem = scrollSquiggle[scrollSquiggle.length - 1];
  //     if (lastLiveItem.x > lastItem.x) {
  //       scrollSquiggle.push({ x: lastLiveItem.x, y: lastItem.y, dummy: true });
  //     }
  //   }
  // }, [liveScrollSquiggle]);

  return (
    <>
      <VStack w="100%" pb={6} pt={4} alignItems="flex-start">
        <EditableDescription
          textStyle={{ fontSize: "3xl", fontWeight: "medium" }}
          onEdit={(value) => setAnnotation("title", value)}
          isLoading={annotationsLoading}
          value={annotations?.title}
          showEditControls={userInfo?.loggedIn}
        />
        <EditableDescription
          showEditControls={userInfo?.loggedIn}
          textStyle={{ fontSize: "sm", maxWidth: "600px" }}
          onEdit={(value) => setAnnotation("description", value)}
          value={annotations?.description}
          isLoading={annotationsLoading}
          fieldType="textarea"
        />
      </VStack>
      <HStack
        justifyContent="flex-start"
        w="100%"
        h="100%"
        alignItems="stretch"
        gap="6"
      >
        <VStack
          borderRadius="md"
          w="100%"
          maxW="40%"
          overflow="hidden"
          h="100%"
          border="1px solid"
          borderColor="gray.200"
          pos="relative"
          ref={parentRef}
        >
          <TopGradient />
          <Gauge
            pressure={sessionPressure}
            averagePressure={averagePressure}
            avgScrollDistance={avgScrollDistance}
            scrollDistance={scrollDistance}
          />
          <BottomGradient />
          {/* --------------------------------------------- */}
          {/* LIVE STATS */}
          <StatHStack opacity={sessionPressure > 0 ? 1 : 0} pb="7">
            <CatStat title="Velocity" value={sessionPressure} animate={false} />
          </StatHStack>
          {/* --------------------------------------------- */}
          {/* SET STATS */}
          <StatHStack opacity={sessionPressure > 0 ? 0 : 1}>
            <CatStat
              title="Av Velocity"
              value={averagePressure}
              benchmark={averagePressure * 0.86}
            />
          </StatHStack>
        </VStack>
        <VStack w="100%" h="100%" alignItems="flex-start">
          <VStack w="100%" h="100%" pos="relative" alignItems="flex-start">
            {/* ^ for when a state was absolute positioned inside chart, otherwise not needed  */}
            <LiveScrubChart
              liveScrollSquiggle={liveScrollSquiggle}
              scrollSquiggle={scrollSquiggle}
              avgScrollDistance={avgScrollDistance}
              avgScrollDistanceBenchmark={avgScrollDistance * 0.95}
            />
            <StatHStack opacity={sessionPressure > 0 ? 1 : 0} pb="7">
              <CatStat
                title="Distance"
                value={scrollDistance}
                animate={false}
                unit="px"
              />
            </StatHStack>
            <StatHStack opacity={sessionPressure > 0 ? 0 : 1}>
              <CatStat
                title="Av Scroll Distance"
                value={avgScrollDistance}
                unit="px"
                benchmark={avgScrollDistance * 0.95}
              />
            </StatHStack>
          </VStack>

          <EditableDescription
            showEditControls={userInfo?.loggedIn}
            textStyle={{ fontSize: "sm", pl: 2 }}
            onEdit={(value) => setAnnotation("description", value)}
            value={annotations?.description}
            isLoading={annotationsLoading}
          />

          <HowDoWeCalculate.Velocity />
        </VStack>
      </HStack>
    </>
  );
};

const TopGradient = () => (
  <Box
    pos="absolute"
    bottom={0}
    right={0}
    h="30%"
    w="100%"
    bgGradient="linear(to-b, whiteAlpha.500, transparent)"
  />
);
const BottomGradient = () => (
  <Box
    pos="absolute"
    bottom={0}
    right={0}
    h="30%"
    w="100%"
    bgGradient="linear(to-t, whiteAlpha.500, transparent)"
  />
);

const StatHStack = ({ children, ...rest }) => (
  <HStack
    pos="absolute"
    bottom={4}
    left={6}
    transition="opacity 0.7s ease"
    w="100%"
    gap={1}
    {...rest}
  >
    {children}
  </HStack>
);

const LiveScrubChart = ({
  scrollSquiggle,
  liveScrollSquiggle,
  avgScrollDistance,
  avgScrollDistanceBenchmark,
}) => {
  console.log("liveScrollSquiggle", liveScrollSquiggle);
  return (
    <ResponsiveContainer aspect={5 / 3} maxHeight={300}>
      <LineChart margin={{ bottom: -10 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          type="number"
          interval={"preserveStart"}
          domain={["dataMin", "dataMax"]}
          fontSize="0.8rem"
          unit="px"
          minTickGap={125}
          stroke="#C7C7C7"
          label={{ fill: "#C7C7C7" }}
        />
        <YAxis
          dataKey="y"
          hide={true}
          domain={[(dataMin) => dataMin * 3, (dataMax) => dataMax * 3]}
          // domain={[-500, 500]}
        />
        <Line
          data={scrollSquiggle}
          strokeWidth={2}
          dot={false}
          opacity={liveScrollSquiggle.length > 0 ? 0.2 : 1}
          animationDuration={0.5}
          type="monotone"
          dataKey="y"
          stroke="#8884d8"
        />
        <Line
          data={liveScrollSquiggle}
          strokeWidth={2}
          dot={false}
          type="monotone"
          dataKey="y"
          stroke="#8884d8"
        />
        <ReferenceLine
          x={avgScrollDistance}
          stroke="#00BA77"
          animationDuration={4}
          label={{
            value: "Av. Scroll Distance",
            transform: "translate(0, 5)",
            fill: "#00BA77",
            ...refLineStyle,
          }}
        />
        <ReferenceLine
          x={avgScrollDistanceBenchmark}
          stroke="#C7C7C7"
          animationDuration={4}
          label={{
            value: "Benchmark*",
            transform: "translate(0, 25)",
            fill: "#C7C7C7",
            ...refLineStyle,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const refLineStyle = {
  fontFamily: "roboto mono, monospace",
  fontSize: "0.8rem",
  position: "insideTopRight",
};
