import React, {useEffect} from 'react';
import Game from '../../components/Game/Game'
import {logAnalytics} from "@utils/analyticsUtil";

import styles from './DontCry.module.scss'
import { UIStore } from '../../store/UIStore';

const DontCry = () => {
  
  useEffect(() => {
    logAnalytics.error404(
      window.location.href,
    );
  }, []);

  const userInfo = UIStore((state) => state.userInfo);

  return (
    <div className={styles.dontCry}>
      <div className={styles.wrapper}>
          <div className={styles.gameWrapper}>
            <Game/>
          </div>
          <h1>OOPS - 404</h1>
          <h2>The page you requested doesn't exist.</h2>
          <p>Please contact us if this seems to be a mistake.</p>
      </div>
    </div>
    
  )
}

export default DontCry
