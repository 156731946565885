import { Tooltip, Icon } from "@chakra-ui/react";
import { BiInfoCircle } from "react-icons/bi";

export const CustomTooltip = ({ icon = BiInfoCircle, label, color }) => {
  return (
    <Tooltip hasArrow label={label} bg="gray.50" color={"gray.800"}>
      {icon && (
        <span>
          <Icon width="16px" height="16px" as={icon} color={color} />
        </span>
      )}
    </Tooltip>
  );
};
