import React from "react";
import styles from "./CATRouselCard.module.scss";

const CATRouselCard = (props) => {
  return (
    <div
      className={`${styles.cardContainer} ${
        props.ranking === 1 ? styles.firstCard : null
      }`}
    >
      <div
        // style={{ backgroundImage: `url(${props.image})` }}
        className={styles.cardImage}
      >
        <img src={props.image} style={{height: "auto", width: "100%"}} />
      </div>
      <div className={styles.cardTitle}>{props.title.replace(/_/g, " ")}</div>
      <div className={styles.ranking}>#{props.ranking}</div>
    </div>
  );
};

export default CATRouselCard;
