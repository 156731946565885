import { SequenceData } from "./useAdScrollState";

export class ScrollSequencer {
  data: SequenceData[];
  target: Window;
  currentIndex: number;
  isPlaying: boolean;
  elapsedDuration: number;
  totalDuration: any;
  currentProgress: number;
  setPlayingCallback: Function;
  setCurrentIndexCallback: Function;
  setCurrentProgressCallback: Function;
  lastFrameTimestamp: any;
  animationFrameId: any;
  constructor(
    data: SequenceData[],
    target: Window,
    setPlayingCallback: Function,
    setCurrentIndexCallback: Function,
    setCurrentProgressCallback: Function
  ) {
    this.data = data;
    this.target = target;
    this.currentIndex = 0;
    this.isPlaying = false;

    this.elapsedDuration = 0;
    this.totalDuration = this.data.reduce(
      (acc, { duration }) => acc + duration,
      0
    );
    this.currentProgress = 0;

    // callbacks to update the UI/ state ---
    this.setPlayingCallback = setPlayingCallback;
    this.setCurrentIndexCallback = setCurrentIndexCallback;
    this.setCurrentProgressCallback = setCurrentProgressCallback;

    this.lastFrameTimestamp = null;
    this.animationFrameId = null;
  }

  // internal methods -----------------------------------------------------------
  // we update the local state of the sequencer and call the callback to update the UI
  setPlaying(isPlaying) {
    this.isPlaying = isPlaying;
    this.setPlayingCallback(isPlaying);
  }

  setCurrentIndex(index) {
    this.currentIndex = index;
    this.setCurrentIndexCallback(index);
  }

  setCurrentProgress(elapsedDuration) {
    this.currentProgress = elapsedDuration;
    this.setCurrentProgressCallback(elapsedDuration);
  }

  //--------------------------------------------------------------------------------

  play(index = 0) {
    if (this.isPlaying) this.jumpToIndex(index);

    if (!this.isPlaying && index < this.data.length) {
      this.setPlaying(true);
      this.setCurrentIndex(index);
      let { yDifference, duration } = this.data[this.currentIndex];
      this.scroll(yDifference, duration);
    }
  }

  pause() {
    if (this.animationFrameId) {
      window.cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    this.setPlaying(false);
  }

  resume() {
    if (!this.isPlaying && this.currentIndex < this.data.length) {
      this.setPlaying(true);
      let { yDifference, duration } = this.data[this.currentIndex];
      this.scroll(yDifference, duration);
    }
  }

  playNext() {
    if (!this.isPlaying) {
      console.error(
        "You've asked me to playNext, but the sequencer is not playing"
      );
      return;
    }
    if (this.currentIndex >= this.data.length) {
      this.cancel();
      return;
    }
    const currentData = this.data[this.currentIndex];
    this.setCurrentIndex(this.currentIndex + 1);
    this.scroll(currentData.yDifference, currentData.duration);
  }

  cancel() {
    this.setPlaying(false);
    this.elapsedDuration = 0;
    this.setCurrentProgress(0);
    this.lastFrameTimestamp = null;
    this.setCurrentIndex(0);
    this.animationFrameId = null;
  }

  jumpToIndex(index) {
    const wasPlaying = this.isPlaying;
    this.pause();
    if (index >= this.data.length) return;
    this.setCurrentIndex(index);
    // resest progress and elapsed duration
    this.elapsedDuration = calculateElapsedDuration(index, this.data);
    this.setCurrentProgress((this.elapsedDuration / this.totalDuration) * 100);
    this.lastFrameTimestamp = null;

    if (wasPlaying) {
      this.playNext();
    }
  }

  jumpToPercentElapsed(percent) {
    const targetElapsedDuration = (percent / 100) * this.totalDuration;
    const index = this.data.findIndex(({ duration }, i) => {
      return (
        calculateElapsedDuration(i, this.data) + duration >=
        targetElapsedDuration
      );
    });
    this.jumpToIndex(index);
  }

  scroll(
    delta, // how much we will scroll
    duration // duration of this scroll action
  ) {
    let start = null;

    // current position of the window
    const from = this.target.pageYOffset;

    // where we will scroll to at the end of the delta
    const to = from + delta;

    window.requestAnimationFrame(
      function step(timestamp) {
        if (!start) start = timestamp;
        const progress = (timestamp - start) / duration;

        // ---------------------------------------------
        // for calculating the % progress of the scroll
        if (this.lastFrameTimestamp) {
          this.elapsedDuration += timestamp - this.lastFrameTimestamp;
          this.setCurrentProgress(
            (this.elapsedDuration / this.totalDuration) * 100
          );
        }
        this.lastFrameTimestamp = timestamp;
        // ---------------------------------------------

        if (progress < 1) {
          const scrollPos = progress * delta + from;
          this.target.scrollTo(0, Math.round(scrollPos));
          this.animationFrameId = window.requestAnimationFrame(step.bind(this));
        } else {
          this.target.scrollTo(0, to);
          this.setCurrentIndex(this.currentIndex + 1);
          if (this.currentIndex < this.data.length && this.isPlaying) {
            let { yDifference, duration } = this.data[this.currentIndex];
            this.scroll(yDifference, duration);
          }
        }
      }.bind(this)
    );
  }
}

const calculateElapsedDuration = (index, data) => {
  return data.slice(0, index).reduce((acc, { duration }) => acc + duration, 0);
};
