import { Flex } from "@chakra-ui/react";
import { SpinnyCat } from "@ui-lib";
import { withRouter } from "react-router";
import { useDcoData } from "./useDcoData";
import { DcoContainer } from "./DcoContainer";

export const DCO = withRouter(
  ({
    match: {
      params: { buildID },
    },
  }) => {
    const [dcoData, isLoading] = useDcoData(buildID);

    return (
      <Flex height="100%" direction="column" w="100%">
        {isLoading ? (
          <Flex mt="50px">
            <SpinnyCat />
          </Flex>
        ) : (
          <DcoContainer buildID={buildID} dcoData={dcoData} />
        )}
      </Flex>
    );
  }
);
