import { HStack, Text, Box } from "@chakra-ui/react";
import { IconInBox } from "./IconInBox";
export const ChartButton = ({
  children,
  active,
  color,
  icon,
  text,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onClick = () => {},
}) => {
  return (
    <HStack
      spacing="2"
      display="flex"
      direction="row"
      padding="8px 8px"
      borderRadius="md"
      _hover={{ opacity: "0.9", cursor: "pointer" }}
      border={"solid 1px white"}
      borderColor={active ? color : "white"}
      onMouseEnter={() => {
        onMouseEnter();
      }}
      onMouseLeave={() => {
        onMouseLeave();
      }}
      onClick={() => {
        onClick();
      }}
      fontWeight="400"
      opacity={active ? "1" : "0.5"}
    >
      {/* removing as we don't have meaningful icons yet... {icon && <IconInBox color={color} width="16px" height="16px" CustomIcon={icon} />} */}
      <Box w="16px" h="16px" borderRadius={"sm"} bgColor={color} opacity={active ? "1" : "0.5"}></Box>
      <Text margin="0px 5px" fontSize="sm">
        {text}
      </Text>
    </HStack>
  );
};
