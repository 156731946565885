import React, { useEffect, useState } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import firebase from "@services/firestore";
import { Link } from "react-router-dom";
import LoadingBar from "../../components/LoadingBar/LoadingBar";

import styles from "./CATRouselSelector.module.scss";
import { fetchMetrics } from "../../services/theplayground";
const CATRouselSelector = () => {
  const demoCreativeIDs = ["lawaso", "topane"]; // manually stopping some test data from displaying
  const [catrouselCreatives, setCatrouselCreatives] = useState([]);
  const [creatives, loadingCreatives, errorCreatives] = useCollectionOnce(
    firebase.firestore().collection("CAT-Rousel")
  );

  if (errorCreatives) {
    console.error(errorCreatives);
  }

  if (!loadingCreatives) {
    console.log(creatives.docs);
  }

  useEffect(() => {
    if (!loadingCreatives && creatives) {
      let allCreatives = [];
      creatives.docs.map(async (creativeDoc, index) => {
        const creativeData = creativeDoc.data();
        if (creativeData.campaigns) {
          creativeData.id = creativeDoc.id;
          allCreatives.push(creativeData);
        } else {
          if (!demoCreativeIDs.includes(creativeDoc.id)) {
            getCampaignName(creativeDoc.id, creativeData);
            creativeData.id = creativeDoc.id;
            creativeData.campaigns = "";
            creativeData.creatives = "";
            creativeData.brands = "";
            creativeData.verticals = "";
            allCreatives.push(creativeData);
          }
        }
      });
      setCatrouselCreatives(allCreatives);
    }
  }, [creatives, loadingCreatives, errorCreatives]);

  const getCampaignName = (creativeID, creativeData) => {
    fetchMetrics(creativeID)
      .then((data) => {
        let campaignNames = "";
        let creativeNames = "";
        let brandNames = "";
        let brandVerticals = [];
        if (data && !data.errors) {
          data.playground.campaigns.forEach((campaign) => {
            campaignNames += " " + campaign.name;
          });
          data.playground.creatives.forEach((creative) => {
            creativeNames += " " + creative.name;
          });
          data.playground.brands.forEach((brand) => {
            brandNames += " " + brand.name;
            brandVerticals.push(brand.vertical);
          });

          firebase.firestore().collection("CAT-Rousel").doc(creativeID).update(
            {
              campaigns: campaignNames,
              creatives: creativeNames,
              brands: brandNames,
              verticals: brandVerticals,
            },
            { merge: true }
          );
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className={styles.catrouselSelector}>
      <div className={styles.creativeContainer}>
        {!loadingCreatives ? (
          catrouselCreatives.map((creativeDoc, index) => {
            return (
              <Link to={`/catrousel/${creativeDoc.id}`} key={creativeDoc.id}>
                <div className={styles.creativeWrapper}>
                  <div className={styles.campaign}>
                    {creativeDoc.brands} - {creativeDoc.campaigns}
                  </div>
                  <img
                    src={`http://metababy.playground.xyz/${creativeDoc.id}/400x400/hang-time/small-thumbnail`}
                    alt={creativeDoc.id}
                  />
                  <p>Creative ID: {creativeDoc.id}</p>
                  <div className={styles.creativeName}>
                    {creativeDoc.creatives}
                  </div>
                </div>
              </Link>
            );
          })
        ) : (
          <LoadingBar />
        )}
      </div>
    </div>
  );
};

export default CATRouselSelector;
