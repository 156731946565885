import styled from "@emotion/styled";
import React from "react";

const scales = {
  s: 0.5,
  xs: 0.1,
  m: 1,
};

const LoadingCat = styled.div<{ size: "xs" | "s" | "m" }>`
  position: relative;
  width: 300px;
  height: 300px;
  margin: 48px auto;

  --outline: var(--spinnyCat-outline);
  --fill1: var(--spinnyCat-fills-0);
  --fill2: var(--spinnyCat-fills-1);

  transform: scale(${({ size }) => scales[size] ?? 1});
  transform-origin: top left;

  & * {
    box-sizing: content-box; 
  }

  @keyframes mask-animation {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(-675deg);
    }
  }

  @keyframes mask-animation-sub-left {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes mask-animation-sub-right {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes head-animation {
    0% {
      transform: rotate(70deg);
    }
    100% {
      transform: rotate(-650deg);
    }
  }

  @keyframes foot-animation {
    0% {
      transform: rotate(25deg);
    }
    100% {
      transform: rotate(-695deg);
    }
  }

  @keyframes eye-light-animation {
    0% {
      opacity: 0;
      height: 6px;
    }
    50% {
      opacity: 0.75;
      height: 50px;
    }
    80% {
      opacity: 1;
      height: 6px;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes mouth-animation {
    0% {
      height: 0px;
    }
    50% {
      height: 10px;
    }
    100% {
      height: 0px;
    }
  }
`;

const Body = styled.div`
  position: absolute;
  width: 290px;
  height: 290px;
  background-color: var(--fill1);
  border-radius: 50%;
  border: 5px solid var(--outline);

  &:before {
    content: "";
    position: absolute;
    top: calc(50% - 188px / 2 - 5px);
    left: calc(50% - 188px / 2 - 5px);
    width: 188px;
    height: 188px;
    border-radius: 50%;
    border: 5px solid var(--outline);
    background-color: var(--fill2);
  }
  &:after {
    content: "";
    position: absolute;
    top: calc(50% - 222px / 2 - 22px);
    left: calc(50% - 222px / 2 - 22px);
    height: 222px;
    width: 222px;
    border: 22px solid var(--fill2);
    border-radius: 50%;
  }
`;
const AnimationMask = styled.div`
  transform: rotate(45deg);
  animation: mask-animation 2s 0s infinite;

  &:before {
    animation: mask-animation-sub-right 2s 0s infinite;
  }
  &:after {
    animation: mask-animation-sub-left 2s 0s infinite;
  }

  position: absolute;
  height: 50%;
  width: 50%;
  background-color: var(--fill2);
  transform-origin: right bottom;

  &:before {
    content: "";
    position: absolute;
    left: 100%;
    height: 100%;
    width: 100%;
    background-color: var(--fill2);
    transform-origin: left bottom;
  }
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    height: 100%;
    width: 100%;
    background-color: var(--fill2);
    transform-origin: right top;
  }
`;
const Head = styled.div`
  transform: rotate(70deg);
  animation: head-animation 2s 0s infinite;
  animation-timing-function: cubic-bezier(0.2, 0, 0.09, 1);

  position: absolute;
  right: 0;
  top: 0;
  height: 50%;
  width: 50%;
  background-color: var(--fill2);
  transform-origin: left bottom;
`;
const Face = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 50px;
  height: 40px;
  background-color: var(--fill1);
  border: 5px solid var(--outline);
  border-bottom: 0;

  &:before {
    content: "";
    position: absolute;
    left: calc(50% - 10px);
    bottom: -15px;
    height: 22px;
    width: 22px;
    background-color: var(--fill2);
    border-radius: 50%;
  }
`;
const Ear = styled.div`
  position: absolute;
  bottom: 39px;
  right: 0;
  width: 10px;
  height: 12px;
  background-color: var(--fill1);
  border: 5px solid var(--outline);
  border-bottom: 0;
  border-radius: 20px 0 0 0;

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    left: -45px;
    width: 10px;
    height: 12px;
    background-color: var(--fill1);
    border: 5px solid var(--outline);
    border-bottom: 0;
    border-radius: 0 20px 0 0;
  }
`;
const Hand = styled.div`
  position: absolute;
  bottom: -32px;
  right: 0;
  width: 10px;
  height: 30px;
  background-color: var(--fill1);
  border: 5px solid var(--outline);
  border-top: 0;
  border-radius: 0 0 10px 10px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -45px;
    width: 10px;
    height: 30px;
    background-color: var(--fill1);
    border: 5px solid var(--outline);
    border-top: 0;
    border-radius: 0 0 10px 10px;
  }
`;

const eyecss = `
  position: absolute;
  top: 116px;
  right: 12px;
  width: 6px;
  height: 6px;
  background-color: var(--outline);
  border-radius: 3px;

  &:before {
    content: "";
    position: absolute;
    top: 0px;
    right: 30px;
    width: 6px;
    height: 6px;
    background-color: var(--outline);
    border-radius: 3px;
  }
`;
const Eye = styled.div`
  ${eyecss}
`;
const EyeLight = styled.div`
  ${eyecss}
  background-color: white;
  box-shadow: 0 0 10px white;
  opacity: 0;
  animation: eye-light-animation 2s 0s infinite;

  &:before {
    background-color: white;
    box-shadow: 0 0 10px white;
    opacity: 0;
    animation: eye-light-animation 2s 0s infinite;
  }
`;
const Mouth = styled.div`
  position: absolute;
  bottom: 16px;
  right: 27px;
  width: 2px;
  height: 0px;
  background-color: var(--fill1);
  border: 2px solid var(--outline);
  border-top: 0;
  border-radius: 0 0 10px 10px;
  animation: mouth-animation 2s 0s infinite;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: -4px;
    width: 2px;
    height: 2px;
    background-color: var(--fill1);
    border: 2px solid var(--outline);
    border-top: 0;
    border-radius: 0 0 10px 10px;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0px;
    width: 2px;
    height: 2px;
    background-color: var(--fill1);
    border: 2px solid var(--outline);
    border-top: 0;
    border-radius: 0 0 10px 10px;
  }
`;

const bearcss = `
  position: absolute;
  bottom: 18px;
  right: -4px;
  width: 12px;
  height: 3px;
  background-color: var(--outline);

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    right: 0;
    width: 12px;
    height: 3px;
    background-color: var(--outline);
    transform: rotate(-10deg);
  }
  &:after {
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    width: 12px;
    height: 3px;
    background-color: var(--outline);
    transform: rotate(10deg);
  }
`;

const BeardLeft = styled.div`
  ${bearcss}
`;

const BeardRight = styled.div`
  ${bearcss}
  right: 52px;

  &:before {
    transform: rotate(10deg);
  }
  &:after {
    transform: rotate(-10deg);
  }
`;
const Foot = styled.div`
  transform: rotate(25deg);
  animation: foot-animation 2s 0s infinite;
  animation-timing-function: cubic-bezier(0.2, 0, 0.45, 1);

  position: absolute;
  left: 0;
  bottom: 0;
  height: 50%;
  width: 50%;
  background-color: var(--fill2);
  transform-origin: right top;
`;
const Belly = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 14px;
  height: 46px;
  background-color: var(--fill1);
  border: 5px solid var(--outline);
  border-left: 0;
  border-right: 0;
`;
const Leg = styled.div`
  position: absolute;
  bottom: 0px;
  right: 14px;
  width: 30px;
  height: 10px;
  background-color: var(--fill1);
  border: 5px solid var(--outline);
  border-right: 0;
  border-radius: 10px 0 0 10px;

  &:before {
    content: "";
    position: absolute;
    top: -41px;
    right: 0;
    width: 30px;
    height: 10px;
    background-color: var(--fill1);
    border: 5px solid var(--outline);
    border-right: 0;
    border-radius: 10px 0 0 10px;
  }
`;
const Tail = styled.div`
position: absolute;
      bottom: 16px;
      right: 14px;
      width: 50px;
      height: 14px;
      background-color: var(--$orange);
      border: 5px solid var(--outline);
      border-right: 0;
      border-radius: 14px 0 0 14px;

      &:after {
        content: "";
        position: absolute;
        right: -28px;
        bottom: -4px;
        height: 22px;
        width: 22px;
        background-color: var(--fill2);
        border-radius: 50%;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: -7px;
        right: 0;
        width: 10px;
        height: 18px;
        background-color: var(--fill1);
        border: 5px solid var(--outline);
        border-right: 0;
        border-radius: 12px 0 0 12px;
      }
    }
`;

interface SpinnyCatProps {
  size?: "s" | "m";
}

export const SpinnyCat = ({ size = "m" }: SpinnyCatProps) => (
  <LoadingCat size={size}>
    <Body />
    <AnimationMask />
    <Head>
      <Face />
      <Ear />
      <Hand />
      <Eye />
      <EyeLight />
      <Mouth />
      <BeardLeft />
      <BeardRight />
    </Head>
    <Foot>
      <Belly />
      <Leg />
      <Tail />
    </Foot>
  </LoadingCat>
);
