import catLogin from "@assets/images/login.png";
import styled from "@emotion/styled";
import firebase from "@services/firestore";
import FirebaseAuth from "react-firebaseui/FirebaseAuth";
import { UIStore } from "../../store/UIStore";
import styles from "./LoginScreen.module.scss";

const StyleWrapper = styled.div`
  #firebaseui_container {
    button {
      color: var(--colors-primary);
    }

    button[type="submit"] {
      background-color: var(--colors-primary);
      color: white;
    }

    .mdl-shadow--2dp {
      box-shadow: none;
    }
    .firebaseui-textfield.mdl-textfield .firebaseui-label::after {
      background-color: var(--colors-primary);
    }
  }
`;

const LoginScreen = () => {
  const userInfo = UIStore((state) => state.userInfo);

  if (userInfo.loggedIn === false) {
    const firebaseUIConfig = {
      // popup / redirect
      signInFlow: "popup",
      callbacks: {
        signInSuccessWithAuthResult: () => false,
      },
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            // Forces account selection even when one account
            // is available.
            hd: "playgroundxyz.com",
          },
        },
      ],
    };
    return (
      <div className={styles.loginScreen}>
        <div className={styles.loginWrapper}>
          <img src={catLogin} alt="login" />
          <h1>Please Login</h1>
          <p>
            Login with your GumGum Google Account to access the Creative
            Analytics Tracker.
          </p>
          <StyleWrapper>
            <FirebaseAuth
              uiConfig={firebaseUIConfig}
              firebaseAuth={firebase.auth()}
            />
          </StyleWrapper>
        </div>
      </div>
    );
  }
};

export default LoginScreen;
