import {
  getCountsByBuildId,
  getCountsByUserId,
  addToUserFavourites,
  removeFromUserFavourites,
  getTopFavouritedCreatives,
} from '../services/counts';
import { useState, useEffect } from 'react';

const useCountsByCreative = (buildId) => {
  const [counts, setCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!buildId) return;
    if (loading) return;
    fetchCounts(buildId);
  }, [buildId]);

  const fetchCounts = async (buildId) => {
    setLoading(true);
    try {
      const counts = await getCountsByBuildId(buildId);
      setCounts(counts);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const refetchCounts = async () => {
    await fetchCounts(buildId);
    return;
  };

  return { counts, loading, error, refetchCounts };
};

const useCountsByUserId = (userId) => {
  const [counts, setCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userId) return;
    if (loading) return;
    fetchCounts(userId);
  }, [userId]);

  const fetchCounts = async (userId) => {
    setLoading(true);
    try {
      const counts = await getCountsByUserId(userId);
      setCounts(counts);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const addFavourite = async (buildId) => {
    setLoading(true);
    try {
      await addToUserFavourites(userId, buildId);
      fetchCounts(userId);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const removeFavourite = async (buildId) => {
    setLoading(true);
    try {
      await removeFromUserFavourites(userId, buildId);
      fetchCounts(userId);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const toggleFavourite = async (buildId) => {
    setLoading(true);
    try {
      if (
        counts.favourites &&
        counts.favourites.find((fav) => fav.id === buildId)
      ) {
        await removeFromUserFavourites(userId, buildId);
      } else {
        await addToUserFavourites(userId, buildId);
      }
      fetchCounts(userId);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    counts,
    loading,
    error,
    addFavourite,
    removeFavourite,
    toggleFavourite,
  };
};

const useTopFavourites = () => {
  const [topFavourites, setTopFavourites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (loading) return;
    fetchCounts();
  }, []);
  const fetchCounts = async () => {
    setLoading(true);
    try {
      const counts = await getTopFavouritedCreatives();
      setTopFavourites(counts);
    } catch (error) {
      console.log('error', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { topFavourites, loading, error };
};

export { useCountsByCreative, useCountsByUserId, useTopFavourites };
