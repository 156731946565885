import { Box, Flex, Text, Divider } from "@chakra-ui/react";
import { useIsVisible } from "../../hooks/useIsVisible";
import { useRef, useEffect } from "react";
import { IconInBox } from "../../components/UI/Buttons/IconInBox";
import { Link } from "react-router-dom";

export const Card = ({
  children,
  heading,
  link = null,
  setInViewItem,
  id,
  height = "100%",
  icon = null,
}) => {
  const ref = useRef(null);
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (isVisible) {
      setInViewItem();
    }
  }, [isVisible]);

  return (
    <Box
      width="1100px"
      height={height}
      overall="hidden"
      padding="20px 0"
      borderRadius="lg"
      backgroundColor="white"
      boxShadow="md"
      marginY={4}
    >
      <div id={id} ref={ref}>
        {heading && (
          <>
            <Flex alignItems={"center"} px="30px">
              <div style={{ marginRight: "12px" }}>
                {icon && (
                  <IconInBox
                    width="26px"
                    height="26px"
                    padding="2px"
                    CustomIcon={icon}
                  />
                )}
              </div>
              <Text fontSize="xl">{heading}</Text>
              {link && (
                <Link to={link.path}>
                  <Text color={"#4AC1E0"} fontSize="xl">
                    {link.text}
                  </Text>
                </Link>
              )}
            </Flex>
            {children && <Divider marginTop="20px" />}
          </>
        )}

        {children}
      </div>
    </Box>
  );
};
