function aggregateScrollSquiggle({
  avgScrollSquiggle,
  incomingScrollSquiggle,
  totalSessions,
}) {
  if (!avgScrollSquiggle || !avgScrollSquiggle.length) {
    return incomingScrollSquiggle;
  }

  const maxLength = Math.max(
    avgScrollSquiggle.length,
    incomingScrollSquiggle.length
  );

  // Initialize the new scroll squiggle array with appropriate length and default values
  const newScrollSquiggle = new Array(maxLength).fill(null).map((_, index) => {
    const avgPoint = avgScrollSquiggle[index];
    const newPoint = incomingScrollSquiggle[index];

    // De-average the existing data if it exists
    const baseY = avgPoint ? avgPoint.y * totalSessions : 0;

    // Add the new session data if it exists
    const incomingY = newPoint ? newPoint.y : 0;

    // Re-average the combined data
    const newY = (baseY + incomingY) / (totalSessions + 1);

    // setting a standard x value for the squiggle
    // doing this as aggregating the x values is very messy
    // we can have sessions come in that wildly throw off the x values
    return { x: index * 50, y: Math.round(newY) };
  });

  return newScrollSquiggle;
}

function aggregatePressureSequence({
  avgPressureSequence,
  incomingPressureSequence,
  totalSessions,
}) {
  const maxLength = Math.max(
    avgPressureSequence.length,
    incomingPressureSequence.length
  );

  // Initialize the new pressure sequence array with appropriate length and default values
  let rollingAccumulatedTime = 0;
  const newPressureSequence = new Array(maxLength)
    .fill(null)
    .map((_, index) => {
      const avgPoint = avgPressureSequence[index];
      const newPoint = incomingPressureSequence[index];

      // De-average the existing data if it exists
      const baseY = avgPoint ? avgPoint.pressure * totalSessions : 0;
      const baseTime = avgPoint ? avgPoint.accumulatedTime : 0;
      // Add the new session data if it exists
      const incomingY = newPoint ? newPoint.pressure : 0;
      const incomingTime = newPoint ? newPoint.accumulatedTime : 0;
      // Re-average the combined data
      const newY = (baseY + incomingY) / (totalSessions + 1);
      const newTime = (baseTime + incomingTime) / (totalSessions + 1);
      // accumulatedTime should be rolling accumulation of all previous times
      rollingAccumulatedTime += newTime;
      return { accumulatedTime: rollingAccumulatedTime, pressure: newY };
    });

  return newPressureSequence;
}

module.exports = { aggregateScrollSquiggle, aggregatePressureSequence };
