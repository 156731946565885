import { theme } from '@chakra-ui/react';
export const colors = {
  // ----------------------------------
  // BRAND COLORS
  // Note - if you're making updates to contextualGreen, creativeBlue, deepBlue, or pxyzPink,
  // you should also update the overwrites below.

  //🟩
  // contextual green overwrites the default chakra green
  green: {
    50: '#E8FFF6',
    100: '#A4FFDB',
    200: '#5AFFBD',
    300: '#2FF3A5',
    400: '#00CE7C', //main
    500: '#00B76E',
    600: '#00965B',
    700: '#007748',
    800: '#00643C',
    900: '#005231',
  },

  //🟦
  // creative blue overwrites the default chakra cyan
  cyan: {
    50: '#ECFBFF',
    100: '#B5EFFF',
    200: '#7CE2FD', //main
    300: '#4AC1E0',
    400: '#3DAAC7',
    500: '#2E94AF',
    600: '#1E7C95',
    700: '#0F657C',
    800: '#05586E',
    900: '#004A5D',
  },

  //⬛️
  // deep blue overwrites the default chakra blue
  blue: {
    50: '#EDFAFF',
    100: '#C1EBFB',
    200: '#A7D1E1',
    300: '#8AB4C4',
    400: '#6E98A8',
    500: '#507A8A',
    600: '#366070',
    700: '#214B5B',
    800: '#163B48',
    900: '#002A3A', //main
  },

  // 👛
  // pxyz pink overwrites the default chakra pink
  pink: {
    50: '#FFF3F6',
    100: '#FFD0DC',
    200: '#FFABC0',
    300: '#FF7EA0',
    400: '#FF5581',
    500: '#E93362', //main
    600: '#D12D57',
    700: '#BA1A44',
    800: '#AC0E37',
    900: '#9F022B',
  },
  // ------------------------------------------
  // Adding additional greys to the chakra grey
  //   since we use .grey50 as the bg color in light mode, needed to add more granularity in dark mode
  //   otherwise there was a large jump in contrast from 800 to 900
  gray: {
    ...theme.colors.gray,
    850: '#191E29',
    950: '#171920',
  },
};
