import { SESSION_SIZE_WARNING_BENCHMARK } from '@controllers/Home/consts';
import create from 'zustand';

const PAGE_SIZE = 21;

const HomepageStore = create((set, get) => ({
  status: 'ready',
  numberOfVisibleCreatives: PAGE_SIZE,
  options: {
    brands: [],
    verticals: [],
    formats: [],
    features: [],
  },
  filters: {
    brand: [],
    vertical: [],
    format: [],
    features: [],
    sessionSize: SESSION_SIZE_WARNING_BENCHMARK,
  },
  data: [],
  filteredData: [],
  pageData: [],
  hasNextPage: false,

  loadMore: () => {
    const numberOfVisibleCreatives = get().numberOfVisibleCreatives + PAGE_SIZE;
    set((state) => {
      return {
        numberOfVisibleCreatives,
        pageData: state.filteredData.slice(0, numberOfVisibleCreatives),
        hasNextPage: numberOfVisibleCreatives < state.filteredData.length,
      };
    });
  },

  setStatus: (status) => {
    set({
      status,
    });
  },

  setData: (data) => {
    set((state) => {
      // always apply the filter when re seting the data
      const filteredData = applyFilter(data, state.filters);
      return {
        data,
        filteredData,
        pageData: filteredData.slice(0, PAGE_SIZE),
        numberOfVisibleCreatives: PAGE_SIZE,
        hasNextPage: PAGE_SIZE < data.length,
      };
    });
  },

  setOptions: (options) => {
    set({
      options,
    });
  },

  getFilteredData: () => {
    return get().filteredData;
  },

  getPageData: () => {
    return get().filteredData.slice(0, get().numberOfVisibleCreatives);
  },

  changeFilters: (selectedOption, field) => {
    set((state) => {
      const filters = {
        ...state.filters,
        [field]: selectedOption,
      };

      const filteredData = applyFilter(state.data, filters);

      return {
        filteredData,
        filters,
        numberOfVisibleCreatives: PAGE_SIZE,
        pageData: filteredData.slice(0, PAGE_SIZE),
        hasNextPage: state.numberOfVisibleCreatives < filteredData.length,
      };
    });
  },

  clearFilters: () => {
    set((state) => {
      const filters = {
        brand: [],
        vertical: [],
        format: [],
        features: [],
        sessionSize: SESSION_SIZE_WARNING_BENCHMARK,
      };

      const filteredData = applyFilter(state.data, filters);

      return {
        filteredData,
        filters,
        numberOfVisibleCreatives: PAGE_SIZE,
        pageData: filteredData.slice(0, PAGE_SIZE),
        hasNextPage: PAGE_SIZE < filteredData.length,
      };
    });
  },
}));

function applyFilter(data, filters) {
  const filteredData = data.filter(
    (item) =>
      (filters.brand.length
        ? filters.brand.some((option) => option.value === item.brand)
        : true) &&
      (filters.vertical.length
        ? filters.vertical.some((option) => option.value === item.vertical)
        : true) &&
      (filters.format.length
        ? filters.format.some((option) => option.value === item.format)
        : true) &&
      (filters.features.length
        ? filters.features.some(
            (option) =>
              (option.value === 'video' && item.containsVideo) ||
              (option.value === 'display' && !item.containsVideo)
          )
        : true) &&
      (typeof filters.sessionSize === 'number'
        ? item.aggregatedSessionSize >= filters.sessionSize
        : true)
  );

  // sort by date if no filters are applied from dropdowns
  const sortByDateFirst =
    filters.brand.length === 0 &&
    filters.vertical.length === 0 &&
    filters.format.length == 0 &&
    filters.features.length == 0;

  // sort resultis by updated date if no filters are applied
  // aggregatedSessionSize is considered a filter so it is not included in the check
  if (sortByDateFirst) {
    filteredData.sort((a, b) => {
      return b.updated - a.updated;
    });
    return filteredData;
  }

  // group results by favourite count then by updated date
  filteredData.sort((a, b) => {
    if (a.favCount === b.favCount) {
      return b.updated - a.updated;
    }
    return b.favCount - a.favCount;
  });
  return filteredData;
}

export { HomepageStore };
