import firebase from "@services/firestore";
import { useEffect, useState } from "react";

// a hook to handle all of the

const CREATIVE_BUILD_REF = {
  hangtime: firebase.firestore().collection("CAT3"),
  velocity: firebase
    .firestore()
    .collection("Velocity")
    .doc("beta")
    .collection("v1"),
};

export const useCreativeAnnotations = ({
  buildId,
  format = "hangtime",
}: {
  buildId: string;
  format?: "hangtime" | "velocity";
}) => {
  const [annotations, setAnnotations] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(true);

  const fetchAnnotations = async () => {
    try {
      setLoading(true);

      const doc = CREATIVE_BUILD_REF[format].doc(buildId);
      const snapshot = await doc.get();
      const data = snapshot.data()?.meta || {};
      setAnnotations(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const setAnnotation = async (key: string, value: string) => {
    try {
      const doc = CREATIVE_BUILD_REF[format].doc(buildId);
      setLoading(true);
      await doc.set({ meta: { [key]: value } }, { merge: true });
      setLoading(false);
      setAnnotations({ ...annotations, [key]: value });
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!buildId) return;
    fetchAnnotations();
  }, [buildId]);

  return { annotations, loading, fetchAnnotations, setAnnotation };
};
