import { Box, HStack, IconButton, VStack } from "@chakra-ui/react";
import { EditableDescription } from "@components/UI/EditableDescription";
import { useCreativeAnnotations } from "../../../hooks/useCreativeAnnotations";
import useScrollData from "../../../hooks/useScrollData";
import { UIStore } from "@stores/UIStore";
import Gauge from "../Gauge";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { CatStat } from "@components/CatStat";
import { useEffect, useRef } from "react";
import { ProgressSlider } from "../ProgressSlider";
import { useAdScrollState } from "@controllers/ScrollController/useAdScrollState";
import { getScrollQueueFromScrollData } from "@controllers/ScrollController/getScrollQueue";
import { BiPause, BiPlay } from "react-icons/bi";
import { HowDoWeCalculate } from "@composites/HowDoWeMeasure";

export const GaugeScroll = ({
  rechartsData,
  mainFrameRef,
  buildId,
  iframeState,
}) => {
  const { averagePressure, avgScrollDistance, scrollSquiggle } = rechartsData;
  const userInfo = UIStore((state) => state.userInfo);
  const playTimer = useRef(null);
  const { scrollStatus, scrollActions, currentProgress, currentY } =
    useAdScrollState(mainFrameRef);

  const {
    annotations,
    setAnnotation,
    loading: annotationsLoading,
  } = useCreativeAnnotations({ buildId, format: "velocity" });

  const { sessionPressure, pressureArray, scrollDistance } =
    useScrollData(currentY);

  const parentRef = useRef(null);

  const runSetup = () => {
    // sets up listener in the useAdScrollState hook
    // to pass through y position
    scrollActions.loadIFrame();

    const scrollConfig = {
      // initalY: 3000, //inital scroll position.
      durationScale: 2.2,
      scrollLengthScale: 1.3,
      // delayDurationScale: 2,
      minDuration: 0,
      maxDuration: 4000,
    };

    const scrollQueue = getScrollQueueFromScrollData(
      scrollSquiggle,
      scrollConfig
    );

    scrollActions.loadQueue(scrollQueue);

    playTimer.current = setTimeout(() => {
      scrollActions.play();
    }, 3000);
  };

  useEffect(() => {
    if (iframeState.iframeLoaded) runSetup();
    return () => {
      clearTimeout(playTimer.current);
    };
  }, [iframeState.iframeLoaded]);

  useEffect(() => {
    if (!iframeState.isHoveringIframe) return;
    clearTimeout(playTimer.current);
    scrollActions.pause();
  }, [iframeState.isHoveringIframe]);

  return (
    <>
      <VStack w="100%" pt="4" alignItems="flex-start">
        <EditableDescription
          textStyle={{ fontSize: "4xl", fontWeight: "medium" }}
          onEdit={(value) => setAnnotation("title", value)}
          isLoading={annotationsLoading}
          value={annotations?.title}
          showEditControls={userInfo?.loggedIn}
        />
      </VStack>
      <HStack
        justifyContent="flex-start"
        w="100%"
        h="100%"
        alignItems="stretch"
        gap="6"
        py={8}
      >
        <VStack
          borderRadius="md"
          w="100%"
          maxW="330px"
          overflow="hidden"
          h="100%"
          px={5}
          border="1px solid"
          borderColor="gray.200"
          pos="relative"
          ref={parentRef}
        >
          <TopGradient />
          <Gauge
            pressure={sessionPressure}
            averagePressure={averagePressure}
            avgScrollDistance={avgScrollDistance}
            scrollDistance={scrollDistance}
          />
          <BottomGradient />
          {/* --------------------------------------------- */}
          {/* LIVE STATS */}
          <StatHStack opacity={sessionPressure > 0 ? 1 : 0} pb="7">
            <CatStat title="Velocity" value={sessionPressure} animate={false} />
            <CatStat
              title="Distance"
              value={scrollDistance}
              animate={false}
              unit="px"
            />
          </StatHStack>
          {/* --------------------------------------------- */}
          {/* SET STATS */}
          <StatHStack opacity={sessionPressure > 0 ? 0 : 1}>
            <CatStat
              title="Av Velocity"
              value={averagePressure}
              benchmark={averagePressure * 0.86}
            />
            <CatStat
              title="Av Distance"
              value={avgScrollDistance}
              unit="px"
              benchmark={avgScrollDistance * 0.95}
            />
          </StatHStack>
        </VStack>
        <VStack w="100%" h="100%" gap="0" alignItems="flex-start">
          <Box w="100%" h="100%" pos="relative">
            {/* ^ for when a state was absolute positioned inside chart, otherwise not needed  */}
            <ResponsiveContainer aspect={5 / 3} maxHeight={300}>
              <LineChart data={scrollSquiggle}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="x"
                  hide={true}
                  type="number"
                  interval={"preserveStart"}
                  domain={["dataMin", "dataMax"]}
                />
                <YAxis
                  dataKey="y"
                  hide={true}
                  domain={[(dataMin) => dataMin * 2, (dataMax) => dataMax * 2]}
                />
                <Line
                  strokeWidth={2}
                  dot={false}
                  type="monotone"
                  dataKey="y"
                  stroke="#8884d8"
                />
              </LineChart>
            </ResponsiveContainer>
            <ProgressSlider
              currentProgress={currentProgress}
              playFromPercent={scrollActions.playFromPercent}
            />
          </Box>
          <HStack
            w="100%"
            pos="relative"
            h="32px"
            borderRadius="lg"
            overflow="hidden"
            mb="6"
          >
            <ProgressSlider
              currentProgress={currentProgress}
              playFromPercent={scrollActions.playFromPercent}
              sliderTrackStyle={{ bg: "gray.200", opacity: 0.6 }}
            />
            <Box pos="absolute" top={0} left={0} zIndex={1}>
              <PlayButton
                scrollStatus={scrollStatus}
                scrollActions={scrollActions}
              />
            </Box>
          </HStack>
          <EditableDescription
            showEditControls={userInfo?.loggedIn}
            textStyle={{ fontSize: "sm", pl: 2 }}
            onEdit={(value) => setAnnotation("description", value)}
            value={annotations?.description}
            isLoading={annotationsLoading}
          />
          <HowDoWeCalculate.Velocity />
        </VStack>
      </HStack>
    </>
  );
};

const TopGradient = () => (
  <Box
    pos="absolute"
    bottom={0}
    right={0}
    h="30%"
    w="100%"
    bgGradient="linear(to-b, whiteAlpha.500, transparent)"
  />
);
const BottomGradient = () => (
  <Box
    pos="absolute"
    bottom={0}
    right={0}
    h="30%"
    w="100%"
    bgGradient="linear(to-t, whiteAlpha.500, transparent)"
  />
);

const StatHStack = ({ children, ...rest }) => (
  <HStack
    pos="absolute"
    justifyContent="center"
    bottom={4}
    left={0}
    px={5}
    transition="opacity 0.7s ease"
    w="100%"
    gap={1}
    {...rest}
  >
    {children}
  </HStack>
);

const PlayButton = ({ scrollStatus, scrollActions }) => {
  const showPlayButton = !scrollStatus.playing;
  const showRestartButton =
    scrollStatus.currentMovementIndex === scrollStatus.scrollQueue.length;

  if (showRestartButton) {
    return (
      <IconButton
        size="sm"
        backgroundColor="gray.200"
        icon={<BiPlay />}
        onClick={() => scrollActions.playFromPercent(0)}
      />
    );
  }

  if (showPlayButton) {
    return (
      <IconButton
        size="sm"
        backgroundColor="gray.200"
        icon={<BiPlay />}
        onClick={() => scrollActions.resume()}
      />
    );
  }

  return (
    <IconButton
      size="sm"
      backgroundColor="gray.100"
      icon={<BiPause />}
      onClick={() => scrollActions.pause()}
    />
  );
};
