export const applyHeaderColorToIframe = (mainFrameRef, headerColor) => {
  // Get the iframe header and menu
  const iframeHeader     = mainFrameRef.current.contentDocument.querySelector("header");
  const iframeHeaderMenu = mainFrameRef.current.contentDocument.querySelector(".header-menu");

  // Change the header color
  if (iframeHeader) {
    iframeHeader.style = `
      transition: background-color 1s, background 1s;
      background: linear-gradient(0deg, ${headerColor} 60%, rgba(9,30,44,1) 350%)!important;
      margin-top: -1px; /* Fix the gap between the header and the iframe */
    `;
  }

  // Hide the menu
  if (iframeHeaderMenu) {
    iframeHeaderMenu.style = `
      transition: opacity 1s;
      opacity: 0;
    `;
  }
}