import React, { useEffect, useRef, useState } from "react";
import Share from "@components/UI/Share/Share";
import { useCreatives } from "@stores/creatives";
import { GUIStore } from "@stores/GUIStore";
import { useMetrics } from "@stores/metrics";
import "allotment/dist/style.css";
import { generatePreviewerURL } from "../../util/creativeUtil";
import { ABGUI } from "../../components/GUI/ABGUI";
import { withAlertsRender } from "../Portals";
import { SpinnyCat, Text, Link, SnackBar } from "@ui-lib";
import { ViewerContainer, MobileAdLayout } from "./indexAB";
import { Subheader } from "../../composites/Subheader/Subheader";
import { logAnalytics } from "@utils/analyticsUtil";

const keyColors = ["#C5C5C5", "#4AC1E0"];

export const ABViewer = (props) => {
  const creatives = useCreatives((state) => state.items);
  const addCreatives = useCreatives((state) => state.create);
  const loadCreativeSequences = useCreatives(
    (state) => state.loadCreativeSequences
  );
  const cleanUp = useCreatives((state) => state.cleanUp);
  const metArray = useMetrics((state) => state.items);
  const addMetrics = useMetrics((state) => state.create);
  const fetchMetrics = useMetrics((state) => state.fetchMetrics);

  const minSequenceLength = GUIStore((state) => state.minSequenceLength);
  const triggerType = GUIStore((state) => state.triggerType);
  const domainWhiteList = GUIStore((state) => state.domainWhiteList);

  const [scrubbed, setscrubbed] = useState(false);
  const previewerRefs = useRef([]);

  const SnackBarAlert = withAlertsRender(SnackBar);

  // LIFE CYCLE EVENTS
  useEffect(() => {
    if (!!props.match.params.creativeID && !!props.match.params.creativeID2) {
      const buildIds = [
        props.match.params.creativeID,
        props.match.params.creativeID2,
      ];
      addCreatives([...buildIds]);
      addMetrics([...buildIds]);

      // Analytic view events
      logAnalytics.creativeViewed(buildIds[0], true);
      logAnalytics.creativeViewed(buildIds[1], true);
      logAnalytics.abtestViewed([...buildIds]);
    }
  }, [props.match.params.creativeID, props.match.params.creativeID2]);

  // load sequences
  useEffect(() => {
    if (creatives.every(({ sequences }) => sequences === null)) {
      loadCreativeSequences(minSequenceLength, triggerType);
    }
  }, [creatives, minSequenceLength, triggerType]);

  useEffect(() => {
    creatives.forEach(({ buildId }) => {
      fetchMetrics(buildId, domainWhiteList);
    });
    loadCreativeSequences(minSequenceLength, triggerType, domainWhiteList);
  }, [domainWhiteList]);

  useEffect(() => {
    return cleanUp;
  }, []);

  //animate in trigger
  useEffect(() => {
    if (creatives.length === 2) {
      previewerRefs.current.forEach((previewRef) => {
        previewRef.animateIn();
      });
      // test loaded
      logAnalytics.abtestLoaded([creatives[0].buildId, creatives[1].buildId]);
    }
  }, [
    creatives.length > 0 &&
      creatives
        .map(({ iframeLoaded }) => iframeLoaded)
        .every((val) => val === true),
  ]);

  const wheelHandler = (e) => {
    if (previewerRefs.current.length < 2) {
      return;
    }
    // probably a better way to do, throattle wasn't working for me
    if (!scrubbed) {
      logAnalytics.abtestScrubed([creatives[0].buildId, creatives[1].buildId]);
      setscrubbed(true);
    }
    creatives.forEach((p, i) => {
      const ref = previewerRefs.current[i].mainFrame;
      const iframeScrollPosition = ref.contentWindow.scrollY;
      const newScrollPosition = iframeScrollPosition + e.deltaY;
      ref.contentWindow.scrollTo(0, newScrollPosition);
    });
  };

  // alerts and errors
  const zeroMetricError =
    metArray?.[0]?.attentionTime === 0 || metArray?.[1]?.attentionTime === 0;

  const formatIsntHangtimeError =
    (creatives[0]?.format && !creatives[0]?.format === "hang-time") ||
    (creatives[1]?.format && !creatives[1]?.format === "hang-time");

  const creativeHasVideoError =
    (!!creatives[0]?.videoSequences &&
      Object.keys(creatives[0]?.videoSequences).length > 0) ||
    (!!creatives[1]?.videoSequences &&
      Object.keys(creatives[1]?.videoSequences).length > 0);

  return (
    <ViewerContainer>
      <Subheader
        title={
          <Text as="span" type="body2">
            AB Test &nbsp;
            <Link
              title="Preview this ad in a new tab"
              maxWidth="200px"
              target="_blank"
              href={generatePreviewerURL(
                creatives[0]?.buildId,
                creatives[0]?.format
              )}
            >
              {creatives[0]?.creativeNames || ""}
            </Link>
            {creatives[0]?.creativeNames && <> vs </>}
            <Link
              title="Preview this ad in a new tab"
              maxWidth="200px"
              target="_blank"
              href={generatePreviewerURL(
                creatives[1]?.buildId,
                creatives[1]?.format
              )}
            >
              {creatives[1]?.creativeNames || ""}
            </Link>
          </Text>
        }
        // 🚨 TODO check how to calculate combined sessions for whitelist?
        // sessionSize={sessionSize}
        // minSessions={2000}
      >
        <Share
          description={"Share a link to this AB test"}
          content={"Share"}
          url={window.location.href}
        />
      </Subheader>
      {creatives.length === 2 && creatives[0]?.format ? (
        <MobileAdLayout
          creatives={creatives}
          wheelHandler={wheelHandler}
          metArray={metArray}
          previewerRefs={previewerRefs}
          keyColors={keyColors}
        />
      ) : (
        <SpinnyCat size="s" />
      )}

      {props.loggedIn && <ABGUI />}

      {zeroMetricError && (
        <SnackBarAlert
          title={
            <>
              We're having some issues fetching the metrics for this AB test.
              Sorry! Please check back a little later.
            </>
          }
          status="warning"
        />
      )}
      {formatIsntHangtimeError && (
        <SnackBarAlert
          title="This ad format isn't supported yet in AB Tests. Please stick to hangtime ads for now."
          status="warning"
        />
      )}
      {creativeHasVideoError && (
        <SnackBarAlert
          title="Creatives with video aren't really supported in AB Tests yet, so take the results with a grain of salt."
          delayToClose={8000}
          status="warning"
        />
      )}
    </ViewerContainer>
  );
};
