import { motion } from "framer-motion";
import { Flex, Box, Text } from "@chakra-ui/react";
import { getPercentageChange } from "../../util/helpers";
import { BiLineChart } from "react-icons/bi";
import { IconInBox } from "../../components/UI/Buttons/IconInBox.jsx";
import { valueToColor } from "../../util/helpers";
import { PercentChangeValue } from "../../components/Charts/PercentChangeValue.jsx";

export const WeightingBar = ({
  values,
  index,
  label,
  icon,
  color,
  showLabel = true,
}) => {
  const percentageChange = Math.round(
    getPercentageChange(values[index], values[0])
  );

  const performanceColor = valueToColor(percentageChange);

  return (
    <>
      {showLabel && (
        <Flex
          marginBottom="8px"
          direction="row"
          justify="space-between"
          alignItems="center"
          gap="2"
        >
          <Flex alignItems="center">
            <IconInBox
              width="16px"
              height="16px"
              padding="2px"
              CustomIcon={BiLineChart}
              color={color}
            />
            <Text
              marginLeft="2"
              fontSize="xs"
              lineHeight={1.1}
              fontWeight={500}
            >
              {label}
            </Text>
          </Flex>
          <PercentChangeValue value={values[index]} startValue={values[0]} />
        </Flex>
      )}
      <Flex w="100%" h="8px" bg="#eee" position="relative" borderRadius="sm">
        <Box
          position="absolute"
          left={`${values[0] * 100}%`}
          bg="gray.300"
          w="2px"
          h="100%"
        ></Box>

        <Box
          as={motion.div}
          backgroundColor={performanceColor}
          borderRadius="sm"
          initial={{
            width: `${values[index] * 100}%`,
          }}
          animate={{
            width: `${values[index] * 100}%`,
          }}
        ></Box>
      </Flex>
    </>
  );
};
