export const titleBarParentStyle = {
  w: "100%",
  h: "auto",
  pl: { base: 1.5, md: 2 },
  pr: { base: 1.5, md: 2 },
  pt: { base: 1, md: 1.5 },
  pb: { base: 0, md: 0.5 },
  borderRadius: "md",
  spacing: 0,
};
