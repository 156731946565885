import React, { forwardRef, useEffect } from "react";

import { useCreatives } from "@stores/creatives";
import throttle from "lodash.throttle";
import { useMetrics } from "@stores/metrics";
import PreviewerIFrame from "../../components/PreviewerIFrame/PreviewerIFrame";

export const ABPreviewWrapper = forwardRef(
  (
    { buildId, format, iframeSrcDoc, scriptVersion, scrollMode, i, color },
    forwardRef
  ) => {
    const getCreative = useCreatives((state) => state.get);
    const updateCreative = useCreatives((state) => state.update);

    const getMetrics = useMetrics((state) => state.get);
    const metric = getMetrics(buildId);

    const attentionTime = metric?.attentionTime || null;

    return (
      <PreviewerIFrame
        preventUserScroll={true}
        creativeFormat={getCreative(buildId)?.format}
        key={i}
        ref={(ref) => {
          forwardRef[i] = ref;
          return forwardRef;
        }}
        srcDoc={iframeSrcDoc}
        onAdProgress={(progress) =>
          updateCreative(buildId, {
            creativeScrollProgress: progress,
          })
        }
        scriptVersion={scriptVersion}
        scrollMode={scrollMode}
        isLoading={!scrollMode}
        onLoadHandler={() =>
          updateCreative(buildId, {
            iframeLoaded: true,
          })
        }
        resetTransform={true}
        headerColor={color}
        sessionSize={getCreative(buildId)?.sessionSize}
        attentionTime={attentionTime?.toFixed(2)}
        isAB={true}
      />
    );
  }
);
