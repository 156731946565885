import { Button, Flex, HStack, Box, Center, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { IconInBox } from "../UI/Buttons/IconInBox.jsx";
import { ChartButton } from "../UI/Buttons/ChartButton.jsx";
export const CustomLegend = ({
  series,
  inactiveSeries,
  onSelect,
  onHoverStart,
  onHoverEnd,
}) => {
  return (
    <Wrap spacing="20px" justify="center">
      {series.map((item) => {
        const { color, icon, key } = item;
        const active = !inactiveSeries.includes(key);
        return (
          <WrapItem>
            <ChartButton
              text={key}
              color={color}
              icon={icon}
              active={active}
              onMouseEnter={() => {
                onHoverStart(key);
              }}
              onMouseLeave={() => {
                onHoverEnd(null);
              }}
              onClick={() => {
                onSelect(key);
              }}
            ></ChartButton>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};
