import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import { Divider, Flex, Text } from "@chakra-ui/react";
import { interpolateColor } from "../../util/velocity";

const Gauge = ({
  pressure,
  averagePressure,
  avgScrollDistance,
  scrollDistance,
}) => {
  const [lines, setLines] = useState([]);
  const [activeLineId, setActiveLineId] = useState(null);
  const maxY = 400;
  const margin = 20;
  const tickInterval = 100;
  const maxPressure = 2;
  const orange = { r: 255, g: 165, b: 0 };
  const pink = { r: 255, g: 105, b: 180 };

  const calculateYPosition = (pressure) => {
    const effectiveMaxY = maxY - margin * 2;
    let yPos = margin + (1 - pressure / maxPressure) * effectiveMaxY;
    // Clamp yPos to ensure it's within the drawable area
    yPos = Math.max(margin, Math.min(yPos, maxY - margin));
    return yPos;
  };

  useEffect(() => {
    if (pressure > 0) {
      const now = Date.now();
      if (
        !activeLineId ||
        now - lines.find((line) => line.id === activeLineId)?.lastUpdated > 500
      ) {
        const newLine = {
          id: uuidv4(),
          pressure: pressure,
          lastUpdated: now,
        };
        setLines((lines) => [...lines, newLine]);
        setActiveLineId(newLine.id);
      } else {
        setLines((lines) =>
          lines.map((line) =>
            line.id === activeLineId
              ? { ...line, pressure: pressure, lastUpdated: now }
              : line
          )
        );
      }
    }
  }, [pressure]);

  // Interval to check inactivity
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      if (activeLineId) {
        const activeLine = lines.find((line) => line.id === activeLineId);
        if (activeLine && now - activeLine.lastUpdated > 500) {
          setLines((lines) =>
            lines.map((line) => {
              if (line.id === activeLineId) {
                return { ...line, pressure: 0 };
              }
              return line;
            })
          );
          setActiveLineId(null); // Deactivate the line after 500ms of inactivity
        }
      }
    }, 100); // Checking every 100ms

    return () => clearInterval(interval);
  }, [activeLineId, lines]);

  return (
    <>
      <svg
        width="300"
        height="500"
        viewBox="-20 -20 340 640"
        style={{ marginLeft: "-15px", zIndex: 1 }}
      >
        {/* Y-axis and ticks */}
        {[...Array(Math.floor(maxY / tickInterval) + 1)].map((_, i) => (
          <g key={i} transform={`translate(0, ${maxY - i * tickInterval})`}>
            <line
              x1="30"
              x2="500"
              y1="0"
              y2="0"
              stroke="lightgray"
              strokeWidth="1"
            />
            <text x="0" y="5" fill="grey" fontSize="16" textAnchor="end">
              {(((i * tickInterval) / maxY) * maxPressure).toFixed(1)}
            </text>
          </g>
        ))}
        {/* Average line */}
        <motion.text
          x="43"
          y={calculateYPosition(averagePressure) + 5}
          fill="#4EC1E0"
          fontSize="18"
          textAnchor="end"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.5,
            delay: 1,
          }}
        >
          creative
        </motion.text>
        <motion.line
          x1="60"
          x2="500"
          strokeWidth="5"
          strokeLinecap="round"
          stroke="#4EC1E0"
          y1={calculateYPosition(0)}
          y2={calculateYPosition(0)}
          initial={{ opacity: 0 }}
          animate={{
            y1: calculateYPosition(averagePressure),
            y2: calculateYPosition(averagePressure),
            opacity: 1,
          }}
          transition={{
            type: "spring",
            stiffness: 80,
            damping: 8,
            duration: 0.5,
            delay: 0.5,
          }}
        ></motion.line>
        {/* Baseline line (fake) */}
        <motion.text
          x="45"
          y={calculateYPosition(0.7) + 5}
          fill="grey"
          fontSize="18"
          textAnchor="end"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 0.5,
            delay: 2,
          }}
        >
          baseline
        </motion.text>
        <motion.line
          stroke-dasharray="4"
          x1="60"
          x2="400"
          strokeLinecap="round"
          strokeWidth="5"
          stroke="lightgray"
          initial={{ opacity: 0 }}
          y1={calculateYPosition(0)}
          y2={calculateYPosition(0)}
          animate={{
            y1: calculateYPosition(0.7),
            y2: calculateYPosition(0.7),
            opacity: 1,
          }}
          transition={{
            type: "spring",
            stiffness: 80,
            damping: 8,
            duration: 0.5,
            delay: 1.5,
          }}
        ></motion.line>
        {/* Orange text behind the lines */}
        {pressure > 1.9 && (
          <>
            <motion.text
              x="100"
              y="60"
              width="260"
              height="100"
              fill="red"
              fontWeight={600}
              fontFamily={"'Courier New', monospace"}
              initial={{ opacity: 0 }}
              animate={{
                opacity: [0, 0.3, 0.3, 0], // Sequence of opacity values to create the flashing effect
              }}
              fontSize={"40px"}
              transition={{
                duration: 0.1, // Adjust duration to control the speed of the flash
                times: [0, 0.25, 0.75, 1], // Controls the timing of the opacity changes within each cycle
                repeat: Infinity,
                repeatType: "loop",
                // delay: 0.25, // Initial delay before starting the animation
              }}
            >
              MAXING!!!
            </motion.text>
          </>
        )}

        {/* Pressure lines */}
        {lines.map((line) => (
          <motion.line
            key={line.id}
            x1="50"
            x2="500"
            y1={calculateYPosition(0)}
            y2={calculateYPosition(0)}
            stroke={interpolateColor({
              value: line.pressure,
              minValue: 0,
              maxValue: maxPressure,
              startColor: orange,
              endColor: pink,
            })}
            strokeWidth="5"
            animate={{
              y1: calculateYPosition(line.pressure),
              y2: calculateYPosition(line.pressure),
              opacity: line.pressure > 0 ? 1 : 0,
            }}
            transition={{
              type: "spring",
              stiffness: 70,
              damping: 6,
              duration: 0.1,
            }}
          />
        ))}
      </svg>
    </>
  );
};

export default Gauge;
