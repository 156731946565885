import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  ButtonGroup,
  ChakraProps,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  Flex,
  IconButton,
  Input,
  useEditableControls,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

interface EditableDescriptionProps {
  showEditControls?: boolean; // if user isn't logged in, don't show edit controls
  textStyle?: ChakraProps; // ⚠️ NOTE: also applies to input
  inputStyle?: ChakraProps;
  onEdit: (content: string) => Promise<void>;
  isLoading?: boolean;
  fieldType?: "text" | "textarea";
  value: string;
}

export const EditableDescription = ({
  showEditControls = true,
  textStyle,
  inputStyle,
  onEdit,
  isLoading = false,
  fieldType = "text",
  value,
}: EditableDescriptionProps) => {
  const toast = useToast();

  const EditableControls = ({ isLoading }) => {
    if (!showEditControls) return null;

    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center">
        <IconButton
          {...iconStyleProps}
          icon={<CheckIcon />}
          isLoading={isLoading}
          {...getSubmitButtonProps()}
        />
        <IconButton
          {...iconStyleProps}
          icon={<CloseIcon />}
          isLoading={isLoading}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          {...iconStyleProps}
          icon={<EditIcon />}
          isLoading={isLoading}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  };

  const [editableValue, setEditableValue] = useState(value);

  useEffect(() => {
    setEditableValue(value); // Update internal state when external value changes
  }, [value]);

  const handleEdit = async (content: string) => {
    if (content === value) return;
    setEditableValue(content); // Update local state on edit
    try {
      await onEdit(content);
      toast(SUCCESS_TOAST);
    } catch (e) {
      toast(ERROR_TOAST);
    }
  };

  return (
    <Editable
      value={editableValue}
      pointerEvents={showEditControls ? "inherit" : "none"}
      onChange={(newVal) => setEditableValue(newVal)}
      onSubmit={handleEdit}
      display="flex"
      flexDir="row"
      w="100%"
      className="editable-description" //for hiding on screenshots etc
    >
      <EditablePreview
        _hover={
          showEditControls && {
            cursor: "pointer",
            backgroundColor: "yellow.100",
          }
        }
        fontSize="md"
        textAlign="center"
        w="100%"
        {...textStyle}
      />
      <Input
        as={fieldType === "text" ? EditableInput : EditableTextarea}
        {...inputStyleDefaults}
        {...inputStyle}
        {...textStyle}
      />
      <EditableControls isLoading={isLoading} />
    </Editable>
  );
};

const iconStyleProps = {
  color: "gray.800",
  fontSize: "1em",
  size: "sm",
  variant: "ghost",
};

const inputStyleDefaults = {
  p: "0!important",
  m: "0!important",
  w: "100%",
  height: "auto",
  fontSize: "md",
  fontWeight: "inherit",
  display: "flex",
  color: "gray.600",
  border: "none",
  outline: "none",
  maxW: "800px",
  textAlign: "center",
};

const ERROR_TOAST = {
  title: "Error updating description",
  description: "Please check your connection and try again.",
  status: "error" as "error" | "success" | "warning" | "info", // sad
  duration: 7000,
  isClosable: true,
  position: "top",
};

const SUCCESS_TOAST = {
  title: "Update made 👍",
  status: "success" as "error" | "success" | "warning" | "info", // sad
  duration: 2000,
  isClosable: true,
  position: "top",
};
