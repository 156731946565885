import { useState, useEffect } from "react";
import { getDCOData } from "@services/firestore";

export function useDcoData(buildID) {
  const [dcoData, setDcoData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getDCOData(buildID).then((data) => {
      setDcoData(data);
      setIsLoading(false);
    });
  }, []);
  return [dcoData, isLoading];
}
