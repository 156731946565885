import { Box, Image, keyframes, Spinner } from "@chakra-ui/react";

const filmstripAnimation = keyframes({
  "0%": { backgroundPosition: "0 0" },
  "40%": { backgroundPosition: "100% 0" },
  "50%": { backgroundPosition: "100% 0" },
  "90%": { backgroundPosition: "0 0" },
  "100%": { backgroundPosition: "0 0" },
});

export const AnimatedAdPreviewThumb = ({ filmstripUrl }) => {
  return (
    <Image
      //
      w="auto"
      h="100%"
      minH="100px"
      maxH="100%"
      aspectRatio="120/238"
      //
      bg="gray.300"
      boxShadow={imgLightBoxShadow}
      //
      borderRadius="md"
      outline={"2px solid"}
      outlineOffset={"-1px"}
      outlineColor={"whiteAlpha.600"}
      //
      bgImage={`url(${filmstripUrl})`}
      bgRepeat="no-repeat"
      bgPosition="0 0"
      bgSize="auto 100%"
      //
      animation={`${filmstripAnimation} 4s steps(19) infinite paused`}
      _groupHover={{
        animationPlayState: "running",
      }}
    />
  );
};
const imgLightBoxShadow =
  "0px 15px 40px 0px rgba(18, 41, 64, 0.20), 0px 5px 10px 0px rgba(18, 41, 64, 0.10), 0px 0px 0px 1px rgba(18, 41, 64, 0.04), 0px 2px 0px 2px rgba(18, 41, 64, 0.05)";
