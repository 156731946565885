import amplitude from 'amplitude-js';
import { useEffect } from 'react';
import { userMetadata } from "../../data/userMetadata.js";

// move to env variable
const consoleLogging = false;

export const useStartAnalytics = () => {
  useEffect(() => {
    if (consoleLogging) console.log('Starting analytics - process.env.AMPLITUDE_KEY', process.env.AMPLITUDE_KEY);
    if (!!process.env.AMPLITUDE_KEY) {
        amplitude.getInstance().init(process.env.AMPLITUDE_KEY);
    }
    else if (consoleLogging) console.warn(`No Amplitude key found, analytics will not be initialized. Please chat to CPT about setting up your key in .env.development`);
  }, []);
};

export const sendUserToAnalytics = (user) => {
  if (consoleLogging) console.log('setting user: ', user);
  const foundUser = userMetadata.find(meta => meta.email === user.email);
  const team = foundUser?.team || '';
  amplitude.getInstance().setUserId(user.uid);
  amplitude.getInstance().setUserProperties({
    name : user.displayName || (user.email || ''),
    email : user.email || '',
    cohort: team
  });
}

const analyticsSendEvent = (eventName, eventProperties = null) => {
  if (consoleLogging) console.log('sent event: ', eventName, eventProperties);
  return amplitude.getInstance().logEvent(eventName, eventProperties);
}

// ⚠️
// to be kept up to date with this spreadsheet
// https://bit.ly/3yITi9D

export const logAnalytics = {

  // Creative viewer
  creativeViewed :
  (build_id:string, in_ab_test:boolean = false) => {
    analyticsSendEvent('creativeViewed', {build_id, in_ab_test})
  },
  creativeSessionsLoaded :
  (build_id:string, in_ab_test:boolean = false, sessions:number, format:string) => {
    analyticsSendEvent('creativeSessionsLoaded', {build_id, in_ab_test, sessions, format})
  },
  creativePreviewScrubed :
  (build_id:string) => {
    analyticsSendEvent('creativePreviewScrubed', {build_id})
  },
  creativeQrExpand :
  (build_id:string, direction:string ) => {
    analyticsSendEvent('creativeQrExpand', {build_id, direction})
  },

  // AB Test
  abtestViewed :
  (build_ids: Array<string>) => {
    analyticsSendEvent('abtestViewed', {build_ids})
  },
  abtestLoaded :
  (build_ids: Array<string>) => {
    analyticsSendEvent('abtestLoaded', {build_ids})
  },
  abtestScrubed :
  (build_ids: Array<string>) => {
    analyticsSendEvent('abtestScrubed', {build_ids})
  },

  // Shared UI Interactions
  featureShareOpened :
  (build_ids: Array<string>) => {
    analyticsSendEvent('featureShareOpened', {build_ids})
  },
  featureShareInteraction :
  (build_ids: Array<string>, type:string) => {
    // avilable types: copy_link, open_in_new_tab
    analyticsSendEvent('featureShareInteraction', {build_ids, type})
  },
  featureFeedbackOpened :
  (build_id:string = 'unknown') => {
    analyticsSendEvent('featureFeedbackOpened', {build_id})
  },
  featureFeedbackSubmit :
  (build_id:string = 'unknown', type:string) => {
    // avilable types: submit, cancel
    analyticsSendEvent('featureFeedbackSubmit', {build_id, type})
  },

  // Demo
  demoViewed :
  () => {
    analyticsSendEvent('demoViewed')
  },
  demoNoSessions :
  () => {
    analyticsSendEvent('demoNoSessions')
  },
  demoNoCreative :
  () => {
    analyticsSendEvent('demoNoCreative')
  },
  demoClearedData :
  () => {
    analyticsSendEvent('demoClearedData')
  },
  demoClickedHowToUse :
  () => {
    analyticsSendEvent('demoClickedHowToUse')
  },

  // Errors
  error404 :
   (url:string = 'unknown') => {
    analyticsSendEvent('error404', { url })
  },
  errorGeneral :
  (error:string = 'unknown', url:string = 'unknown') => {
    analyticsSendEvent('errorGeneral', {error, url })
  },

  // GUI
  guiPanel :
  (type:string = 'unknown') => {
    analyticsSendEvent('guiPanel', { type })
  },
  guiAdjust :
  (setting:string = 'unknown', value:string = 'unknown') => {
    analyticsSendEvent('guiAdjust', { setting, value })
  },
  guiDomainWhitelistChange :
  (domain:string = 'unknown', url='unknown', in_ab_test:boolean = false ) => {
    analyticsSendEvent('guiDomainWhitelistChange', { domain, url, in_ab_test })
  },
}