import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";

export const AnimatedCounter = ({ from, to, skipAnimation }) => {
  const ref = useRef();

  useEffect(() => {
    if (
      skipAnimation ||
      !ref.current ||
      from === to ||
      isNaN(from) ||
      isNaN(to)
    ) {
      return;
    }
    const controls = animate(from, to, {
      duration: 1.5,
      delay: 1,
      ease: "circOut",
      onUpdate(value) {
        ref.current.textContent = value.toFixed(1);
      },
    });
    return () => controls.stop();
  }, [from, to]);
  if (skipAnimation) {
    return <span>{to.toFixed(1)}</span>;
  }
  return <span ref={ref} />;
};
