import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  ChakraProps,
  Text,
} from "@chakra-ui/react";
import { AnimatedCounter } from "./helpers";

interface CatStatProps extends ChakraProps {
  title: string;
  value: number;
  unit?: string;
  benchmark?: number;
  animate?: boolean;
}

export const CatStat = ({
  title,
  value,
  unit,
  benchmark,
  animate = true,
  ...rest
}: CatStatProps) => {
  const from = animate ? 0 : value;
  return (
    <Stat {...rest} maxW="160px">
      <StatLabel {...sharedStyle}>{title}</StatLabel>
      <StatNumber {...sharedStyle} fontSize="3xl">
        <AnimatedCounter from={from} to={value} skipAnimation={!animate} />
        <Text as="span" fontSize="md" display={unit ? "inline" : "none"} ml={1}>
          {unit}
        </Text>
      </StatNumber>
      {benchmark && (
        <StatHelpText {...sharedStyle}>
          <StatArrow type={value >= benchmark ? "increase" : "decrease"} />
          {((value * 100) / benchmark - 100 || 0).toFixed(2)}%
        </StatHelpText>
      )}
    </Stat>
  );
};

const sharedStyle = {
  fontFamily: "roboto mono",
  textTransform: "uppercase",
  color: "gray.700",
};
