import styled from "@emotion/styled";
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@ui-lib";
import React, { useState } from "react";
import { getChartColor } from "../../util/dcoaUtils";
import { VideoPlayer } from "./video/VideoPlayer";

export const VideoSelector = ({
  recommendations,
  onMouseEnterLeave,
  onSelect,
  start,
  end,
  selectedId,
  highlightedId,
  winnerId,
}) => {
  const [isUserMuted, setIsUserMuted] = useState(false);
  return (
    <VideoSelectorRoot>
      <VideoPlayersWrapper>
        {recommendations.map(({ videoSource, id }, i) => {
          return (
            <VideoPlayer
              id={id}
              key={id}
              src={videoSource}
              isSelected={selectedId === id}
              isHighlighted={highlightedId === id}
              onMouseEnterLeave={onMouseEnterLeave}
              onSelect={onSelect}
              color={getChartColor(i)}
              isUserMuted={isUserMuted}
              isWinner={winnerId === id}
              {...{ start, end }}
            />
          );
        })}
      </VideoPlayersWrapper>
      <span>
        <Button
          title={isUserMuted ? "unmute videos" : "mute videos"}
          onClick={() => setIsUserMuted((value) => !value)}
        >
          {isUserMuted ? (
            <FontAwesomeIcon icon={faVolumeMute} />
          ) : (
            <FontAwesomeIcon icon={faVolumeUp} />
          )}
        </Button>
      </span>
    </VideoSelectorRoot>
  );
};

const VideoSelectorRoot = styled.div`
  display: grid;
  grid-gap: var(--space-2);
  padding: var(--space-2) 0;
`;

const VideoPlayersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--space-4);
  width: 100%;
  max-width: 1200px;
`;
