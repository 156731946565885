import Game from "@components/Game/Game";
import { logAnalytics } from "@utils/analyticsUtil";
import {useEffect} from "react";

const MissingPage = () => {
  useEffect(() => {
    logAnalytics.error404(window.location.href);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "750px",
          alignItems: "center",
        }}
      >
        <h2>We're sorry, the report you requested could not be found... 😿</h2>
        <p style={{ marginBottom: "50px" }}>
          (Here's a game to make up for it!)
        </p>
        <Game />
      </div>
    </div>
  );
};

export default MissingPage;
