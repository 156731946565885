import React from "react";
import styles from "./Modal.module.scss";
import Backdrop from "../Backdrop/Backdrop";
import closeButton from "@assets/images/close.svg";

export default ({ show, onClose, children }) => {
  return (
    <div>
      <Backdrop show={show} clicked={onClose} />
      <div
        className={styles.Modal}
        style={{
          transform: show ? "translateY(0)" : "translateY(-100vh)",
          opacity: show ? "1" : "0",
        }}
      >
        <img
          src={closeButton}
          alt="Close modal"
          className={styles.closeButton}
          onClick={onClose}
        />

        {children}
      </div>
    </div>
  );
};
