import { getScrollQueueFromEngadements } from "./fromEngagements";
import { getScrollQueueFromScrollData } from "./fromScrollData";

export { getScrollQueueFromEngadements, getScrollQueueFromScrollData };

export type EngadementScrollData = {
  direction: number;
  y: number;
  time: number;
  engadementIndex: number; // used to jump between sessions
}[][][];

export type RawScrollData = {
  x: number; // time
  y: number; // scroll position
}[];

export type ScrollQueueConfig = {
  initalY?: number;
  durationScale: number;
  scrollLengthScale: number;
  delayDurationScale?: number;
  minDuration: number;
  maxDuration: number;

  easingFunction?: (t: number) => number;
};
export type ScrollQueueReturn = {
  duration: number;
  yDifference: number;
  engadementIndex: number;
}[];
