import { Reorder } from "framer-motion";
import { WeightingBar } from "./WeightingBar";
import { useTheme } from "@chakra-ui/react";
import { scrollToElementById } from "../../util/scrollToElement.js";
import { MenuItem } from "./MenuItem";

export const ReorderList = ({ index, list, byVariation, inViewItem }) => {
  const theme = useTheme();
  return (
    <>
      {list && (
        <Reorder.Group
          axis="y"
          values={list}
          onReorder={() => {}}
          style={{ width: "100%" }}
        >
          {list[index]?.variations.map((item, i) => {
            const { id, created } = item;
            const variationHistory = byVariation.find((v) => v.id === id);
            const color = theme.colors[variationHistory.color]["500"];
            return (
              <Reorder.Item
                style={{ listStyle: "none" }}
                dragListener={false}
                key={id}
                value={created}
                onClick={() => {
                  scrollToElementById(id);
                }}
              >
                <MenuItem id={id} selected={inViewItem === id}>
                  <WeightingBar
                    label={variationHistory.name}
                    color={color}
                    values={variationHistory.history.map((v) => v.weighting)}
                    index={index}
                  />
                </MenuItem>
              </Reorder.Item>
            );
          })}
        </Reorder.Group>
      )}
    </>
  );
};
