import styled from "@emotion/styled";
import React from "react";
interface HRProps extends React.HTMLProps<HTMLHRElement> {
  margin?: "1" | "2" | "3" | "4" | "5";
}

const StyledHR = styled.hr<{ margin: HRProps["margin"] }>`
  width: 100%;
  border: none;
  border-top: 1px solid var(--colors-neutral-4);
  margin: var(--space-${({ margin }) => margin}) 0;
`;

export const Hr = (
  ({ margin = "3" }: HRProps) => {
    return (
      <StyledHR margin={margin} />
    );
  }
);
