import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { logAnalytics } from '@utils/analyticsUtil';

import { CreativeStore } from "../../store/CreativeStore";
import styles from "./DemoControls.module.scss";

const DemoControls = () => {
  const clearDemoData = CreativeStore((state) => state.clearDemoData);
  const buildID = CreativeStore((state) => state.buildID);

  const handleClearDemoData = () => {
    logAnalytics.demoClearedData();
    clearDemoData(buildID);
  };

  const handleHowToClick = () => {
    logAnalytics.demoClickedHowToUse();
  };

  return (
    <div className={styles.demoControls}>
      <div className={styles.flush} onClick={handleClearDemoData}>
        <FontAwesomeIcon icon={faEraser} className={styles.icon} />
        Clear demo data
      </div>

      <div className={styles.howTo}>
        <a
          href="https://playgroundxyz.atlassian.net/wiki/spaces/SAL/pages/1455850175/How+to+use+the+CAT+demo"
          target="_blank"
          onClick={handleHowToClick}
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faQuestion} className={styles.icon} />
          How to use demo mode
        </a>
      </div>
    </div>
  );
};

export default DemoControls;
