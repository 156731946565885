@import "colors.scss";

.catrouselSelector {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 900px;
  margin: 100px auto;
  position: relative;
  .creativeContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    a {
      width: 30%;
      .creativeWrapper {
        width: 100%;
        overflow: hidden;
        background-color: $white;
        display: inline-block;
        margin: 10px;
        // border: 2px solid $white;
        padding: 60px 20px 60px 20px;
        box-sizing: border-box;
        border-radius: 10px;
        box-shadow: $shadow50med;
        transition: all 0.2s ease-in-out;
        position: relative;
        img {
          width: 100%;
          border-radius: 10px;
        }
        p {
          margin-bottom: 0;
          text-align: center;
          color: $darkgrey;
        }
        &:hover {
          transform: scale(1.05);
          box-shadow: $shadow50high;
        }
        .creativeName {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          background: $darkpinkgradient;
          color: $white;
          font-size: 12px;
          padding: 10px 10px;
          text-align: center;
          box-sizing: border-box;
        }
        .campaign {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          background: $grey;
          color: $white;
          font-size: 12px;
          padding: 10px 10px;
          text-align: center;
          box-sizing: border-box;
        }
      }
    }
  }
}
