import { useState } from "react";
import { LineChart } from "@components/Charts/LineChart";
import { Flex, Text, Box, VStack, Divider, useTheme } from "@chakra-ui/react";
import { PreviewWrapper } from "./PreviewWrapper";
import { Control } from "./Control";
import { Card } from "./Card";
import { BiListCheck } from "react-icons/bi";

export const DcoResults = ({
  history,
  setInViewItem,
  setIndex,
  optimisationPerformance,
  index,
  buildID,
  byVariation,
  active,
}) => {
  const theme = useTheme();
  const [scrollProgress, setScrollProgress] = useState(0);

  // converting into the data we need with the correct color values
  const processedVariations = byVariation.map((item) => {
    return {
      data: item.history[index].data,
      key: item.name,
      color: theme.colors[item.color]["500"],
      icon: BiListCheck,
      fill: false,
      dashed: false,
    };
  });
  return (
    <Card
      id="results"
      heading="Catalyst Results"
      icon={BiListCheck}
      setInViewItem={() => {
        setInViewItem("results");
      }}
    >
      <Box display="flex" direction="column" position="relative" px="30px">
        <VStack
          alignItems="flex-start"
          padding="20px 0px"
          w="60%"
          spacing="20px"
        >
          <Text fontSize="md" fontWeight={400}>
            During optimisation, Catalyst delivers different ad variations to
            different users. After the optimisation period, the variations that
            received the most user interaction will be delivered most
            frequently.
          </Text>
          <Text fontSize="md" fontWeight={400}>
            This campaign contains <strong>{byVariation.length}</strong>{" "}
            variations. View the performance of each variation below.
          </Text>
          <Flex direction={"column"} width="100%">
            {/* In here we have a overall CAT score chart for overall vs control */}
            {optimisationPerformance && (
              <LineChart
                header={{
                  title: "Overall vs Control",
                  keys: [
                    { title: "Overall", color: theme.colors.red["600"] },
                    { title: "Control", color: theme.colors.gray["500"] },
                  ],
                  tooltip:
                    "The combined performance of all variations vs a small control (non-optimised) group.",
                }}
                active={active}
                animationDuration={0}
                height={200}
                xAxisDomain={[0, optimisationPerformance.overall.length - 1]}
                yAxisDomain={[0, 70]}
                showLegend={false}
                series={[
                  {
                    data: optimisationPerformance.overall.slice(0, index + 1),
                    key: "Catalyst",
                    color: theme.colors.red["600"],
                    // icon: BiListCheck, // icons are available to be passed in to the key buttons, but we don't have any yet
                    fill: true,
                    dashed: false,
                  },
                  {
                    data: optimisationPerformance.control.slice(0, index + 1),
                    key: "Control",
                    color: theme.colors.gray["500"],
                    // icon: BiListCheck, // icons are available to be passed in to the key buttons, but we don't have any yet
                    fill: false,
                    dashed: true,
                  },
                ]}
              />
            )}
          </Flex>
          <Divider marginTop="-20px" />
          <Flex direction={"column"} width="100%">
            {history[index]?.control?.scrubs && (
              <LineChart
                active={active}
                header={{
                  title: "Performance by Variation",
                  tooltip:
                    "Compare the individual Creative Attention performance of each ad variation.",
                }}
                height={300}
                xAxisDomain={[20, 80]}
                yAxisDomain={[0, 1]}
                adProgress={scrollProgress}
                showLegend={true}
                series={[
                  {
                    data: history[index].overall.scrubs,
                    key: "Overall",
                    color: theme.colors["red"]["600"],
                    fill: false,
                    dashed: false,
                  },
                  {
                    data: history[index].control.scrubs,
                    key: "Control",
                    color: theme.colors["gray"]["500"],
                    fill: false,
                    dashed: true,
                  },
                  ...processedVariations,
                ]}
              />
            )}
          </Flex>
        </VStack>
        <Flex pos={"absolute"} top="-50px" right="0px" px="30px">
          <Control
            active={active}
            index={index}
            setIndex={setIndex}
            history={history}
          />
        </Flex>
        <VStack>
          <PreviewWrapper
            setScrollProgress={setScrollProgress}
            buildID={buildID}
            dcoVariation={byVariation[0].id}
            title={byVariation[0].name}
            color={byVariation[0].color}
            emoji={"🏆"}
            scale={0.9}
            position={{ top: "50px", right: "-30px" }}
          />
        </VStack>
      </Box>
    </Card>
  );
};
