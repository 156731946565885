@import "colors.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  color: $grey;
  padding: 10px;
  z-index: 999;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid var(--colors-neutral-4);
  height: 50px;
  min-width: var(--minScreenWidth);

  .headerInner {
    display: flex;
    max-width: var(--maxScreenWidth);
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0 27px 0 19px;
    @media screen and (max-width: 1450px) {
      padding: 0 15px 0 8px;
    }
    .headerWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .nav {
        position: relative;
        display: flex;
        justify-content: center;
        margin-left: 20px;
        padding-left: 10px;
        border-left: 1px solid #cacaca;
        a {
          position: relative;
          margin: 0 10px;
          text-decoration: none;
          color: $grey;
          &.active {
            color: $pink;
          }
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
  a {
    text-decoration: none;
  }
  .cat {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    &.noLink {
      cursor: auto;
    }
    h1 {
      font-weight: 900;
      color: $grey;
      font-size: 12px;
      text-transform: uppercase;
      user-select: none;
      text-decoration: none !important;
      font-style: italic;
      sup {
        font-size: 10px;
        color: $pink;
        line-height: 12px;
        margin-left: 5px;
        font-weight: 500;
      }
    }
    .catLogo {
      height: 27px;
      width: auto;
      display: block;
      margin-left: 10px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
    a {
      height: 100%;
      position: absolute;
      left: 20px;
      display: flex;
      align-items: center;
      sup {
        font-size: 10px;
        color: $pink;
        line-height: 12px;
        margin-left: 5px;
        font-weight: 500;
      }
    }
    @media (max-width: 630px) {
      p {
        display: none;
      }
    }
  }
}
