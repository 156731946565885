import DwellTimeChart from "@components/DwellTimeChart/DwellTimeChart";
// import { MetricPanel } from "@components/Metrics/MetricPanel";
import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import ScrubChart from "@components/ScrubChart/ScrubChart";
import SingleVideoChart from "@components/VideoChart/SingleVideoChart";
import { HowDoWeCalculate } from "@composites/HowDoWeMeasure";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { getOverlapScrollsData } from "../../../src/util/sequencesProcessor";
import { EditableDescription } from "../../components/UI/EditableDescription";
import { useCreativeAnnotations } from "../../hooks/useCreativeAnnotations";
import { CreativeStore } from "../../store/CreativeStore";
import { GUIStore } from "../../store/GUIStore";
import { UIStore } from "../../store/UIStore";
import { GraphBody, GraphRoot } from "./";
// const SnackBarAlert = withAlertsRender(SnackBar);

const Graph = ({ creative, adProgress, showMetric, demoMode }) => {
  const userInfo = UIStore((state) => state.userInfo);

  const showDwellTime = GUIStore((state) => state.showDwellTime);
  const startProgress = GUIStore((state) => state.startProgress);
  const endProgress = GUIStore((state) => state.endProgress);
  const setStartProgress = GUIStore((state) => state.setStartProgress);
  const setEndProgress = GUIStore((state) => state.setEndProgress);
  const [sequenceData, setSequenceData] = useState([]);
  const iframeVideo = CreativeStore((state) => state.iframeVideo);
  const videoSequences = CreativeStore((state) => state.videoSequences);
  const videoDropOffBaseline = CreativeStore(
    (state) => state.videoDropOffBaseline
  );
  const byDurationVideoDropOffBaselines = CreativeStore(
    (state) => state.byDurationVideoDropOffBaselines
  );
  // -----------------------------------------------------
  // should be in presentation mode?
  const queryParams = new URLSearchParams(location.search);
  const isPresentationMode = queryParams.get("layoutMode") === "presentation";
  // this is the URL param that is passed thorugh when gif-generator
  // is viewing the page to create a gif for the slide deck

  const {
    buildID,
    sequences,
    scrollMode,
    dwellTimes,
    scrubBaseline,
    dwellTimeBaseline,
    sessionSize,
    creativeFormat,
    containsVideo,
    usingAggregatedData,
  } = creative;

  const isSampleCreative = buildID === "nayoyo";

  // for different formats we adjust the start and end points for the graph
  useEffect(() => {
    if (creativeFormat !== "hang-time") {
      setStartProgress(20);
      setEndProgress(75);
    } else {
      if (isSampleCreative) {
        setStartProgress(0);
        setEndProgress(100);
      } else {
        setStartProgress(30);
        setEndProgress(65);
      }
    }
  }, [creativeFormat, buildID]);

  // Sequence and Baseline Data processing
  useEffect(() => {
    let creativeSequences = sequences;
    // in demoMode we still need to crunch the raw sequence data
    if (demoMode) {
      creativeSequences = getOverlapScrollsData(
        sequences,
        scrollMode,
        startProgress,
        endProgress,
        sessionSize
      );
    }
    const dataWithBaseline = creativeSequences.map((data, i) => {
      return {
        ...data,
        dwellTimeBaseline: dwellTimeBaseline?.[i],
        scrubBaseline: scrubBaseline?.[i],
      };
    });
    setSequenceData(dataWithBaseline);
  }, [
    sequences,
    showDwellTime,
    scrollMode,
    startProgress,
    endProgress,
    sessionSize,
    dwellTimeBaseline,
    scrubBaseline,
    demoMode,
  ]);

  const {
    annotations,
    setAnnotation,
    loading: annotationsLoading,
  } = useCreativeAnnotations({ buildId: buildID, format: "hangtime" });

  const hasMultipleTabs = showDwellTime || containsVideo;
  const defaultTabToDisplay =
    containsVideo ? "video" : "scrub";
  const [inViewPanel, setInViewPanel] = useState(defaultTabToDisplay);

  return (
    <>
      <GraphRoot>
        <GraphBody>
          <HStack
            display={isPresentationMode || !hasMultipleTabs ? "none" : "flex"}
          >
            <Button
              variant={inViewPanel === "scrub" ? "solid" : "outline"}
              borderWidth="1px"
              size="sm"
              onClick={() => {
                setInViewPanel("scrub");
              }}
            >
              Scrub Chart
            </Button>
            <Button
              display={containsVideo ? "block" : "none"}
              size="sm"
              onClick={() => {
                setInViewPanel("video");
              }}
              variant={inViewPanel === "video" ? "solid" : "outline"}
              borderWidth="1px"
            >
              Video Chart
            </Button>
            <Button
              display={showDwellTime ? "block" : "none"}
              size="sm"
              variant={inViewPanel === "dwell" ? "solid" : "outline"}
              borderWidth="1px"
              onClick={() => {
                setInViewPanel("dwell");
              }}
            >
              Dwell Chart
            </Button>
          </HStack>
          {inViewPanel === "scrub" && (
            <ChartBox
              pt={isPresentationMode ? "0" : "4"}
              pl={isPresentationMode ? "0" : "8"}
              pr={isPresentationMode ? "0" : "12"}
            >
              <ScrubChart
                adProgress={adProgress}
                data={sequenceData}
                scrubBaseline={scrubBaseline}
                dwellTimeBaseline={dwellTimeBaseline}
                startProgress={startProgress}
                endProgress={endProgress}
                showBaseline={!isSampleCreative && !demoMode}
              >
                <EditableDescVStack
                  display={isPresentationMode ? "none" : "flex"}
                  mt="-6"
                >
                  <EditableDescription
                    showEditControls={userInfo?.loggedIn}
                    onEdit={(value) => setAnnotation("scrubChartDesc", value)}
                    value={annotations?.scrubChartDesc}
                    isLoading={annotationsLoading}
                  />
                  <HowDoWeCalculate.Hangtime />
                </EditableDescVStack>
              </ScrubChart>
            </ChartBox>
          )}
          {/* 😬 */}
          {inViewPanel === "video" && (
            <ChartBox
              pt={isPresentationMode ? "0" : "4"}
              pl={isPresentationMode ? "0" : "8"}
              pr={isPresentationMode ? "0" : "12"}
            >
              {containsVideo && iframeVideo && (
                  <SingleVideoChart
                    showBaseline={!isSampleCreative && !demoMode}
                    buildID={buildID}
                    iframeVideo={iframeVideo}
                    videoSequences={usingAggregatedData ? videoSequences : Object.values(videoSequences)[0]}
                    videoDropOffBaseline={videoDropOffBaseline}
                    byDurationVideoDropOffBaselines={
                      byDurationVideoDropOffBaselines
                    }
                  />
              )}
              {/* <EditableDescVStack
                display={isPresentationMode ? "none" : "flex"}
                mt="6"
              >
                <EditableDescription
                  showEditControls={userInfo?.loggedIn}
                  onEdit={(value) => setAnnotation("videoChartDesc", value)}
                  value={annotations?.videoChartDesc}
                  isLoading={annotationsLoading}
                />
              </EditableDescVStack> */}
            </ChartBox>
          )}
          {inViewPanel === "dwell" && (
            <ChartBox
              pt={isPresentationMode ? "0" : "4"}
              pl={isPresentationMode ? "0" : "8"}
              pr={isPresentationMode ? "0" : "12"}
            >
              <DwellTimeChart
                adProgress={adProgress}
                data={sequenceData.map((sequence, i) => {
                  const combined = sequence;
                  combined.dwellTime = dwellTimes[i].dwellTime;
                  return combined;
                })}
                scrubBaseline={scrubBaseline}
                dwellTimeBaseline={dwellTimeBaseline}
                startProgress={startProgress}
                endProgress={endProgress}
              />
            </ChartBox>
          )}
        </GraphBody>

        {/* {showMetric && (
          <MetricPanel
            {...{
              buildID,
              engagementRate,
              benchmarkEngagementRate,
              demoMode,
              engagedAttentionTime,
              benchmarkEngagedAttentionTime,
              creativeFormat,
              vertical,
            }}
          />
        )} */}
      </GraphRoot>
    </>
  );
};

const ChartBox = ({ children, ...rest }) => {
  return (
    <Box
      w="100%"
      h="100%"
      as={motion.div}
      gap="0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      {...rest}
    >
      {children}
    </Box>
  );
};

const EditableDescVStack = ({ children, ...rest }) => {
  return (
    <VStack
      className="editable-description-container"
      px="16"
      pb="6"
      w="100%"
      gap="1.5"
      {...rest}
    >
      {children}
    </VStack>
  );
};

export default Graph;
