function isChrome() {
  var userAgent = navigator.userAgent;
  var isChromium = window.chrome;
  var isChrome = userAgent.indexOf('Chrome') > -1;
  var isEdge = userAgent.indexOf('Edg') > -1; // For Microsoft Edge Chromium
  var isOpera = userAgent.indexOf('OPR') > -1; // For Opera
  var isBrave = navigator.brave !== undefined; // For Brave detection

  // Check if it's Chrome and not Edge, Opera, or Brave
  if (
    isChromium !== null &&
    isChromium !== undefined &&
    isChrome &&
    !isEdge &&
    !isOpera &&
    !isBrave
  ) {
    return true; // It's Chrome
  } else {
    return false; // Not Chrome (could be another Chromium-based browser)
  }
}

export { isChrome };
