// Velocity module contains shared functions for processing scroll
// data and calculating velocity, distance and pressure metrics.
// These functions are shared across the cat-functions and cat-viewer as
// we process the data in cloud functions for storing in the db, but
// also need to process the data "live" in the viewer to display the
// interactive demo with each build.

function processScrollDataWithTime(data) {
  // Array to hold the formatted {x, y} data points
  let chartData = [];
  let directionChangesCount = 0;
  let direction;
  data.forEach((point, index) => {
    // this is just a hack after changing data structure mid-hacking
    const timeKey = point.hasOwnProperty("t") ? "t" : "time";
    const directionKey = point.hasOwnProperty("d") ? "d" : "direction";
    if (index === 0) {
      // Start the chart at {x: 0, y: 0}
      direction = point[directionKey];
      chartData.push({ x: 0, y: 0, direction: direction });
    } else {
      if (point[directionKey] !== direction) {
        directionChangesCount++;
        direction = point[directionKey];
      }
      // Calculate the difference in 'y' and time elapsed since the first point
      const y = point.y - data[index - 1].y;
      const x = point[timeKey] - data[index - 1][timeKey];
      // Add the new data point
      chartData.push({
        y: Math.abs(y),
        x: Math.abs(x),
        direction: direction,
      });
    }
  });
  return chartData;
}

function getSingleSessionTotalScrollDistance(session) {
  return session.reduce((acc, curr) => acc + curr.y, 0);
}

function getAverageScrollDistance(scrolls) {
  return (
    scrolls.reduce((total, session) => {
      return total + getSingleSessionTotalScrollDistance(session);
    }, 0) / scrolls.length
  );
}

function calculateVelocity(deltaY, deltaX) {
  // Calculate velocity (pixels/ms) as deltaY / deltaX
  if (deltaX === 0) {
    // Prevent division by zero
    return 0;
  }
  return deltaY / deltaX;
}

// This function processes a single session of data and calculates the velocity for each point.
function processSingleSessionForVelocity(session) {
  if (!session.length) return []; // Handle empty arrays safely.

  // Set the velocity of the first point to 0 and use it as is.
  const processed = [{ ...session[0], velocity: 0 }];

  // Process remaining points in the session
  for (let i = 1; i < session.length; i++) {
    const { x, y } = session[i];
    const velocity = calculateVelocity(y, x);
    processed.push({ x, y, velocity });
  }

  return processed;
}

// This function maps each subArray through the processing function.
function addVelocity(data) {
  const result = data.map(processSingleSessionForVelocity);
  return result;
}

function combineVelocitySessions(data) {
  // Determine the maximum length of any session's data
  const maxLength = data.reduce(
    (max, session) => Math.max(max, session.length),
    0
  );

  // Initialize an array to store summed distances and times, and count of items for averaging
  const sums = Array.from({ length: maxLength }, () => ({
    totalTime: 0,
    totalDistance: 0,
    totalVelocity: 0,
    count: 0,
  }));

  // Sum up all distances and times for each index
  data.forEach((session) => {
    session.forEach((item, index) => {
      if (!item) {
        console.log("item is null", item);
        return;
      }
      sums[index].totalDistance += item.y;
      sums[index].totalTime += item.x;
      sums[index].totalVelocity += item.velocity;
      sums[index].count++;
    });
  });

  // Calculate averages for each index
  const averages = sums.map((item, _, array) => {
    if (!item.count) {
      return {
        averageDistance: 0,
        averageTime: 0,
        averageVelocity: 0,
      };
    }
    // NOTE: item.count may not be good to stop values in the "tail" of the data looking huge.
    return {
      averageDistance: item.totalDistance / item.count,
      averageTime: item.totalTime / item.count,
      averageVelocity: item.totalVelocity / item.count,
    };
  });

  // After calculating averages, accumulate totalTime
  let accumulatedTime = 0;
  averages.forEach((item) => {
    accumulatedTime += item.averageTime; // Accumulate totalTime
    item.accumulatedTime = accumulatedTime; // Assign accumulatedTime to each item
  });

  // Calculate rolling average of averageVelocity using up to the last 5 points
  for (let i = 0; i < averages.length; i++) {
    let sum = 0;
    let count = 0;
    // Determine the start index for the loop, ensuring it's within bounds
    let startIndex = Math.max(0, i - 2); // including the current index, this will sum the last 3 points
    // Loop from the start index to i, inclusive
    for (let j = startIndex; j <= i; j++) {
      sum += averages[j].averageVelocity;
      count++;
    }
    // Calculate and assign rolling average
    averages[i].rollingAverageVelocity = sum / count;
  }

  return averages;
}

function calculateRollingPressure(averages) {
  if (averages.length === 0) return [];
  const maxPressure = 2.5; // Maximum pressure value - this might need to be 2... to match the frontend
  const sensitivity = 1; // Sensitivity of pressure to changes in velocity
  const decayRate = 0.5; // Natural decay rate of pressure per iteration
  let pressures = [];
  averages.forEach((item, index) => {
    let increment;
    if (index === 0) {
      increment = item.rollingAverageVelocity * sensitivity;
    } else {
      const decayFactor = Math.exp(
        (-pressures[index - 1].pressure / maxPressure) * sensitivity
      );
      increment = decayFactor * item.rollingAverageVelocity * sensitivity;

      // Apply a decay to the current pressure
      pressures[index - 1].pressure *= 1 - decayRate;
    }

    // Calculate new pressure, ensure it doesn't exceed maxPressure or fall below zero
    const newPressure =
      pressures.length > 0
        ? Math.max(0, pressures[index - 1].pressure + increment)
        : increment;
    const pressure = Math.min(newPressure, maxPressure);
    pressures.push({
      accumulatedTime: item.accumulatedTime,
      pressure: pressure,
    });
  });
  // Apply a final entry to return things to 0
  pressures.push({
    accumulatedTime: pressures[pressures.length - 1].accumulatedTime + 200, // 200ms
    pressure: 0,
  });
  return pressures;
}

function getAveragePressure(pressureData) {
  const aggregatedPressure = pressureData.reduce(
    (acc, curr, idx, arr) => {
      // Skip the first and last entries as they are defaulted
      // to 0 pressure and should not be included in the average
      if (idx === 0 || idx === arr.length - 1) return acc;
      acc.totalPressure += curr.pressure;
      acc.count++;
      return acc;
    },
    { totalPressure: 0, count: 0 }
  );
  const averagePressure =
    aggregatedPressure.totalPressure / aggregatedPressure.count;
  return averagePressure;
}

/**
 * A helper to bundle the above functions into a single call
 * @param {*} scrolls
 * @returns
 */
function getSingleSessionPressures(scrolls) {
  const groupedByDirection = aggregateByDirection(scrolls);
  if (groupedByDirection.length <= 1) return [];
  const withVelocity = processSingleSessionForVelocity(groupedByDirection);
  const averageVelocityData = combineVelocitySessions([withVelocity]);
  const pressures = calculateRollingPressure(averageVelocityData);
  return pressures;
}

function aggregateByDirection(session) {
  return session.reduce((acc, curr) => {
    // if the current direction is the same as the previous direction, we need to
    // aggregate the x and y values into the previous object
    if (acc.length > 0 && acc[acc.length - 1].direction === curr.direction) {
      acc[acc.length - 1].x += curr.x;
      acc[acc.length - 1].y += curr.y;
    } else {
      acc.push({ ...curr });
    }
    return acc;
  }, []);
}

function prepareScrollSquiggleChartData(data) {
  if (data.length === 0) return [];
  // Preprocessing step to aggregate data by direction
  const aggregatedData = data.map((session) => {
    // each session has scrolls that may have sequences in the same direction, we
    // need to aggregate these sequences into one value/object in the array to
    // represent each direction movement
    return aggregateByDirection(session);
  });

  // Determine the maximum length of all sessions
  const maxLength = aggregatedData.reduce(
    (max, session) => Math.max(max, session.length),
    0
  );

  // Array to store sum of x and y, and count for averaging
  const values = Array.from({ length: maxLength }, () => ({
    count: 0,
    diffX: 0,
    diffY: 0,
  }));

  // Accumulate values for each index available in each session
  aggregatedData.forEach((session) => {
    session.forEach((point, index) => {
      const { x = 0, y = 0 } = point;
      values[index].diffX += x;
      values[index].diffY += y;
      values[index].count++;
    });
  });

  const result = values.reduce(
    (acc, { diffX, diffY, count }, i) => {
      // Calculate the current x increment and y position
      // NOTE: Adjusting to use the y value as the increment as we want the live squiggle to
      // be passing points on the y axis that represent the distance
      const xIncrement = 50; // Math.round(Math.abs(diffY / count)); //  Math.round(diffX / count);
      const yPosition = Math.round(((i % 2 === 0 ? -1 : 1) * diffY) / count);

      // Start of the current beat is the accumulated x from the previous beat
      const startX = Math.round(acc.accumulatedX);

      // Calculate the end x position of the current beat
      const endX = Math.round(startX + xIncrement);

      // Create the beat with start, peak, and end points
      const beat = [
        // { x: startX, y: 0 }, // Start of the beat
        { x: startX + xIncrement / 2, y: yPosition }, // Peak of the beat
        { x: endX, y: 0 }, // End of the beat, ensuring continuity with the next beat
      ];

      // Update the accumulated x for the next beat
      acc.accumulatedX = endX;

      // Append the current beat to the result
      acc.result = [...acc.result, ...beat];

      return acc;
    },
    { accumulatedX: 0, result: [{ x: 0, y: 0 }] } // start everything at 0,0
  ).result;
  return result;
}

module.exports = {
  getAverageScrollDistance,
  processScrollDataWithTime,
  getSingleSessionTotalScrollDistance,
  addVelocity,
  processSingleSessionForVelocity,
  combineVelocitySessions,
  calculateRollingPressure,
  getAveragePressure,
  getSingleSessionPressures,
  prepareScrollSquiggleChartData,
  aggregateByDirection,
};
