import styled from "@emotion/styled";
import React, { forwardRef } from "react";
interface HeadingProps extends React.HTMLProps<HTMLParagraphElement> {
  type?: "heading1" | "heading2";
}

const StyledText = styled.h1<{ kind: HeadingProps["type"] }>`
  font-style: normal;
  font-size: var(--text-${({ kind }) => kind}-fontSize);
  font-weight: var(--text-${({ kind }) => kind}-fontWeight);
  line-height: var(--text-${({ kind }) => kind}-lineHeight);
  letter-spacing: var(--text-${({ kind }) => kind}-letterSpacing);
  color: var(--colors-text);

  // Link compoents inside a heading should have a thicker border
  & a span{
    border-width: 4px;
  }
`;

export const Heading = forwardRef<HTMLParagraphElement, HeadingProps>(
  ({ type = "heading2", children }: HeadingProps, ref = null) => {
    return (
      <StyledText as={type === "heading1" ? "h1" : "h2"} ref={ref} kind={type}>
        {children}
      </StyledText>
    );
  }
);
