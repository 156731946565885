import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { bounceDown, bounceUp } from "../animations/animations";
import { IconButton } from "../forms/IconButton";
import { Text } from "../typography/Text";

const bounceUpFrames = keyframes`${bounceUp()}`;
const bounceDownFrames = keyframes`${bounceDown()}`;

const SnackBarRoot = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  bottom: var(--space-4);
  left: 50%;
  margin-left: max(-400px, -35vw);

  width: 70vw;
  max-width: 800px;
  padding: var(--space-3) var(--space-3) var(--space-3) var(--space-4);
  gap: var(--space-3);
  z-index: 101;

  animation: ${bounceUpFrames} 0.8s cubic-bezier(0.68, -0.6, 0.32, 1.6) forwards;
  animation-delay: 0.7s;
  opacity: 0;
  transform: translateY(140%);

  background-color: ${({ backgroundColor }) =>
    backgroundColor || "var(--colors-trafficlight-2)"};
  font-family: Roboto;

  &.bye {
    animation: ${bounceDownFrames} 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6)
      forwards;
  }
`;
const SnackBarButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

interface SnackBarProps {
  title: string;
  description?: string;
  icon?: React.ReactElement;
  delayToClose?: number;
  status?: "success" | "error" | "info" | "warning";
}

export const SnackBar = ({
  title,
  description,
  status,
  delayToClose,
}: SnackBarProps) => {
  const [show, setShow] = useState(true);
  const [animateOut, setAnimateOut] = useState(false);

  const backgroundColor = {
    success: "var(--snackBar-success)",
    error: "var(--snackBar-error)",
    info: "var(--snackBar--info)",
    warning: "var(--snackBar-warning)",
  }[status];

  const close = () => {
    setAnimateOut(true);
    setTimeout(() => {
      setShow(false);
      setAnimateOut(false);
    }, 700);
  };

  if (delayToClose) {
    setTimeout(() => {
      close();
    }, delayToClose);
  }

  return (
    show && (
      <SnackBarRoot
        className={animateOut && "bye"}
        backgroundColor={backgroundColor}
      >
        <div>
          <b>
            <Text type="body01" as="span">
              {title}
            </Text>
          </b>
          {description && <Text type="body01">{description}</Text>}
        </div>

        <IconButton onClick={close} />
      </SnackBarRoot>
    )
  );
};
