import React, { forwardRef } from "react";
import CATRouselCard from "../../components/CATRouselCard/CATRouselCard";
import styles from "./CATRouselViewer.module.scss";

export const TopProducts = forwardRef(({ recommendations }, ref) => {
  return (
    <div className={styles.cardsContainer} ref={ref}>
      {recommendations.map((recommendation, index) => {
        return (
          <CATRouselCard
            image={recommendation.url}
            title={recommendation.id}
            key={index}
            ranking={index + 1}
          />
        );
      })}
    </div>
  );
});
