import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import App from "/src/App";

root.render(<App />);
serviceWorker.unregister();
