import { useState } from "react";
import { ReorderList } from "./ReorderList";
import {
  Flex,
  Text,
  Box,
  VStack,
  Grid,
  GridItem,
  Center,
} from "@chakra-ui/react";
import { DcoItem } from "./DcoItem";
import { MenuItem } from "./MenuItem";
import { Card } from "./Card";
import { BiListCheck, BiTargetLock } from "react-icons/bi";
import { DcoResults } from "./DcoResults";
import { DcoHeader } from "./DcoHeader";
import { motion } from "framer-motion";

export const DcoContainer = ({ buildID, dcoData }) => {
  const {
    dcoMetaData,
    dcoHistoryData,
    historyByVariation,
    overallScrubPerformance,
  } = dcoData;

  const optimisationIndex = dcoMetaData.optimisationTimeline.findIndex(
    (item) => item.optimisationUsed === false
  );
  const optimisationProgress =
    optimisationIndex === -1
      ? 100
      : (optimisationIndex / dcoMetaData.optimisationTimeline.length) * 100;
  const active = optimisationProgress > 0;
  const [index, setIndex] = useState(dcoHistoryData.length - 1);
  const [inViewItem, setInViewItem] = useState(null);

  return (
    <Center w="100%" h="100%">
      <Grid
        backgroundColor={"gray.50"}
        bg="gray.50"
        position="absolute"
        top="50px"
        width="100%"
        templateAreas={`"header header"
                  "nav main"`}
        gridTemplateRows={"50px 1fr"}
        gridTemplateColumns={"250px 1fr"}
        h="100%"
        maxW={"1410px"}
        color="blackAlpha.700"
        fontWeight="bold"
      >
        {/* Header */}
        <GridItem
          width="100%"
          height="50px"
          zIndex="201"
          position="fixed"
          bg="gray.50"
          area={"header"}
        >
          <motion.div
            style={{ height: "100%" }}
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ duration: 1 }}
          >
            <DcoHeader
              dcoMeta={dcoMetaData}
              optimisationProgress={optimisationProgress}
            />
          </motion.div>
        </GridItem>
        {/* Side Nav */}
        <GridItem
          position="fixed"
          top="100px"
          zIndex="200"
          bg="gray.50"
          area={"nav"}
        >
          <motion.div
            as={motion.div}
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            transition={{ duration: 1 }}
          >
            <VStack
              direction={"column"}
              bg="white"
              borderRight="solid 1px #eee"
              padding="10px 20px 10px 10px"
              width="250px"
              height="100vh"
              zIndex="200"
              spacing="5px"
              alignItems="flex-start"
            >
              <MenuItem
                text="Catalyst Results"
                id="results"
                selected={inViewItem === "results"}
                icon={BiListCheck}
              />
              <Text fontSize="sm" marginLeft="5px" color="gray.500">
                Ad Variations
              </Text>
              <ReorderList
                inViewItem={inViewItem}
                list={dcoHistoryData}
                index={index}
                byVariation={historyByVariation}
              />
            </VStack>
          </motion.div>
        </GridItem>
        {/* Main Content */}
        <GridItem
          bg="gray.50"
          area={"main"}
          boxShadow={"1000px 0px 10px 0 var(--chakra-colors-gray-50)"}
          pb="20px"
          // hack for the bg color to extend to the right of the page because of the absolute positioning of stuff makes it difficult
        >
          <VStack spacing="1" alignItems={"flex-start"} padding="0 20px">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <DcoResults
                history={dcoHistoryData}
                optimisationPerformance={overallScrubPerformance}
                setInViewItem={setInViewItem}
                setIndex={setIndex}
                index={index}
                buildID={buildID}
                byVariation={historyByVariation}
                active={active}
              />
            </motion.div>
            <Card
              icon={BiTargetLock}
              heading="See how our attention tracking tech works -&nbsp;"
              link={{ text: "Try an interactive demo 📲", path: "/demo" }}
              setInViewItem={() => {
                setInViewItem("demo");
              }}
            ></Card>

            <Flex w="100%" pt="3" pb="0">
              <Text fontSize="lg">Variations</Text>
            </Flex>
            {historyByVariation.map((item) => {
              return (
                <Card
                  id={item.id}
                  key={item.id}
                  height="490px"
                  setInViewItem={() => {
                    setInViewItem(item.id);
                  }}
                >
                  <DcoItem
                    parentBuildID={buildID}
                    history={item.history}
                    variationId={item.id}
                    active={active}
                  />
                </Card>
              );
            })}
          </VStack>
        </GridItem>
      </Grid>
    </Center>
  );
};
