import { IconButton, Icon, Tooltip } from "@chakra-ui/react";
import { BiSolidStar } from "react-icons/bi";
import { useState, useEffect } from "react";
import {
  useCountsByCreative,
  useCountsByUserId,
} from "../../../hooks/useCounts";
const Favourite = ({ buildID, userID }) => {
  const [isFavourite, setIsFavourite] = useState(false);
  const { counts: byCreative, refetchCounts } = useCountsByCreative(buildID);
  const { favourites: favouritesCount } = byCreative;
  const { counts, loading, addFavourite, removeFavourite } =
    useCountsByUserId(userID);
  const { favourites } = counts;

  useEffect(() => {
    if (favourites && favourites.find((fav) => fav.id === buildID)) {
      setIsFavourite(true);
    } else {
      setIsFavourite(false);
    }
  }, [favourites]);

  const handleClicked = async () => {
    if (isFavourite) {
      await removeFavourite(buildID);
    } else {
      await addFavourite(buildID);
    }
    setIsFavourite(!isFavourite);
    refetchCounts();
  };

  return (
    <Tooltip
      label={`Favourited ${favouritesCount} ${
        favouritesCount === 1 ? "time" : "times"
      }`}
      aria-label="favourite count"
    >
      <IconButton
        disabled={loading}
        aria-label="Favourite"
        size="sm"
        icon={
          isFavourite ? (
            <Icon as={BiSolidStar} color="yellow.400" />
          ) : (
            <Icon as={BiSolidStar} color="gray.500" />
          )
        }
        onClick={handleClicked}
      />
    </Tooltip>
  );
};

export default Favourite;
