import { Button, Flex } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import Toggle from '../../components/UI/Toggle/Toggle';
import { HomepageStore } from '../../store/homepage';
import { SESSION_SIZE_WARNING_BENCHMARK } from './consts';

export const HomeFilters = () => {
  const filters = HomepageStore((state) => state.filters);
  const handleFilterChange = HomepageStore((state) => state.changeFilters);
  const clearFilters = HomepageStore((state) => state.clearFilters);
  const options = HomepageStore((state) => state.options);

  const hasOptions = Object.values(options).every(
    (option) => option.length > 0
  );
  const hasFilters = Object.values(filters).some((filter) => filter !== '');
  const filteringSessionSize =
    filters.sessionSize === SESSION_SIZE_WARNING_BENCHMARK;

  return (
    <Flex
      minHeight={'68px'}
      w="100%"
      background="red"
      maxW="1500px"
      justifyContent="flex-start"
    >
      <Flex
        py="4"
        w="100%"
        maxW="1500px"
        justifyContent="flex-start"
        gap="4"
        flexWrap="wrap"
        zIndex="15"
        background="white"
        position="fixed"
        filter="drop-shadow(0px 4px 4px white)"
      >
        {/* BRANDS -------------------------------*/}
        <Select
          {...sharedDropdownProps}
          value={filters.brand}
          isLoading={!hasOptions}
          isClearable={true}
          name="Brands"
          placeholder="Brands"
          options={options.brands}
          onChange={(selectedOptions) =>
            handleFilterChange(selectedOptions, 'brand')
          }
        />
        {/* VERTICALS -------------------------------*/}
        <Select
          {...sharedDropdownProps}
          value={filters.vertical}
          name="Verticals"
          placeholder="Verticals"
          options={options.verticals}
          isLoading={!hasOptions}
          onChange={(selectedOptions) =>
            handleFilterChange(selectedOptions, 'vertical')
          }
        />
        {/* FORMATS -------------------------------*/}
        <Select
          {...sharedDropdownProps}
          value={filters.format}
          name="Units"
          placeholder="Units"
          options={options.formats}
          isLoading={!hasOptions}
          onChange={(selectedOptions) =>
            handleFilterChange(selectedOptions, 'format')
          }
        />
        {/* DISPLAY AND VIDEO -------------------------------*/}
        <Select
          {...{...sharedDropdownProps, isMulti: false }}
          value={filters.features}
          name="Video"
          placeholder="Display and Video"
          options={[{ value: '', label: 'Display and Video' }].concat(
            options.features
          )}
          isLoading={!hasOptions}
          onChange={(selectedOptions) => {
            // as this is a single select dropdown, we need to pass an array
            const opt = selectedOptions.value === '' ? [] : [selectedOptions];
            handleFilterChange(opt, 'features');
          }}
        />
        {/* SESSION SIZE -------------------------------*/}
        {/* hide low session size button here */}

        <Button
          onClick={clearFilters}
          isDisabled={!hasFilters}
          size="sm"
          fontWeight="normal"
          variant="outline"
          h="auto"
        >
          Clear
        </Button>
        <Toggle
          tooltipMessage={`Hide creatives that have registered less than ${SESSION_SIZE_WARNING_BENCHMARK} sessions`}
          label={'Hide low sessions'}
          colorScheme={filteringSessionSize ? 'red' : 'gray'}
          value={filteringSessionSize}
          onToggle={(state) => {
            if (state) {
              handleFilterChange(SESSION_SIZE_WARNING_BENCHMARK, 'sessionSize');
            } else {
              handleFilterChange(0, 'sessionSize');
            }
          }}
        />
      </Flex>
    </Flex>
  );
};

const sharedDropdownProps = {
  isMulti: true,
  isSearchable: true,
  useBasicStyles: true,
  chakraStyles: {
    control: (provided: any) => ({
      ...provided,
      border: '1px solid',
      borderColor: 'gray.200',
      borderRadius: 'md',
      minWidth: '200px',
      minHeight: '36px',
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: 'md',
      border: '1px solid',
      borderColor: 'gray.200',
    }),
  },
};
