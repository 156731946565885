const getAdHeight = (e) => {
  if (
    e.target.contentWindow !== null &&
    e.target.contentWindow.document.getElementById("middle-ad") !== null
  ) {
    const seamlessFrame = e.target.contentWindow.document
      .getElementById("middle-ad")
      .getElementsByTagName("iframe")[0];
    seamlessFrame.addEventListener(
      "onload",
      () => {
        console.log("iframe loaded!");
      },
      true
    );
    const format = formatSwitch(
      seamlessFrame.contentWindow.xyzContext.dimension.creative.format
    );

    return seamlessFrame.contentWindow[format] &&
      seamlessFrame.contentWindow[format].$markerElement &&
      seamlessFrame.contentWindow[format].$markerElement.length
      ? seamlessFrame.contentWindow[
          format
        ].$markerElement[0].getBoundingClientRect().height
      : 0;
  } else {
    console.error("NO AD HEIGHT DETECTED");
    return 0;
  }
};

const formatSwitch = (format) => {
  switch (format) {
    case "hang-time-jnr":
      return "hangTimeJnr";
    case "hang-time":
      return "hangTime";
    case "hitchhike":
      return "hitchhike";
    case "hover-board":
      return "hoverBoard";
    case "video-switch":
      return "videoSwitch";
    default:
      console.error("Format not found");
      return;
  }
};

const isVideoPlaying = (video) => {
  if (!video) return false;
  return !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > 2
  );
};

const checkForElement = (window, target) => {
  return new Promise((resolve) => {
    if (
      !window.document.querySelector(target) ||
      window.document.querySelector(target).length === 0
    ) {
      setTimeout(() => {
        // we're checking if the target has loaded
        // and not progressing until it has
        checkForElement(window, target).then(() => resolve());
      }, 50);
    } else {
      console.log(`${target} is there!`);
      resolve();
    }
  });
};

const capitalize = (string, delimiter) => {
  let words = string
    .split(delimiter)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return words.join(" ");
};

const generatePreviewerURL = (buildID, format = "hang-time") => {
  const urlStart = `https://studio.playgroundxyz.com/${buildID}/`;
  const urlEnd = `quiet?pos=middle-ad&size=300x250&show-engagements=true`;
  return urlStart + urlEnd;
};

const enlargePreviewSizeToggles = (iframe) => {
  try {
    const sizeToggler = iframe.document.body.querySelector(".size-toggler");
    console.log("find me", sizeToggler);
    sizeToggler.style.right = "70px";
    sizeToggler.style.bottom = "40px";
    sizeToggler.style.transform = "scale(1.6)";
  } catch (e) {
    console.log("couldn't enlarge size toggler ", e);
  }
};

export {
  getAdHeight,
  formatSwitch,
  isVideoPlaying,
  checkForElement,
  capitalize,
  generatePreviewerURL,
  enlargePreviewSizeToggles,
};
