import { DCOA } from "@controllers/DCOA";
import firebase from "@services/firestore";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styles from "./App.module.scss";
import LoadingPage from "./components/LoadingPage/LoadingPage";
import Modal from "./components/UI/Modal/Modal";
import DontCry from "./composites/DontCry/DontCry";
import LoginScreen from "./composites/LoginScreen/LoginScreen";
import { ABViewer } from "./controllers/AB/ABViewer";
import CATRouselSelector from "./controllers/CATRouselSelector/CATRouselSelector";
import CATRouselViewerWithRouter from "./controllers/CATRouselViewer/CATRouselViewer";
import CreativeViewerWithRouter from "./controllers/CreativeViewer/CreativeViewer";
// import DataViewer from "./controllers/DataViewer/DataViewer";
import { DCO } from "@controllers/DCO";
import { UIStore } from "./store/UIStore";
import { sendUserToAnalytics, useStartAnalytics } from "./util/analyticsUtil";
import { ChakraProvider } from "@chakra-ui/react";
import Header from "./composites/Header/Header";
import { VelocityPrototype } from "@controllers/VelocityPrototype";
import { gumGumChakraTheme } from "../theme";
import { Home } from "@controllers/Home";
import { Globals } from "@ui-lib/index";

const PrivateRoute = (props) => {
  const { loggedIn, displayName, email } = UIStore((state) => state.userInfo);
  const overrideProps =
    loggedIn === null
      ? {
          component: null,
          children: <LoadingPage />,
        }
      : displayName == "" || email === ""
      ? {
          component: null,
          children: <LoginScreen />,
        }
      : {};

  return <Route {...props} {...overrideProps} />;
};

const App = () => {
  const userInfo = UIStore((state) => state.userInfo);
  const viewerScale = UIStore((state) => state.viewerScale);
  const setUserInfo = UIStore((state) => state.setUserInfo);
  const showModal = UIStore((state) => state.showModal);
  const setShowModal = UIStore((state) => state.setShowModal);
  const getCreativeBlacklist = UIStore((state) => state.getCreativeBlacklist);

  useStartAnalytics();
  useEffect(() => {
    getCreativeBlacklist();
  }, [getCreativeBlacklist]);

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          if (!/gumgum.com|playgroundxyz.com/.test(user.email)) {
            firebase.auth().signOut();
          }

          setUserInfo({
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL ? user.photoURL : "",
            loggedIn: true,
            id: user.uid,
          });
          sendUserToAnalytics(user);
        } else {
          setUserInfo({
            displayName: "",
            email: "",
            photoURL: "",
            loggedIn: false,
            id: "",
          });
        }
      });
    return () => {
      unregisterAuthObserver();
    };
  }, [setUserInfo]);

  return (
    <ChakraProvider theme={gumGumChakraTheme}>
      <div className={styles.app}>
        <Globals />
        {userInfo && (
          <Router>
            <Header
              loggedIn={userInfo.displayName !== "" && userInfo.email !== ""}
            />
            <Switch>
              <PrivateRoute exact path="/">
                <Home />
              </PrivateRoute>
              <Route exact path="/creative/:creativeID">
                <CreativeViewerWithRouter
                  scale={viewerScale}
                  header={userInfo.displayName !== "" && userInfo.email !== ""}
                />
              </Route>
              <Route exact path="/demo/">
                <CreativeViewerWithRouter
                  scale={viewerScale}
                  demoMode={true}
                  header={true}
                  userEmail={userInfo.email}
                />
              </Route>
              <PrivateRoute exact path="/demo/:creativeID">
                <CreativeViewerWithRouter
                  scale={viewerScale}
                  demoMode={true}
                  header={true}
                />
              </PrivateRoute>
              <Route
                exact
                path="/ab/:creativeID/:creativeID2"
                render={(props) => (
                  <ABViewer {...props} loggedIn={userInfo.loggedIn} />
                )}
              />
              <Route exact path="/catalyst/:buildID">
                <DCO
                  header={userInfo.displayName !== "" && userInfo.email !== ""}
                />
              </Route>
              <Route exact path="/vel/:buildID">
                <VelocityPrototype
                  header={userInfo.displayName !== "" && userInfo.email !== ""}
                />
              </Route>
              <PrivateRoute exact path="/catrousel">
                <CATRouselSelector />
              </PrivateRoute>
              <Route exact path="/catrousel/:buildID">
                <CATRouselViewerWithRouter
                  header={userInfo.displayName !== "" && userInfo.email !== ""}
                />
              </Route>
              <Route exact path="/dcoa/:buildID">
                <DCOA
                  header={userInfo.displayName !== "" && userInfo.email !== ""}
                />
              </Route>
              <Route exact path="/loading">
                <LoadingPage />
              </Route>
              <Route component={DontCry} />
            </Switch>
          </Router>
        )}
        <Modal show={showModal.open} onClose={() => setShowModal(false, "")}>
          {showModal.text}
        </Modal>
      </div>
    </ChakraProvider>
  );
};

export default App;
