import { extendTheme } from '@chakra-ui/react';
import { components } from './componentOverrides';
import { colors } from './colors';
import { styles } from './globalStyles';

export const gumGumChakraTheme = extendTheme({
  // ----------------------------------
  // 🎨 COLORS
  colors,

  // ----------------------------------
  // Conditional tokens - mostly used for color switching
  // https://chakra-ui.com/docs/styled-system/semantic-tokens
  // copied from https://github.com/chakra-ui/chakra-ui/blob/main/website/theme.ts
  semanticTokens: {
    colors: {
      // --------------------------------------------------
      // NEW
      // Text default tokens that change with color mode
      'text-default': { default: 'gray.600', _dark: 'gray.300' },
      'text-default-muted': { default: 'gray.500', _dark: 'gray.400' },
      'text-default-subtle': { default: 'gray.400', _dark: 'gray.500' },
      'text-default-placeholder': { default: 'gray.400', _dark: 'gray.500' },
      'text-default-accent': { default: 'green.500', _dark: 'green.300' },
      'text-strong': { default: 'gray.800', _dark: 'gray.100' },

      // BG default tokens that change with color mode
      'bg-default': { default: 'white', _dark: 'gray.900' },
      'bg-default-lv2': { default: 'gray.50', _dark: 'gray.850' },
      'bg-default-lv3': { default: 'gray.100', _dark: 'gray.800' },
      'bg-default-lv4': { default: 'gray.200', _dark: 'gray.700' },

      // Border default tokens that change with color mode
      'border-default': { default: 'gray.400', _dark: 'gray.600' },
      'border-default-muted': { default: 'gray.200', _dark: 'gray.700' },
      'border-default-subtle': { default: 'gray.100', _dark: 'gray.800' },
      'border-default-focus': { default: 'green.500', _dark: 'green.300' },
    },

    // --------------------------------------------------
    // OVERWRITING CHAKRA'S DEFAULTS

    // 'ring-color': { default: 'var(--chakra-colors-green-500)', _dark: 'var(--chakra-colors-green-300)' },
    // come back to this later, not sure why not working
  },

  // ----------------------------------
  // 🔠 FONTS
  fonts: {
    heading: `'Mulish', sans-serif`,
    body: `'Mulish', sans-serif`,
  },

  // ----------------------------------
  // 📐 ADDING VARIATIONS TO CHAKRA COMPONENTS
  components,

  // ----------------------------------
  //  MISC GLOBAL CSS STYLES
  styles,
});
