import { Card, Center, Spinner, VStack } from "@chakra-ui/react";
import React from "react";

export const GGCard = ({
  key,
  onClick,
  children = null,
  thumbnailComponent = <Spinner />,
  topRightIcon = null,
}) => {
  return (
    <Card
      cursor={onClick ? "pointer" : "default"}
      onClick={onClick}
      key={key}
      w="160px"
      h="220px"
      {...cardStaticStyle}
    >
      {/* Top right icon --------------------------------------------- */}
      {topRightIcon}

      {/* Thumbnail --------------------------------------------- */}
      <Center
        borderRadius="8px 8px 0 0"
        transition="all 0.2s ease"
        bg="bg-default-lv3"
        h="100%"
        maxH="70%"
        overflow="hidden"
        px="3"
        py="3"
        _groupHover={{
          px: "2.5",
          pt: "3.5",
        }}
      >
        {thumbnailComponent}
      </Center>
      {/* Details --------------------------------------------- */}
      <VStack px="1" py="1.5" role="group" w="100%">
        {children}
      </VStack>
    </Card>
  );
};

const cardStaticStyle = {
  boxShadow: "none",
  borderWidth: "1px",
  borderColor: "border-default-muted",
  backgroundColor: "bg-default",
  borderRadius: "8px",
  role: "group",
  _hover: {
    borderColor: "border-default-focus",
  },
};
