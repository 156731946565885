import React from "react";
import styles from "./LoadingBar.module.scss";

const LoadingBar = () => {
  return (
    <div className={styles.loading}>
      <div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingBar;
