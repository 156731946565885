/**
 * Takes in the captured video sequence data and fills in
 * the gaps left by data not capturing at a fast enough
 * speed to fill the 0-100 progress array of a video
 * @param {array} sequence
 * @returns an array of length sequence.length
 */
const normalizeVideoSequence = (sequence) => {
  if (sequence[0] === null) sequence[0] = 50;
  for (let i = 0; i < sequence.length; i++) {
    let percentInView = sequence[i];
    if (percentInView === null) {
      let j = 1;
      let nextValue = sequence[i + j];
      while (nextValue === null) {
        j++;
        if (i + j > 100) {
          return sequence.map((value) => (value === null ? 0 : value));
        }
        nextValue = sequence[i + j];
      }
      // when we hit the next number, we work back to fill in the gaps
      // get the difference between the last and next nonZero
      let differenceToFill = sequence[i + j] - sequence[i - 1];
      let increments = differenceToFill / (j + 1);
      for (let k = 0; k < j; k++) {
        sequence[i + k] = Math.round(sequence[i - 1] + increments * (k + 1));
      }
    }
  }
  return sequence;
};

exports.normalizeVideoSequence = normalizeVideoSequence;

export const normalizeBaselineAndActual = (
  incomingData,
  startProgress,
  endProgress
) => {
  if (!incomingData.length) {
    return [];
  }
  const dataClone = [...incomingData].slice(startProgress, endProgress);
  const sequenceMax = dataClone.reduce((prev, current) =>
    prev.playfulness > current.playfulness ? prev : current
  ).playfulness;

  const sequenceMin = dataClone.reduce(function (prev, current) {
    return prev.playfulness < current.playfulness ? prev : current;
  }).playfulness;

  const baselineMax = dataClone.reduce((prev, current) =>
    prev.scrubBaseline > current.scrubBaseline ? prev : current
  ).scrubBaseline;
  const baselineMin = dataClone.reduce(function (prev, current) {
    return prev.scrubBaseline < current.scrubBaseline ? prev : current;
  }).scrubBaseline;

  const max = Math.max(sequenceMax, baselineMax);
  const min = Math.min(sequenceMin, baselineMin);
  const gap = max - min;

  return dataClone.map((value) => {
    const newValue = { ...value };
    if (value.playfulness >= min && value.playfulness <= max) {
      newValue.playfulness =
        (value.playfulness - min) * (incomingData.length / gap);
    } else if (value.playfulness > max) {
      newValue.playfulness = 100;
    } else {
      newValue.playfulness = 0;
    }

    if (value.scrubBaseline >= min && value.scrubBaseline <= max) {
      newValue.scrubBaseline =
        (value.scrubBaseline - min) * (incomingData.length / gap);
    } else if (value.scrubBaseline > max) {
      newValue.scrubBaseline = 100;
    } else {
      newValue.scrubBaseline = 0;
    }
    return newValue;
  });
};
