@import "colors.scss";

.cardContainer {
  width: calc(25% - 20px);
  overflow: hidden;
  background-color: $white;
  display: inline-block;
  margin: 10px;
  padding: 80px 20px 40px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: $shadow50med;
  transition: all 0.2s ease-in-out;
  position: relative;
  &.firstCard {
    // width: calc(50% - 20px);
    .ranking {
      font-size: 24px;
      height: 100px;
      width: 100px;
      background: $darkpinkgradient;
    }
  }
  .cardImage {
    width: 100%;
    height: 400px;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }
  .cardTitle {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: $grey;
    color: $white;
    font-size: 16px;
    padding: 20px 10px;
    text-align: center;
    box-sizing: border-box;
  }
  .ranking {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: $lightgrey;
    box-shadow: $shadow50med;
    color: $white;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    box-sizing: border-box;
  }
}

@media (max-width: 630px) {
  .cardContainer {
    margin: 10px 10%;
    width: 80%;
    &.firstCard {
      width: 80%;
    }
  }
}
