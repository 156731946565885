import { Flex, Image, Tag } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import create from "zustand";
import { UIStore } from "../../store/UIStore";

// using zustand to create a store for the aspect ratio of the video thumbnails
// as the video is not loaded again we need to store the aspect ratio of the video
const useVideoLocal = create((set) => ({
  aspectRatio: 6.734177215189874,
  setAspectRatio: (aspectRatio) => set({ aspectRatio }),
}));

export const VideoFrames = ({ videoUrl, videoDuration }) => {
  const videoRef = useRef();
  const canvasRef = useRef();
  const framesRef = useRef(videoDuration ? new Array(videoDuration) : []); // TODO - this is a hack to get around the fact that videoDuration is not available on first render FIXME
  const videoFrames = UIStore((state) => state.videoFrames);
  const setVideoFrames = UIStore((state) => state.setVideoFrames);
  // used to set the aspect ratio of container to match the video thumbnails aspect ratio
  const aspectRatio = useVideoLocal((state) => state.aspectRatio);
  const setAspectRatio = useVideoLocal((state) => state.setAspectRatio);

  useEffect(() => {
    if (videoFrames[videoUrl]) return;
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    video.crossOrigin = "anonymous";

    video.addEventListener("loadedmetadata", () => {
      // Set canvas dimensions equal to video dimensions
      // 1 thumb per seconds
      const thumbsTotal = Math.floor(video.duration);

      canvas.width = video.videoWidth / 6;
      canvas.height = video.videoHeight / 6;

      // Set the aspect ratio of the canvas to match the video thumbnails
      // also set height to 2x as the hover effect will double the size
      const thumbAreaRatio = (canvas.width * thumbsTotal) / (canvas.height * 2);
      if (aspectRatio !== thumbAreaRatio) {
        setAspectRatio(thumbAreaRatio);
      }

      let i = 0;

      video.addEventListener("seeked", async () => {
        // Draw the current frame of the video onto the canvas
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas image to a data URL and store it
        framesRef.current[i] = canvas.toDataURL();

        i++;

        if (i < Math.floor(video.duration)) {
          // Seek to the next second of the video
          video.currentTime = i;
          setVideoFrames({ [videoUrl]: framesRef.current });
        }
      });

      // Start the frame extraction process
      video.currentTime = i;
    });
  }, [videoUrl]);

  // -----------------------------------------------------
  // should be in presentation mode?
  const queryParams = new URLSearchParams(location.search);
  const isPresentationMode = queryParams.get("layoutMode") === "presentation";
  // this is the URL param that is passed thorugh when gif-generator
  // is viewing the page to create a gif for the slide deck

  return (
    <Flex id="video-frames" position={"relative"} aspectRatio={aspectRatio}>
      <video ref={videoRef} src={videoUrl} style={{ display: "none" }} />
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <Flex
        pos={"absolute"}
        width={"100%"}
        bg="gray.100"
        borderRadius="0 0 5px 5px"
        maxH={"130px"}
      >
        {/* <Flex position={"relative"}>
      <video ref={videoRef} src={videoUrl} style={{ display: "none" }} />
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <Flex
        pos={"absolute"}
        bg="gray.100"
        borderRadius="0 0 5px 5px"
        width={"96%"}
        top="-50px" //-90px"
        marginLeft={"50px"}
        maxH={"130px"}
      > */}

        {videoFrames[videoUrl] &&
          videoFrames[videoUrl].map((frameUrl, index) => {
            return (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.8 }}
                key={`frame-${index + 1}`}
                whileHover={{
                  scale: 1.4,
                  zIndex: 2,
                  opacity: 1,
                  duration: 0.5,
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  transformOrigin: "top center",
                  position: "relative",
                  width: `${100 / videoDuration}%`,
                }}
              >
                <Tag
                  fontSize={isPresentationMode ? "24px" : "sm"}
                  colorScheme="gray"
                  px={isPresentationMode ? "1.5" : "1"}
                  position={"absolute"}
                  top="0"
                  zIndex="1"
                >
                  {index}s
                </Tag>
                <Image
                  borderLeft={"2px solid black"}
                  height="100%"
                  src={frameUrl}
                  key={index}
                  alt={`Frame ${index + 1}`}
                  borderRadius={"sm"}
                  boxShadow={"sm"}
                  placeholder={"blur"}
                />
              </motion.div>
            );
          })}
      </Flex>
    </Flex>
  );
};
