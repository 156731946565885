import firebase from '@services/firestore';
import { useEffect, useState } from 'react';
import {
  processScrollDataWithTime,
  getAverageScrollDistance,
  addVelocity,
  combineVelocitySessions,
  calculateRollingPressure,
  getAveragePressure,
  prepareScrollSquiggleChartData,
  aggregateByDirection,
  getSingleSessionPressures,
} from '../../../cat-functions/velocity';
import { aggregateScrollSquiggle } from '../../../cat-functions/modules/Velocity/utils';
import { averageArrayOfInts } from '@utils/velocity';

/**
 * This hook is used to fetch raw scrolls data for a given buildID.
 * The data is retrieved from the Firestore collection:
 * '/Velocity/beta/v1/{buildId}/entries'
 */
export const useVelocityTestData = (id) => {
  const [fetchedIds, setFetchedIds] = useState({});

  useEffect(() => {
    if (id && !fetchedIds[id]) {
      getData(id).then((data) => {
        if (!data) return;
        const {
          totalSessions,
          avgScrollDistance,
          avgPressure,
          avgScrollSquiggle,
          avgPressureSequence,
          scrollSquiggleBenchmark,
        } = data;
        setFetchedIds((prev) => ({
          ...prev,
          [id]: {
            totalSessions,
            scrollSquiggleBenchmark,
            scrollSquiggleWithBenchmarkLength: getScrollLength(
              scrollSquiggleBenchmark
            ),
            scrollSquiggleLength: getScrollLength(avgScrollSquiggle),
            sessionPressure: avgPressureSequence,
            averagePressure: avgPressure,
            avgScrollDistance,
            scrollSquiggle: avgScrollSquiggle,
          },
        }));
      });
      return;
    }
  }, [id, fetchedIds]);

  return fetchedIds[id];
};

async function getData(id) {
  const dataRef = firebase
    .firestore()
    .collection('Velocity')
    .doc('beta')
    .collection('v1')
    .doc(id);

  const buildDataSnap = await dataRef.get();
  if (!buildDataSnap.exists) {
    console.error('No data found for build', id);
    return null;
  }
  const buildData = buildDataSnap.data();

  if (!buildData.avgScrollSquiggle) {
    console.error('No averageScrollSquiggle data found for build', id);
    return null;
  }

  // fetch benchmark data
  const benchmarkRef = firebase.firestore().collection('Velocity').doc('beta');

  const benchmarkDataSnap = await benchmarkRef.get();
  const benchmarkData = benchmarkDataSnap.data();

  return { ...buildData, ...benchmarkData };
}

// Can be used in debugging and testing to access the raw sessions
// of a build rather than the aggregated data. Swap out for getData above!
async function getDataAsRawSessions(id, limit = 100) {
  const dataRef = firebase
    .firestore()
    .collection('Velocity')
    .doc('beta')
    .collection('v1')
    .doc(id)
    .collection('entries')
    .limit(limit);

  const buildDataSnap = await dataRef.get();

  const buildData = buildDataSnap.docs.map((doc) => doc.data().data);

  const scrolls = buildData.map((doc) => processScrollDataWithTime(doc));
  console.log('scrolls', scrolls);
  // get the average scroll distance for the build
  const avgScrollDistance = getAverageScrollDistance(scrolls);
  const aggregatedData = scrolls.map((session) => {
    // each session has scrolls that may have sequences in the same direction, we
    // need to aggregate these sequences into one value/object in the array to
    // represent each direction movement
    return aggregateByDirection(session);
  });
  // calculate velocity and pressure data
  const velocityData = addVelocity(aggregatedData);
  const averageVelocityData = combineVelocitySessions(velocityData);
  const sessionPressure = calculateRollingPressure(averageVelocityData);

  // calculate the average pressure for the build
  const averagePressure = averageArrayOfInts(
    scrolls.reduce((acc, session) => {
      const incomingPressureSequence = getSingleSessionPressures(session);
      if (!incomingPressureSequence.length) {
        console.error('No pressure data found in the session');
        return acc;
      }
      const sessionPressure = getAveragePressure(incomingPressureSequence);
      acc.push(sessionPressure);
      return acc;
    }, [])
  );

  // mimicking the aggregation of scroll squiggles that happens in the cloud function
  let totalSessions = 0;
  let averageScrollSquiggle = [];
  for (let i = 0; i < scrolls.length; i++) {
    const scrollSession = scrolls[i];
    totalSessions++;
    const scrollSquiggle = prepareScrollSquiggleChartData([scrollSession]);
    const newAverageScrollSquiggle = aggregateScrollSquiggle({
      avgScrollSquiggle: averageScrollSquiggle,
      incomingScrollSquiggle: scrollSquiggle,
      totalSessions,
    });
    averageScrollSquiggle = newAverageScrollSquiggle;
  }

  // fetch benchmark data
  const benchmarkRef = firebase.firestore().collection('Velocity').doc('beta');
  const benchmarkDataSnap = await benchmarkRef.get();
  const benchmarkData = benchmarkDataSnap.data();

  return {
    ...benchmarkData,
    totalSessions,
    avgScrollDistance,
    sessionPressure,
    avgPressure: averagePressure,
    avgScrollSquiggle: averageScrollSquiggle,
  };
}

// -----------------------------------------------------------------------------------------
const calculateTempBenchmark = (scrollSquiggle) => {
  // Iterate over the new data array to adjust 'y' values
  return scrollSquiggle.map((point, index) => {
    const randomAdjustment = Math.random(); // Generate a random number for probability

    let newY;
    if (randomAdjustment < 0.7) {
      // Decrease 'y' by 10-50%
      const decreasePercentage = 0.3 + (0.4 - 0.6) * Math.random();
      newY = point.y * (1 - decreasePercentage);
    } else if (randomAdjustment >= 0.7 && randomAdjustment < 0.8) {
      // Keep 'y' the same
      newY = point.y;
    } else {
      // Increase 'y' by ~5%
      newY = point.y * 1.1;
    }

    return { x: point.x, y: newY };
  });
};

//
// getScrollLength
// for the benchmark lines
// This needs a sanity check
// not calculating the average scroll length here, we're hoping that the returned scroll squiggle data is averaged out in a way where
// it's length is the average scroll length or at least the point at which we stop getting non outlier data

// we find the last non zero y value
// we then jump forward one index and return the x value of that index
// why jump forward one index? because the line on the chart has easing, and it's at this next index point
// where the line returns back to the middle, so thats where the benchmark line looks like it's finished

function getScrollLength(data) {
  let lastIndexWithNonZeroY = -1;

  // Iterate over the data to find the last index with non-zero y
  for (let i = 0; i < data.length; i++) {
    if (data[i].y !== 0) {
      lastIndexWithNonZeroY = i;
    }
  }

  // Check if there is a next object
  if (lastIndexWithNonZeroY !== -1 && lastIndexWithNonZeroY + 1 < data.length) {
    return data[lastIndexWithNonZeroY + 1].x;
  }

  // if there is no next object, return the value of the last object
  if (lastIndexWithNonZeroY !== -1) {
    return data[lastIndexWithNonZeroY].x;
  }

  // If no suitable object is found, return the last x value
  return data[data.length - 1].x;
}
