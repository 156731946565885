import styled from "@emotion/styled";
import React, { forwardRef, ReactNode } from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "sm" | "md";
  variant?: "primary" | "secondary" | "tertiary";
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactElement;
  children: ReactNode;
}

const ButtonTemplate =`
  display: flex;
  align-items: center;
  gap: var(--space-2);
  white-space: nowrap;

  cursor: pointer;

  border-radius: var(--radii-0);
  line-height: var(--lineHeights-3);
  letter-spacing: var(--letterSpacings-0);
  transition: var(--transitions-0);
`

const PrimaryButton = styled.button<{
  fontSize: string;
  padding: string;
  kind: string;
}>`
  ${ButtonTemplate}
  color: var(--buttons-primary-text);
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ padding }) => padding};
  background: var(--buttons-primary-background);
  border: var(--borderWidths-0) var(--buttons-primary-border)
    var(--borderStyles-0);
  font-weight: var(--fontWeights-1);

  &:hover {
    background: var(--buttons-primary-hover-background);
    border-color: var(--buttons-primary-hover-border);
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--buttons-primary-disabled-background);
    border-color: var(--buttons-primary-disabled-border);
  }
`;

const SecondaryButton = styled.button<{
  fontSize: string;
  padding: string;
  kind: string;
}>`
  ${ButtonTemplate}
  color: var(--buttons-secondary-text);
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ padding }) => padding};
  background: var(--buttons-secondary-background);
  border: var(--borderWidths-0) var(--buttons-secondary-border)
    var(--borderStyles-0);
  font-weight: var(--fontWeights-1);

  &:hover {
    color: var(--buttons-secondary-hover-text);
    border-color: var(--buttons-secondary-hover-border);
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--buttons-secondary-disabled-text);
    border-color: var(--buttons-secondary-disabled-border);
  }
`;

const TertiaryButton = styled.button<{
  fontSize: string;
  padding: string;
  kind: string;
}>`
  ${ButtonTemplate}
  color: var(--buttons-tertiary-text);
  font-size: ${({ fontSize }) => fontSize};
  padding: ${({ padding }) => padding};
  background: var(--buttons-tertiary-background);
  border: var(--buttons-tertiary-border);
  font-weight: var(--fontWeights-2);

  &:hover {
    color: var(--buttons-tertiary-hover-text);
    border-color: var(--buttons-tertiary-hover-border);
    background-color: var(--buttons-tertiary-hover-background);
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--buttons-tertiary-disabled-text);
    border-color: var(--buttons-tertiary-disabled-border);
  }
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = "sm",
      variant = "primary",
      leftIcon,
      rightIcon,
      children,
      ...restProps
    }: ButtonProps,
    ref
  ) => {
    const btnSize = {
      md: {
        font: "var(--fontSizes-2)",
        padding: "var(--space-2) var(--space-4) ;",
      },
      sm: {
        font: "var(--fontSizes-1)",
        padding: "var(--space-1) var(--space-3);",
      },
    }[size];

    const ButtonVariant = {
      primary: PrimaryButton,
      secondary: SecondaryButton,
      tertiary: TertiaryButton,
    }[variant];

    const kind = {
      md: "baseline",
      sm: "body01",
    }[variant];

    const lineHeight = {
      md: "var(--lineHeights-2)",
      sm: "var(--lineHeights-2)",
    }[variant];

    const letterSpacings = {
      md: "var(--letterSpacings-2)",
      sm: "var(--letterSpacings-2)",
    }[variant];

    return (
      <ButtonVariant
        ref={ref}
        kind={kind}
        fontSize={btnSize.font}
        padding={btnSize.padding}
        {...restProps}
      >
        {leftIcon} {children} {rightIcon}
      </ButtonVariant>
    );
  }
);
