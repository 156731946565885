import { Box, Flex, Text } from "@chakra-ui/react";
import { scrollToElementById } from "../../util/scrollToElement.js";
import { IconInBox } from "../../components/UI/Buttons/IconInBox.jsx";
export const MenuItem = ({ children, selected, text, id, icon }) => {
  return (
    <Box
      width="100%"
      padding="5px 5px"
      my={2}
      border={selected ? "solid 1px #ccc" : "solid 1px transparent"}
      borderRadius="md"
      _hover={{ cursor: "pointer", backgroundColor: "gray.50" }}
      onClick={() => {
        scrollToElementById(id);
      }}
    >
      <Flex alignItems={"center"}>
        {icon && <IconInBox CustomIcon={icon} width="24px" height="24px" />}
        {text && (
          <Text ml={icon ? 2 : 0} fontSize="md">
            {text}
          </Text>
        )}
      </Flex>
      {children}
    </Box>
  );
};
