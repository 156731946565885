import PreviewerIFrame from "../../components/PreviewerIFrame/PreviewerIFrame";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { fetchIframeSourceDoc } from "../../services/studio";
import { BiLineChart } from "react-icons/bi";
import { IconInBox } from "../../components/UI/Buttons/IconInBox";
import { Text, HStack, useTheme } from "@chakra-ui/react";

export const PreviewWrapper = ({
  buildID,
  dcoVariation,
  setScrollProgress,
  scale = 0.5,
  position = { top: "0px", right: "0px" },
  title = "Preview",
  color = "gray",
  emoji = null,
}) => {
  const theme = useTheme();
  const [iframeSrcDoc, setIframeSrcDoc] = useState(null);
  const [adWindow, setAdWindow] = useState(null);

  const scrollCallback = (e) => {
    const iframe = e.target
      .getElementById("middle-ad")
      .getElementsByTagName("iframe")[0];
    const progress = iframe.contentWindow.format.cat.timeline;
    if (setScrollProgress) setScrollProgress(progress);
  };

  const iframeOnLoadHandler = (e) => {
    const previewWindow = e.target.contentWindow;
    const iframeWindow = previewWindow.document
      .getElementById("middle-ad")
      .getElementsByTagName("iframe")[0];

    // to load the correct variation we send an event that the iframe listens for
    iframeWindow.contentWindow.dispatchEvent(
      new CustomEvent("dcoPreviewID", {
        detail: { dcoPreviewID: dcoVariation },
      })
    );

    previewWindow.scrollTo({
      top: 1500,
      behavior: "smooth",
    });

    setAdWindow(previewWindow);
    previewWindow.addEventListener("scroll", scrollCallback);
  };

  useEffect(() => {
    if (adWindow) {
      return adWindow.removeEventListener("scroll", scrollCallback);
    }
  }, [adWindow]);

  useEffect(() => {
    if (buildID && !iframeSrcDoc) {
      try {
        fetchIframeSourceDoc(buildID).then((srcDoc) => {
          setIframeSrcDoc(srcDoc);
        });
      } catch (error) {
        setErrorMessage(error);
      }
    }
  }, [dcoVariation]);

  // Bit of weirdness with the Heading in the iframe, as it's scaled down in size inside the preview iframe
  const Heading = () => (
    <HStack
      pt="4"
      px="5"
      pb="3"
      gap="4"
      bg="#dddddd"
      position="relative"
      borderTopRadius={"30px"}
      mr="2" //so it lines up with the mobile frame
      ml="1"
      borderBottom="solid 1px #ccc"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
    >
      <IconInBox
        CustomIcon={BiLineChart}
        color={theme.colors[color]["500"]}
        width={"24px"}
        height={"24px"}
      />
      <Text fontSize="xl" noOfLines={1} fontWeight="500" color="black">
        {title}
      </Text>
      {emoji && (
        <Text
          cursor={"default"}
          pos={"absolute"}
          right="-10px"
          transform={"rotate(20deg)"}
          fontSize="80px"
          color="black"
        >
          {emoji}
        </Text>
      )}
    </HStack>
  );

  return (
    <>
      <AnimatePresence>
        {iframeSrcDoc && (
          <motion.div
            style={{
              position: "relative",
              top: position.top,
              right: position.right,
              width: "100%",
            }}
            key={`${dcoVariation}-wrapper`}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
          >
            <PreviewerIFrame
              key={dcoVariation}
              srcDoc={iframeSrcDoc}
              heading={<Heading />}
              onLoadHandler={iframeOnLoadHandler}
              creativeFormat="hang-time"
              showWrapper={false}
              creativeID={buildID}
              isLoading={false}
              isLegacy={true}
              scale={scale}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
