import {
  Area,
  Bar,
  ComposedChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import { useState } from "react";
import { CustomLegend } from "./CustomLegend";
import { CustomTooltip } from "../UI/Tooltip/CustomTooltip";
import { getBrightness, changeArrayRange } from "../../util/helpers.js";
import { Flex, Text, Box, Center, Badge } from "@chakra-ui/react";

export const LineChart = ({
  adProgress = null,
  series,
  xAxisDomain = [0, 100],
  yAxisDomain = [0, 1],
  showLegend = false,
  type = "area",
  height = 400,
  width = "100%",
  animationDuration = 1000,
  header = null,
  active = true,
}) => {
  // setting just the first two series to be active by default
  const [inactiveSeries, setInactiveSeries] = useState(
    series.map((s, i) => (i > 1 ? s.key : ""))
  );
  const [hoveredSeries, setHoveredSeries] = useState(null);

  // not going to render the chart lines if there is nothing to render
  const shouldRenderChartData = !series.some(({ data }) => data.length === 1);

  const Chart = getChart(type);
  function getChart(type) {
    switch (type) {
      case "area":
        return Area;
      case "bar":
        return Bar;
      case "heat":
        return Bar;
      default:
        return Area;
    }
  }

  function combineSeries(series) {
    const data = [];
    const length = series[0].data.length;
    for (let i = 0; i < length; i++) {
      const item = {};
      series.forEach((seriesItem) => {
        item[seriesItem.key] = seriesItem.data[i];
        item.key = seriesItem.key;
        item.color = seriesItem.color;
      });
      item.timeline = i;
      data.push(item);
    }
    if (type === "heat") {
      /**
       * To get the "heat map" colours we need to convert the values to
       * brightness values based on Chakra colour scales.
       */
      const values = changeArrayRange(data.map((item) => item[item.key]));
      const newData = data.map((item, i) => {
        const color = item.color;
        const brightness = getBrightness(values[i]);
        return {
          ...item,
          [item.key]: 1,
          fill: `var(--chakra-colors-${color}-${brightness})`,
        };
      });
      return newData;
    }
    return data;
  }

  const toggleItem = (item) => {
    if (inactiveSeries.includes(item)) {
      setInactiveSeries((prevState) =>
        prevState.filter((element) => element !== item)
      );
    } else {
      setInactiveSeries((prevState) => [...prevState, item]);
    }
  };

  const data = combineSeries(series);
  return (
    <div style={{ position: "relative" }}>
      {header && (
        <Flex marginBottom="2" justifyContent={"space-between"}>
          <Flex>
            <Text fontSize="md" mr={2} fontWeight={400}>
              {header.title}
            </Text>
            {header.tooltip && <CustomTooltip label={header.tooltip} />}
          </Flex>
          <Flex>
            {header.keys &&
              header.keys.map((key) => {
                return (
                  <Flex mx="8px" alignItems={"center"}>
                    <Box
                      w={"30px"}
                      h="4px"
                      borderRadius={"xl"}
                      bgColor={key.color}
                      mr="8px"
                    ></Box>
                    <Text fontSize={"xs"}>{key.title}</Text>
                  </Flex>
                );
              })}
          </Flex>
        </Flex>
      )}
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={data}
          barCategoryGap={0}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          {showLegend && (
            <Legend
              content={
                <CustomLegend
                  series={series}
                  inactiveSeries={inactiveSeries}
                  onSelect={toggleItem}
                  onHoverStart={setHoveredSeries}
                  onHoverEnd={setHoveredSeries}
                />
              }
            />
          )}

          <CartesianGrid fill="#F8F8F8" stroke="transparent" />
          <XAxis
            dataKey="timeline"
            type="number"
            tickLine={true}
            domain={xAxisDomain}
            allowDataOverflow={true}
            axisLine={false}
            ticks={["Scroll in", "Scroll out"]}
          ></XAxis>

          <YAxis domain={yAxisDomain} hide></YAxis>
          {data &&
            shouldRenderChartData &&
            series.map((item, index) => {
              return (
                <Chart
                  key={index}
                  type="monotone"
                  dataKey={item.key}
                  animationDuration={animationDuration}
                  stroke={type === "heat" ? "none" : item.color}
                  strokeWidth={hoveredSeries === item.key ? 3 : 2}
                  fill={item.fill ? item.color : "none"}
                  fillOpacity={type === "heat" ? 1 : 0.3}
                  isAnimationActive={true}
                  hide={inactiveSeries.includes(item.key)}
                  strokeDasharray={item.dashed ? "5 5" : "0 0"}
                />
              );
            })}
          {adProgress && (
            <ReferenceLine
              x={Math.round(adProgress)}
              fill="#000"
              stroke="rgba(0,0,0,0.50)"
              strokeWidth="3px"
              isFront={true}
            />
          )}
          <defs>
            <linearGradient id="linear">
              <stop offset="25%" stopColor="#00CE7C" />
              <stop offset="100%" stopColor="#4AC1E0" />
            </linearGradient>
          </defs>
        </ComposedChart>
      </ResponsiveContainer>
      {!active && (
        <Center
          pos="absolute"
          backdropFilter={"blur(1.5px)"}
          bgColor={"#ffffff30"}
          top="0px"
          left="0px"
          width="100%"
          height="100%"
        >
          <Badge padding={2} variant="subtle" colorScheme="orange">
            No data available. Campaign yet to start.
          </Badge>
        </Center>
      )}
    </div>
  );
};
