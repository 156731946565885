import styled from "@emotion/styled";
import React from "react";
import { HStack, Text } from "@chakra-ui/react";
export const GraphLegends = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  color: var(--colors-neutral-2);
  background: white;
  box-sizing: border-box;
  opacity: 1;
  > div {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    span {
      width: 30px;
      height: 4px;
      display: block;
      border-radius: 4px;
    }
  }
`;

const ActualLegend = styled.span`
  height: 4px;
  width: 30px;
  background: var(--colors-brandGradient);
  display: block;
  border-radius: 4px;
`;
const BaselineLegend = styled.span`
  height: 4px;
  width: 30px;
  background: #c4c4c4;
  display: block;
  border-radius: 4px;
`;
const OldLegend = styled.span`
  height: 4px;
  width: 30px;
  background: #c5c5c5;
  display: block;
  border-radius: 4px;
`;
const NewLegend = styled.span`
  height: 4px;
  width: 30px;
  background: var(--colors-secondary);
  display: block;
  border-radius: 4px;
`;

const ScrubLegend = styled.span`
  height: 4px;
  width: 30px;
  background: var(--colors-neutral-2);
  display: block;
  border-radius: 4px;
`;
const DwelltimeLegend = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: var(--colors-brandGradient);
  opacity: 0.9;
`;

GraphLegends.Actual = () => (
  <LegendsHStack gap={2} alignItems={"center"}>
    <Text fontSize={"xs"}>Actual:</Text> <ActualLegend />
  </LegendsHStack>
);

GraphLegends.Baseline = () => (
  <LegendsHStack alignItems={"center"}>
    <Text fontSize={"xs"}>Format Average:</Text> <BaselineLegend />
  </LegendsHStack>
);

GraphLegends.Old = () => (
  <LegendsHStack gap={2} alignItems={"center"}>
    <Text fontSize={"xs"}>Creative A:</Text> <OldLegend />
  </LegendsHStack>
);

GraphLegends.New = () => (
  <LegendsHStack alignItems={"center"}>
    <Text fontSize={"xs"}>Creative B:</Text> <NewLegend />
  </LegendsHStack>
);

GraphLegends.Scrub = () => (
  <LegendsHStack alignItems={"center"}>
    <Text fontSize={"xs"}>Ad Engagement:</Text> <ScrubLegend />
  </LegendsHStack>
);

GraphLegends.Dwelltime = () => (
  <LegendsHStack alignItems={"center"}>
    <Text fontSize={"xs"}>Dwell Time:</Text> <DwelltimeLegend />
  </LegendsHStack>
);

const LegendsHStack = ({ children }) => {
  return (
    <HStack alignItems="center" gap={2} py="1">
      {children}
    </HStack>
  );
};
