import { Button, Text, VStack } from "@chakra-ui/react";
import {
  CreativeCardGrid,
  FavoutitesCardGrid,
} from "@composites/CreativeCardGrid";
import { SpinnyCat } from "@ui-lib/index";
import React, { useEffect } from "react";
import { useCountsByUserId } from "../../hooks/useCounts";
import fetchHomepageData from "../../services/homepageData";
import { HomepageStore } from "../../store/homepage";
import { UIStore } from "../../store/UIStore";
import { HomeFilters } from "./HomeFilters";

export interface MetaV2Document {
  id: string;
  adBuyId: string;
  aggregatedSessionSize: number;
  brand: string;
  campaign: string;
  creative: string;
  favCount: number;
  format: string;
  containsVideo: boolean;
  updated: string;
  vertical: string;
  currentUserFavourite?: boolean;
}

export const Home = () => {
  const status = HomepageStore((state) => state.status);
  const setStatus = HomepageStore((state) => state.setStatus);
  const filters = HomepageStore((state) => state.filters);
  const hasNextPage = HomepageStore((state) => state.hasNextPage);
  const homepageData = HomepageStore((state) => state.data);
  const loadMore = HomepageStore((state) => state.loadMore);
  const pageData = HomepageStore((state) => state.pageData);
  const setHomepageData = HomepageStore((state) => state.setData);
  const setHomepageOptions = HomepageStore((state) => state.setOptions);
  const filteredData = HomepageStore((state) => state.filteredData);

  const userInfo = UIStore((state) => state.userInfo);
  const {
    counts: { favourites: userFavourites = [] },
    toggleFavourite,
  } = useCountsByUserId(userInfo.id);

  useEffect(() => {
    if (status === "ready") {
      // console.log('Homepage data loaded', homepageData);
      setStatus("loading");
      fetchHomepageData()
        .then(({ data, options }) => {
          setHomepageData(data);
          setHomepageOptions(options);
          setStatus("loaded");
        })
        .catch((error) => {
          console.error("Error fetching homepage data", error);
          setStatus("error");
        });
    }
  }, [status]);

  if (status === "loading") return <SpinnyCat size="s" />;
  if (status === "error")
    return <Text>There was an error fetching the data.</Text>;

  const hasFilters = Object.values(filters).some((filter) => filter.length > 0);

  return (
    <VStack
      justifyContent="flexStart"
      overflow="hidden"
      maxW="99vw"
      w="100%"
      pt="50px"
      px="4"
    >
      <HomeFilters />

      <VStack
        transition="height 0.5s ease, opacity 0.3s ease, margin 0.3s ease"
        mt={hasFilters ? "-50px" : "0"}
        h={hasFilters ? "0" : "100%"}
        opacity={hasFilters ? 0 : 1}
        pointerEvents={hasFilters ? "none" : "auto"}
        border="1px solid"
        borderRadius="md"
        borderColor="gray.200"
        alignItems="flexStart"
        w="100%"
        maxW="1500px"
        gap="4"
        py="4"
        px="4"
      >
        <FavoutitesCardGrid
          userFavourites={userFavourites}
          toggleFavourite={toggleFavourite}
          data={homepageData}
          limit={6}
        />
      </VStack>
      <VStack
        justifyContent="flexStart"
        alignItems="flexStart"
        w="100%"
        maxW="1500px"
        px="4"
        pt="4"
        pb="24"
      >
        <Text fontSize="md" color="gray.600" fontWeight="semibold" mb="1">
          Creatives{" "}
          <Text as="span" fontWeight="normal" fontSize="sm">
            ({pageData.length} of {filteredData.length})
          </Text>
        </Text>
        <CreativeCardGrid
          userFavourites={userFavourites}
          toggleFavourite={toggleFavourite}
          creatives={pageData}
        />
        <Button
          onClick={loadMore}
          display={hasNextPage ? "block" : "none"}
          // 👆 hides the button * most * of the time when there is no more creatives
          // which is good enough for now
          disabled={!hasNextPage}
          colorScheme="teal"
          variant="outline"
          size="md"
          mt="4"
        >
          Load More
        </Button>
      </VStack>
    </VStack>
  );
};
