// Global styles that are applied to the entire app through the chakra theme
// https://chakra-ui.com/docs/theming/theme

import { ChakraTheme } from '@chakra-ui/react';

export const styles: ChakraTheme['styles'] = {
  global: {
    'html, body': {
      fontFamily: 'Mulish, sans-serif',
      color: 'text-default',
      bg: 'bg-default',
      lineHeight: 'base',
    },
    '*::placeholder': {
      color: 'text-default-placeholder',
    },
    '*, *::before, &::after': {
      borderColor: 'border-default',
      wordWrap: 'break-word',
    },
  },
};
