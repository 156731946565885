import { faChartPie, faPoll } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  prepCarouselChartData,
  prepCarouselTopAssets,
} from "../../util/dcoaUtils";
import styles from "./CATRouselViewer.module.scss";
import { RelativeScoreCompareChart } from "./RelativeScoreCompare";
import { TopProducts } from "./TopProducts";

export const DCOACarousel = ({
  currentDomainRecommendations,
  creativeData,
}) => {
  const [chartData, setChartData] = useState([]);
  const [recommendations, setRecommendations] = useState([]);

  useEffect(() => {
    if (creativeData?.DCOA && currentDomainRecommendations) {
      const recoPrep = prepCarouselTopAssets(currentDomainRecommendations);
      setRecommendations(recoPrep);

      const chartData = prepCarouselChartData(recoPrep);
      setChartData(chartData);
    }
  }, [creativeData, currentDomainRecommendations]);

  return (
    <>
      {/* {chartData && (
        <>
          <div name="topProducts" className={styles.sectionTitle}>
            <FontAwesomeIcon icon={faChartPie} />
            Weight Distribution
          </div>
          <RelativeScoreCompareChart
            data={chartData}
            width={400}
            height={300}
          />
        </>
      )} */}
      {creativeData?.DCOA?.type === "carousel" && (
        <>
          <div name="topProducts" className={styles.sectionTitle}>
            <FontAwesomeIcon icon={faPoll} />
            Top Products
          </div>
          <TopProducts recommendations={recommendations} />
        </>
      )}
    </>
  );
};
