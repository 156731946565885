import create from "zustand";
import { fetchMetrics } from "../services/theplayground";
// import { mountStoreDevtool } from "simple-zustand-devtools";

const MetricStore = create((set, get) => ({
  attentionTime: "loading...",
  benchmarkEngagementRate: 0,
  benchmarkEngagedAttentionTime: 0,
  engagementRate: "loading...",
  engagedAttentionTime: "loading...",
  fetchMetrics: async (creativeID, domainWhiteList = []) => {
    set({
      engagementRate: "loading...",
      engagedAttentionTime: "loading...",
      attentionTime: "loading...",
    });

    if (creativeID) {
      const {
        attentionTime,
        benchmarkEngagementRate,
        benchmarkEngagedAttentionTime,
        engagementRate,
        engagedAttentionTime,
        creatives
      } = await fetchMetrics(creativeID, domainWhiteList);
      set({
        attentionTime,
        benchmarkEngagementRate,
        benchmarkEngagedAttentionTime,
        engagementRate,
        engagedAttentionTime,
        creatives
      });
    }
  },
}));
// if (process.env.NODE_ENV === "development") {
//   mountStoreDevtool("MetricStore", MetricStore);
// }
export { MetricStore };
