import { useCallback, useRef, useState } from "react";
import { ScrollSequencer } from "./ScrollSequencer";

export interface SequenceData {
  duration: number;
  yDifference: number;
  engadementIndex: number;
}

// To start passing through currentY, run loadIFrame

export function useAdScrollState(
  targetRef: React.RefObject<HTMLIFrameElement>
) {
  const [scrollQueue, setScrollQueue] = useState([] as SequenceData[]);
  const [playing, setPlaying] = useState(false);
  const [currentMovementIndex, setCurrentMovementIndex] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0); // percentage
  const [currentY, setCurrentY] = useState(0); // y position in pixels

  // ------------------------------------------------------------
  const scrollSequencer = useRef<ScrollSequencer | null>(null);

  // setting up the actions, binding them to the sequencer
  const play = useCallback(
    (index?: number) => {
      scrollSequencer.current?.play(index);
    },
    [scrollSequencer.current]
  );

  const pause = useCallback(() => {
    scrollSequencer.current?.pause();
  }, [scrollSequencer.current]);

  const resume = useCallback(() => {
    scrollSequencer.current?.resume();
  }, [scrollSequencer.current]);

  const playFromPercent = useCallback(
    (percent: number) => {
      //between 0 and 1 for percentage
      scrollSequencer.current?.jumpToPercentElapsed(percent);
    },
    [scrollSequencer.current]
  );

  // function throttle(func: Function, limit: number) {
  //   let inThrottle: boolean;
  //   return function (this: any) {
  //     const args = arguments;
  //     const context = this;
  //     if (!inThrottle) {
  //       func.apply(context, args);
  //       inThrottle = true;
  //       setTimeout(() => (inThrottle = false), limit);
  //     }
  //   };
  // }
  const loadQueue = useCallback(
    (queue: SequenceData[]) => {
      setScrollQueue(queue);

      const iframeWindow = targetRef.current.contentWindow;
      if (!iframeWindow) return console.error("iframe has no contentWindow");

      loadIFrame();

      scrollSequencer.current = new ScrollSequencer(
        queue,
        iframeWindow,
        //👇 callbacks to set state back in this hook ------
        setPlaying,
        setCurrentMovementIndex,
        setCurrentProgress
        // ------------------------------------------------
      );
    },
    [setScrollQueue, targetRef.current]
  );

  // run this to get y postition back
  const loadIFrame = useCallback(() => {
    const iframeWindow = targetRef.current.contentWindow;
    if (!iframeWindow) return console.error("iframe has no contentWindow");
    // attach a scrollListener to iframeWindow with throttling
    // felt laggy? so throttled... might not be fixing much

    // this is where we can access the iframe content
    // const formatIframe = iframeWindow.document
    //   .getElementById('middle-ad')
    //   .getElementsByTagName('iframe')[0];

    iframeWindow.addEventListener(
      "scroll",
      () => {
        setCurrentY(iframeWindow.scrollY);
      }

      // throttle(() => {
      //   // here we can access things attached to the ad format
      //   // console.log(formatIframe.contentWindow?.format);
      // }, 100)
    );
  }, [targetRef.current]);

  const scrollActions = {
    play,
    pause,
    resume,
    loadQueue,
    loadIFrame,
    playFromPercent,
  };

  const scrollStatus = {
    playing,
    currentMovementIndex,
    // currentProgress,
    scrollQueue,
    // currentY,
  };

  return { scrollStatus, scrollActions, currentY, currentProgress };
}
