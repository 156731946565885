import styled from "@emotion/styled";
import React from "react";
import QRCode from "qrcode.react";
import useStudioBuildID from "../../hooks/useStudioBuildID";
import { CreativeStore } from "../../store/CreativeStore";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  IconButton,
  Portal,
  VStack,
} from "@chakra-ui/react";
import { BiQr } from "react-icons/bi";

export const LeftPanel = ({ children, ...rest }) => {
  return (
    <VStack
      w="100%"
      h="100%"
      p={4}
      borderColor="gray.200"
      justifyContent={"flex-start"}
      borderRight="1px solid var(--colors-neutral-4)"
      overflow="hidden"
      maxW={"32%"}
      {...rest}
    >
      {children}
    </VStack>
  );
};

export const RightPanel = ({ children, ...rest }) => {
  return (
    <VStack
      flexGrow={1}
      minH={"550px"}
      w="100%"
      h="100%"
      overflowY="scroll"
      {...rest}
    >
      {children}
    </VStack>
  );
};

// ----------------------------------------------------------------------------
// QR Code

export const QrButton = (props) => {
  const { buildId } = props;
  const qrId = useStudioBuildID(buildId);
  const urlString = `https://studio.playgroundxyz.com/${qrId}/quiet?pos=middle-ad&size=300x250&dpframe=0`;

  // accessing the iframe video (if it exists) to pause it when the qrCode displays, as chart re-rendering is causing rendering issues for the Popover
  const iframeVideo = CreativeStore((state) => state.iframeVideo);

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          onMouseEnter={() => {
            if (iframeVideo) iframeVideo.pause();
          }}
          onMouseLeave={() => {
            if (iframeVideo) iframeVideo.play();
          }}
          aria-label="Scan QR Code"
          icon={<BiQr size={"25px"} />}
          size="sm"
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontSize={"sm"}>
            Scan to view this creative on mobile
          </PopoverHeader>
          <PopoverBody py={10}>
            <QRCode
              renderAs={"svg"}
              style={{
                width: "100%",
              }}
              size={250}
              value={urlString}
              bgColor="#fff"
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
