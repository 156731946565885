import {
  useCreativeData,
  useCurrentDomainRecommendations,
} from "@services/firestore";
import { SpinnyCat } from "@ui-lib";
import React from "react";
import { withRouter } from "react-router";
import styles from "./CATRouselViewer.module.scss";
import { DCOACarousel } from "./DCOACarousel";
import { DCOAVideo } from "./DCOAVideo";

export const DCOA = withRouter(
  ({
    match: {
      params: { buildID },
    },
    header: isUserloggedIn,
  }) => {
    const [creativeData, loadingCreativeData, errorCreativeData] =
      useCreativeData(buildID);

    const [
      currentDomainRecommendations,
      loadingRecommendations,
      errorRecommendations,
    ] = useCurrentDomainRecommendations(buildID);

    errorCreativeData && console.error(errorCreativeData);
    errorRecommendations && console.error(errorRecommendations);

    return (
      <div className={styles.catrouselViewer}>

        <div className={styles.campaignTitle}>
          <div className={styles.brand}>
            {creativeData?.brandNames ?? "Campaign name"}
          </div>
          <div className={styles.campaign}>
            {creativeData?.brandNames ?? "Campaign name"}
          </div>
        </div>
        {(loadingCreativeData || loadingRecommendations) && <SpinnyCat />}

        {creativeData?.DCOA?.type === "carousel" && (
          <DCOACarousel
            buildID={buildID}
            creativeData={creativeData}
            currentDomainRecommendations={currentDomainRecommendations}
          />
        )}
        {creativeData?.DCOA?.type === "video" && (
          <DCOAVideo
            buildID={buildID}
            creativeData={creativeData}
            currentDomainRecommendations={currentDomainRecommendations}
          />
        )}
      </div>
    );
  }
);
