import firebase from "firebase/app";
const db = firebase.firestore();

export const getCountsByBuildId = async (buildId) => {
  const countsRef = db.collection("/Counts/byCreative/creatives/").doc(buildId);
  try {
    const snapshot = await countsRef.get();
    if (snapshot.exists) {
      return snapshot.data();
    } else {
      return { favourites: 0, views: 0 };
    }
  } catch (e) {
    console.log("error", e);
  }
};

export const getCountsByUserId = async (userId) => {
  const countsRef = db.collection("/Counts/byUser/users/").doc(userId);
  try {
    const snapshot = await countsRef.get();
    if (snapshot.exists) {
      const data = snapshot.data();
      return {
        favourites: data.favourites.sort((a, b) => b.updated - a.updated),
      };
    } else {
      return { favourites: [] };
    }
  } catch (e) {
    console.log("error", e);
  }
};

export const addToUserFavourites = async (userId, buildId) => {
  const userRef = db.collection("/Counts/byUser/users/").doc(userId);

  const creativeRef = db
    .collection("/Counts/byCreative/creatives/")
    .doc(buildId);

  const updated = new Date().getTime();
  const transaction = db.runTransaction(async (t) => {
    const userDoc = await t.get(userRef);
    const creativeDoc = await t.get(creativeRef);
    if (userDoc.exists) {
      const favourites = userDoc.data().favourites;
      const alreadyFavourited = favourites.find((fav) => fav.id === buildId);
      if (!alreadyFavourited) {
        favourites.push({ id: buildId, updated });
        t.set(userRef, { favourites }, { merge: true });
      }
    } else {
      t.set(userRef, { favourites: [{ id: buildId, updated }] });
    }
    if (creativeDoc.exists) {
      const favourites = creativeDoc.data().favourites;
      t.set(
        creativeRef,
        { favourites: favourites + 1, updated },
        { merge: true }
      );
    } else {
      t.set(creativeRef, { favourites: 1, updated }, { merge: true });
    }
  });

  await transaction;
  return;
};

export const removeFromUserFavourites = async (userId, buildId) => {
  const userRef = db.collection("/Counts/byUser/users/").doc(userId);

  const creativeRef = db
    .collection("/Counts/byCreative/creatives/")
    .doc(buildId);

  const updated = new Date().getTime();
  const transaction = db.runTransaction(async (t) => {
    const userDoc = await t.get(userRef);
    const creativeDoc = await t.get(creativeRef);
    if (userDoc.exists) {
      const favourites = userDoc.data().favourites;
      const isFavourited = favourites.find((fav) => fav.id === buildId);
      if (isFavourited) {
        const newFavourites = favourites.filter((fav) => fav.id !== buildId);
        t.update(userRef, { favourites: newFavourites });
      }
    } else {
      t.set(userRef, { favourites: [] });
    }
    if (creativeDoc.exists) {
      const favourites = creativeDoc.data().favourites;
      t.set(
        creativeRef,
        { favourites: favourites - 1, updated },
        { merge: true }
      );
    } else {
      t.set(creativeRef, { favourites: 0, updated }, { merge: true });
    }
  });

  await transaction;
  return;
};

/**
 * Function to get the creatives with the most favourites and updated within
 */

export const getTopFavouritedCreatives = async (limit = 10) => {
  const countsRef = db.collection("/Counts/byCreative/creatives/");
  const snapshot = await countsRef
    .orderBy("favourites", "desc")
    .orderBy("updated", "desc")
    .where("favourites", ">", 0)
    .limit(limit)
    .get();
  const topFavourited = [];
  snapshot.forEach((doc) => {
    topFavourited.push({ id: doc.id, ...doc.data() });
  });
  const topWithScores = getTopFavouritesWithScores(topFavourited);
  return topWithScores;
};

const calculateWeightedScore = (item, currentTime) => {
  // tinker with these values to adjust the weighting of the score
  const favouritesWeight = 0.9;
  const recencyWeight = 0.1;

  const favouritesScore = item.favourites * favouritesWeight;

  // Calculate recency score (higher score for more recent items)
  const timeDiff = currentTime - item.updated;
  const daysSinceUpdate = timeDiff / (1000 * 60 * 60 * 24);
  const recencyScore = (1 / (daysSinceUpdate + 1)) * recencyWeight;

  return favouritesScore + recencyScore;
};

const getTopFavouritesWithScores = (favourites) => {
  const currentTime = Date.now();

  return favourites
    .map((item) => {
      const score = calculateWeightedScore(item, currentTime);
      return { ...item, score };
    })
    .sort((a, b) => b.score - a.score);
};
