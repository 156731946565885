export const formatsAndFeatures = [
  { value: "All Formats", label: "All Formats" },
  { value: "Hang Time Mobile", label: "Hang Time Mobile" },
  { value: "Hang Time Mobile Video", label: "Hang Time Mobile Video" },
  { value: "Velocity", label: "Velocity" },
  { value: "Hang Time Desktop", label: "Hang Time Desktop" },
];

// warn users if a creative has less than x sessions
export const SESSION_SIZE_WARNING_BENCHMARK = 1000;

// max amount of cards to show on the homescreen
export const MAX_LIMIT = 250;

export const METABABY_IMAGE_URLS = {
  // add the id to the middle of the array
  // e.g. METABABY_IMAGE_URLS.hangtime[0] + id + METABABY_IMAGE_URLS.hangtime[1]
  hangtime: [
    "https://20230804t043942-dot-meta-baby.uc.r.appspot.com/",
    "/375x745/hang-time/filmstrip?&styles=[%22.content{opacity:0.3;}.page-style-article%20header{display:none;}%22]&scrollToElement.offset=-360&screenshotSize=20&scrollIncrement=50&customFileID=wrapdeck&regnerateMedia=true",
  ],
};

export interface HomeFilterState {
  brand: Option[];
  vertical: Option[];
  format: Option[];
  sessionSize: number;
}

export interface Option {
  value: string;
  label: string;
}

export interface HomeFilterOptions {
  brands: Option[];
  verticals: Option[];
  formats: Option[];
  sessionSizes: number;
}
