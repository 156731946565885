import { CreativeCardGrid } from "./index";
import { useTopFavourites } from "../../hooks/useCounts";
import { useState, useEffect } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

const FavouritesCardGrid = ({
  data,
  userFavourites,
  toggleFavourite,
  limit,
}) => {
  const { topFavourites } = useTopFavourites();
  const [favourties, setFavourites] = useState([]);
  const [currentUserFavourites, setCurrentUserFavourites] = useState([]);
  useEffect(() => {
    if (!data || !topFavourites) return;
    const latestFavourites = topFavourites
      .map((fav) => {
        const match = data.find((creative) => creative.id === fav.id);
        if (!match) return null;
        return { ...match, favCount: fav.favourites };
      })
      .filter((x) => x);
    setFavourites(latestFavourites.slice(0, limit));
  }, [data, topFavourites, limit]);

  useEffect(() => {
    if (!data || !userFavourites) return;
    const allUserFavourites = userFavourites
      .map((fav) => {
        const match = data.find((creative) => creative.id === fav.id);
        if (!match) return null;
        return match;
      })
      .filter((x) => x);
    setCurrentUserFavourites(allUserFavourites);
  }, [data, userFavourites]);

  return (
    <Tabs variant="soft-rounded" colorScheme="green" width="100%">
      <TabList ml={-1}>
        <Tab>Highlights</Tab>
        <Tab>My Favourites</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0}>
          <CreativeCardGrid
            gridAutoFlow="column"
            overflowX={"scroll"}
            creatives={favourties}
            userFavourites={userFavourites}
            toggleFavourite={toggleFavourite}
          />
        </TabPanel>
        <TabPanel px={0}>
          <CreativeCardGrid
            gridAutoFlow="column"
            overflowX={"scroll"}
            creatives={currentUserFavourites}
            userFavourites={userFavourites}
            toggleFavourite={toggleFavourite}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default FavouritesCardGrid;
