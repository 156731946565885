import { EngadementScrollData } from "@controllers/ScrollController/getScrollQueue";

export const scrollInAnimation: EngadementScrollData = [
  {
    y: 10,
    x: 400,
  },
  {
    y: 200,
    x: 1400,
  },
  {
    y: 220,
    x: 1500,
  },
  {
    y: 230,
    x: 1700,
  },
  {
    y: 2,
    x: 2500,
  },
  {
    y: 10,
    x: 2600,
  },
  {
    y: 10,
    x: 3000,
  },
  {
    y: 15,
    x: 3350,
  },
  {
    y: 40,
    x: 3600,
  },
  {
    y: 270,
    x: 4000,
  },
  {
    y: 290,
    x: 4250,
  },
  {
    y: 300,
    x: 4600,
  },
  {
    y: 280,
    x: 5000,
  },
  {
    y: 270,
    x: 5100,
  },
];
