const bounceUp = (start = "140%", end = "0%") => `
    0% {
      opacity: 1;  
      transform: translateY(${start});
    }

    100% {
      opacity: 1;
      transform: translateY(${end});
    }
  `;
const bounceDown = (start = "0%", end = "200%") => `
    0% {
      opacity: 1;  
      transform: translateY(${start});
    }

    100% {
      opacity: 0;
      transform: translateY(${end});
    }
  `;

export { bounceUp, bounceDown };
