import { EngadementScrollData, ScrollQueueConfig, ScrollQueueReturn } from ".";
// getScrollQueue -----------------------------------------------------------------------
// takes in scroll data, for now grouped into sessions and engadements/direction changes
// averages y positions as differences between the last y position
// averages durations as differences between the last time
// adds delays between sessions

// returns a queue of scroll movements as { duration: number, yDifference: number }[]
export const getScrollQueueFromEngadements = (
  scrollData: EngadementScrollData,
  config = {
    initalY: 3000, //inital scroll position.
    durationScale: 1,
    scrollLengthScale: 1,
    delayDurationScale: 2,
    minDuration: 100,
    maxDuration: 1500,
    easingFunction: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  } as ScrollQueueConfig
): ScrollQueueReturn => {
  const scrollQueue = [];
  const engadements = scrollData.flat(1);

  // const initalScroll = {
  //   duration: getNextMovementDuration(0, 1000, config),
  //   yDifference: config.initalY,
  // };
  // scrollQueue.push(initalScroll);

  engadements.forEach((engadement, index) => {
    let direction = engadement[1].direction === 1 ? "down" : "up"; // the direction of the change/ middle value
    const initalTime = engadement[0].time;
    const initalY = engadement[0].y;

    const movement1 = {
      duration: getNextMovementDuration(initalTime, engadement[1].time, config),
      yDifference: getNextMovementY(
        // right now, YDifference can be positive or negative to indicate direction. but could just be positive and we alternate elsewhere if we want to.
        initalY,
        engadement[1].y,
        config
      ),
      engadementIndex: index,
    };

    const movement2 = {
      duration: getNextMovementDuration(
        engadement[1].time,
        engadement[2].time,
        config
      ),
      yDifference: getNextMovementY(engadement[1].y, engadement[2].y, config),
      engadementIndex: index,
    };

    // add the movements to the queue
    scrollQueue.push(movement1, movement2);

    // Delay -----------------------------------------------------------------------
    if (index < engadements.length - 1) {
      const nextEngadement = engadements[index + 1];
      const delay = getDelay(
        engadement[2].time,
        nextEngadement[0].time,
        config
      );
      // add a delay to the queue
      scrollQueue.push({
        duration: delay,
        yDifference: 0,
        engadementIndex: index,
      });
    }
  });

  return scrollQueue;
};

// getScrollQueue Helpers -----------------------------------------------------------------------
const getNextMovementDuration = (previousTime, currentTime, config) => {
  let duration = (currentTime - previousTime) * config.durationScale;
  if (duration < config.minDuration) {
    const upperBounds = config.minDuration * 2;
    duration =
      randomIntFromInterval(config.minDuration, upperBounds) *
      config.durationScale;
  }
  if (duration > config.maxDuration) {
    duration = config.maxDuration * config.durationScale;
  }
  return duration;
};

const getNextMovementY = (previousY, currentY, config) => {
  const direction = currentY > previousY ? 1 : -1;
  const amplitude = Math.abs(currentY - previousY) * config.scrollLengthScale;
  return direction * amplitude;
};

const getDelay = (lastTime, nextSessionFirstTime, config) => {
  let delay = nextSessionFirstTime - lastTime;
  // is the delay within a reasonable range?
  // if not, it might be a new session or strange data, and we just add a random one
  if (delay < config.minDuration) {
    delay = config.minDuration * config.delayDurationScale;
  } else if (delay > config.maxDuration) {
    delay =
      randomIntFromInterval(config.minDuration, config.maxDuration) *
      config.delayDurationScale;
  }

  return delay;
};

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
