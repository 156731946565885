import { RawScrollData, ScrollQueueConfig, ScrollQueueReturn } from ".";
// getScrollQueue -----------------------------------------------------------------------
// takes in scroll data, for now grouped into sessions and engadements/direction changes
// averages y positions as differences between the last y position
// averages durations as differences between the last time
// adds delays between sessions

// returns a queue of scroll movements as { duration: number, yDifference: number }[]
export const getScrollQueueFromScrollData = (
  scrollData: RawScrollData,
  config = {
    initalY: 3000, //inital scroll position.
    durationScale: 1,
    scrollLengthScale: 1,
    // delayDurationScale: 2,
    minDuration: 50,
    maxDuration: 1000,
    easingFunction: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  } as ScrollQueueConfig
): ScrollQueueReturn => {
  const scrollQueue = [];
  // const initalScroll = {
  //   duration: getNextMovementDuration(0, 1000, config),
  //   yDifference: config.initalY,
  // };

  // scrollQueue.push(initalScroll);

  scrollData?.forEach((scroll, index) => {
    // let direction = scroll.y < 0 ? "down" : "up"; // the direction of the change/ middle value
    const previousScroll = scrollData[index - 1] || { x: 0, y: 0 };

    const movement = {
      duration: getNextMovementDuration(previousScroll.x, scroll.x, config),
      yDifference: getNextMovementY(previousScroll.y, scroll.y, config),
      engadementIndex: 0, //not using this in getScrollQueueFromScrollData yet, but it's used in fromEngadements
    };

    scrollQueue.push(movement);
  });

  return scrollQueue;
};

// getScrollQueue Helpers -----------------------------------------------------------------------
const getNextMovementDuration = (previousTime, currentTime, config) => {
  let duration = (currentTime - previousTime) * config.durationScale;
  if (duration < config.minDuration) {
    const upperBounds = config.minDuration * 2;
    duration =
      randomIntFromInterval(config.minDuration, upperBounds) *
      config.durationScale;
  }
  if (duration > config.maxDuration) {
    duration = config.maxDuration * config.durationScale;
  }
  return duration;
};

const getNextMovementY = (previousY, currentY, config) => {
  const direction = currentY > previousY ? 1 : -1;
  const amplitude = Math.abs(currentY - previousY) * config.scrollLengthScale;
  return direction * amplitude;
};

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
