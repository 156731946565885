import { GraphLegends } from "@components/GraphLegends/GraphLegends";
import React, { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  ReferenceLine,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  ResponsiveContainer,
} from "recharts";
import { ScrubLabels } from "../Charts/ScrubLabels";
import { normalizeBaselineAndActual } from "../../util/sequencesUtil";

const DwellTimeChart = ({ adProgress, data, startProgress, endProgress }) => {
  const dwellTimeRange = [10, 1500];
  const [actualData, setActualData] = useState([]);
  const [baselineData, setBaselineData] = useState([]);

  useEffect(() => {
    const normalizedData = normalizeBaselineAndActual(
      data,
      startProgress,
      endProgress
    );
    setActualData(normalizedData);
    const convertBaseline = normalizedData.map((value) => {
      return {
        dwellTime: value.dwellTimeBaseline,
        playfulness: value.scrubBaseline,
        timeline: value.timeline,
      };
    });
    setBaselineData(convertBaseline);
  }, [data, startProgress, endProgress]);

  return (
    <>
      <ResponsiveContainer width={"100%"} aspect={5 / 3} maxHeight={270}>
        <ComposedChart
          margin={{
            top: 0,
            right: 10,
            left: 50,
            bottom: 20,
          }}
        >
          <CartesianGrid stroke="#e8e8e8" strokeDasharray="3 3" />
          <XAxis
            dataKey="timeline"
            type="number"
            tickLine={true}
            domain={[startProgress, endProgress]}
            allowDataOverflow={true}
            axisLine={false}
            ticks={["Scroll in", "Scroll out"]}
            name="Ad Progress"
          >
          </XAxis>
          <YAxis
            dataKey="playfulness"
            hide
            name="Ad Engagement"
            allowDataOverflow={true}
          ></YAxis>
          <ZAxis
            dataKey="dwellTime"
            range={dwellTimeRange}
            name="Avg Dwell Time"
            unit="ms"
            scale="time"
          />
          <defs>
            <linearGradient id="linear">
              <stop offset="25%" stopColor="rgba(255,138,63, 0.9)" />
              <stop offset="100%" stopColor="rgba(254,0,96, 0.9)" />
            </linearGradient>
          </defs>
          <Scatter
            name="Dwell Time Baseline"
            data={baselineData}
            fill="rgba(197,197,197,0.6)"
            line={{ stroke: "#c5c5c5", strokeWidth: 2 }}
            shape="circle"
            lineType="joint"
          />
          <Scatter
            name="Dwell Time"
            data={actualData}
            fill="url(#linear)"
            line={{ stroke: "#8b9192", strokeWidth: 2 }}
            shape="circle"
            lineType="joint"
          />
          <Area
            type="monotone"
            dataKey="scrubBaseline"
            stroke="#c5c5c5"
            strokeWidth={2}
            fillOpacity={0.1}
            fill="#c5c5c5"
            isAnimationActive={false}
          />
          <ReferenceLine
            x={Math.round(adProgress)}
            fill="#000"
            stroke="rgba(0,0,0,0.15)"
            strokeWidth="4px"
            isFront={true}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <ScrubLabels />
      <GraphLegends>
        <GraphLegends.Scrub />
        <GraphLegends.Dwelltime />
      </GraphLegends>
    </>
  );
};

export default DwellTimeChart;
