import { fetchCreativesBlacklist } from '@utils/creativeBlacklist';
import ggAdvertisersMap from '../../public/data/gg-advertisers.json';
import ggVerticals from '../../public/data/gg-verticals.json';

const META_V2_URL =
  'https://firebasestorage.googleapis.com/v0/b/cat-cache/o/meta-v2.json?alt=media&token=f4c75f83-6e73-4a62-b2c9-a30b97595127';

const GENERIC_EXCLUDE = ['', 'unknown', 'null', 'undefined'];
const BRANDS_EXCLUDE = GENERIC_EXCLUDE.concat(['{advertiserId}']);
const VERTICALS_EXCLUDE = GENERIC_EXCLUDE.concat(['{verticalId}']);
const FORMATS_EXCLUDE = GENERIC_EXCLUDE.concat(['hang-time-desktop-v2']);

const FEATURES_OPTIONS = [
  { value: 'display', label: 'Display only' },
  { value: 'video', label: 'Video only' },
];

// create a map of ggAdvertisers id to name
const ggVerticalsMap = ggVerticals.reduce((acc, ggVertical) => {
  acc[ggVertical.id] = ggVertical.name;
  return acc;
}, {});

const sortByLowerCase = (a, b) => {
  const la = a.toLowerCase();
  const lb = b.toLowerCase();
  return la.localeCompare(lb);
};

// Expand property names and map to human readable values
const mapMetaItemProperties = (item) => {
  const { id, a, s, b, p, c, n, f, h, u, v } = item;
  const format = f ? f : 'hang-time';

  const brand = isNaN(b) ? b : ggAdvertisersMap[b];

  return {
    id,
    adBuyId: a,
    aggregatedSessionSize: s,
    brand,
    campaign: p,
    creative: c,
    favCount: n,
    format,
    containsVideo: h,
    updated: u,
    vertical: isNaN(v) ? v : ggVerticalsMap[v],
  };
};

const fetchHomepageData = async () => {
  try {
    const response = await fetch(META_V2_URL);
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    // if this fetch gets too slow, could pull in the blacklist from the store and
    // pass through to fetchHomepageData as a param? 👇
    const BUILD_IDS_EXCLUDE = await fetchCreativesBlacklist();
    const data = await response.json();
    const mergedData = data
      .map((meta) => mapMetaItemProperties(meta))
      .filter((meta) => {
        // exclude items with empty or excluded values
        if (
          !meta ||
          FORMATS_EXCLUDE.includes(meta.format) ||
          VERTICALS_EXCLUDE.includes(meta.vertical) ||
          BRANDS_EXCLUDE.includes(meta.brand) ||
          BUILD_IDS_EXCLUDE.includes(meta.id)
        ) {
          return false;
        }
        return true;
      });

    // sort by updated date
    mergedData.sort(
      (a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()
    );

    const optionsAsArray = mergedData.reduce(
      (acc, item) => {
        const brand = item.brand?.trim() || '';
        const format = item.format?.trim() || '';
        const vertical = item.vertical?.trim() || '';

        if (!acc.brands.includes(brand) && !!brand) {
          acc.brands.push(brand);
        }
        if (!acc.verticals.includes(vertical) && !!vertical) {
          acc.verticals.push(vertical);
        }
        if (!acc.formats.includes(format) && !!format) {
          acc.formats.push(format);
        }
        return acc;
      },
      { brands: [], verticals: [], formats: [] }
    );

    const newOptions = {
      brands: optionsAsArray.brands
        .sort(sortByLowerCase)
        .map((brand) => ({ value: brand, label: brand })),
      verticals: optionsAsArray.verticals
        .sort(sortByLowerCase)
        .map((vertical) => ({ value: vertical, label: vertical })),
      formats: optionsAsArray.formats
        .sort(sortByLowerCase)
        .map((format) => ({ value: format, label: format })),
      features: FEATURES_OPTIONS,
    };
    console.log(newOptions);
    return { data: mergedData, options: newOptions };
  } catch (error) {
    console.log(error);
  }

  return { data: [], options: [] };
};

export default fetchHomepageData;
