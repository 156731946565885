@import "colors.scss";

.Modal {
  position: fixed;
  z-index: 9999;
  background-color: $white;
  width: 70%;
  min-height: 30vh;
  border-radius: 30px;
  box-shadow: $shadow50med;
  padding: 30px;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  font-size: 15px;
  flex-direction: column;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
}

@media (min-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}
