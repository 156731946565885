import { ChakraTheme } from '@chakra-ui/react';

export const components: ChakraTheme['components'] = {
  // overwrite the tab component so that text stays on one line
  Button: {
    baseStyle: {
      fontWeight: 'medium',
    },
  },
  Input: {
    baseStyle: {
      fontWeight: 'medium',
      borderRadius: 'md',
      borderColor: 'border-default-muted',
      _hover: {
        borderColor: 'border-default',
      },
      _focus: {
        borderColor: 'border-default-focus',
      },
    },
  },
  Select: {
    baseStyle: {
      fontWeight: 'medium',
      borderRadius: 'md',
      borderColor: 'border-default-muted',
      _hover: {
        borderColor: 'border-default',
      },
      _focus: {
        borderColor: 'border-default-focus',
      },
    },
  },
  Tabs: {
    baseStyle: {
      tab: {
        textOverflow: 'ellipsis', // stop tabs from wrapping
        whiteSpace: 'nowrap', // which is never what we want
        fontWeight: 'medium',
      },
    },
  },
};
