// the amount of progress above and below the ad to include in the recording
// eg: a buffer of 25 means it will start recording when the ad is 75% in view at the bottom
// and stop when it is again 75% in view at the top
const PROGRESS_BUFFER = 0;
let lastTime;
let isFirstScrollEvent = true;

const isInView = function (progress) {
  return progress >= 0 && progress <= 100;
};

// Adds a new entry to scrollInfo (where applicable) and returns a new scrollInfo.
export const updateScrollInfo = function (prevScrollInfo, progress) {
  const date = new Date();
  const currentTime = date.getTime();
  const timeDelta = currentTime - lastTime;

  // can't start recording info until at least the 2nd scroll event as we need the time delta
  if (isFirstScrollEvent) {
    lastTime = currentTime;
    isFirstScrollEvent = false;
    return prevScrollInfo;
  }

  if (prevScrollInfo.length > 0) {
    const prevProgress = prevScrollInfo[prevScrollInfo.length - 1].progress;

    // don't update if progress has changed only a small amount
    if (Math.abs(progress - prevProgress) < 1) {
      return prevScrollInfo;
    }

    // don't update if prevProgress and current progress are both outside of the ad range
    if (!isInView(prevProgress) && !isInView(progress)) {
      return prevScrollInfo;
    }
  }

  const newScrollEntry = {
    progress,
    timeDelta,
  };

  lastTime = currentTime;

  return [...prevScrollInfo, newScrollEntry];
};

// Transforms scrollInfo into idleTime,
// resulting data can be passed directly into the Graph component.
export const getIdleTimeData = function (scrollInfo) {
  const data = [];

  for (let i = 1; i < scrollInfo.length; i++) {
    // const prev = scrollInfo[i - 1];
    const curr = scrollInfo[i];

    const { timeDelta, progress } = curr;
    if (timeDelta > 500) {
      data.push({ idleTime: timeDelta, progress });
    }
  }

  return data;
};

export const getDirection = function (from, to) {
  if (from.progress === to.progress) {
    return "idle";
  }

  return to.progress > from.progress ? "down" : "up";
};

// Removes the first sequence from the scroll info
// then returns both the sequence and remaining scroll info.
// eg: [newSequence, remainingScrollInfo]
// scrollInfo must have at least 2 entries
export const getNextSequence = function (scrollInfo) {
  // create the initial single segment sequence
  const sequence = {
    startProgress: scrollInfo[0].progress,
    endProgress: scrollInfo[1].progress,
    direction: getDirection(scrollInfo[0], scrollInfo[1]),
    duration: scrollInfo[1].timeDelta,
  };

  // continue adding to the sequence from the 2nd segment on
  for (let to = 2; to < scrollInfo.length; to++) {
    const prev = scrollInfo[to - 1];
    const curr = scrollInfo[to];
    const direction = getDirection(prev, curr);

    // end the sequence if the direction changes
    if (direction !== sequence.direction) {
      // subtract 1 from the slice position because the end of this sequence will become the start of the next sequence
      const remainingScrollInfo = scrollInfo.slice(to - 1);
      return [sequence, remainingScrollInfo];
    }

    // sequence gets a little longer
    sequence.endProgress = curr.progress;
    sequence.duration += curr.timeDelta;
  }

  // if we make it to the end of scrollInfo without a direction change
  // then return the final sequence
  return [sequence, []];
};

// returns an array of sequences from scrollInfo
// eg: [{startProgress: 0, endProgress: 2, direction: "down", duration: 150}]
export const getSequences = function (scrollInfo) {
  let sequences = [];
  let remainingScrollInfo = [...scrollInfo];

  while (remainingScrollInfo.length > 1) {
    const [sequence, newScrollInfo] = getNextSequence(remainingScrollInfo);
    sequences.push(sequence);
    remainingScrollInfo = newScrollInfo;
  }

  return sequences;
};

const getOverlaps = function (sequences, mode, sessionSize) {
  let overlaps = new Array(101).fill(0);

  for (const sequence of sequences) {
    const startTimeline =
      mode === "timeline" ? sequence.startTimeline : sequence.startProgress;
    const endTimeline =
      mode === "timeline" ? sequence.endTimeline : sequence.endProgress;
    const duration = sequence.duration;
    const start = Math.floor(Math.min(startTimeline, endTimeline));
    const end = Math.floor(Math.max(startTimeline, endTimeline));
    for (let i = start + PROGRESS_BUFFER; i <= end + PROGRESS_BUFFER; i += 1) {
      if (
        duration / Math.abs((endTimeline - startTimeline).toFixed(2)) ===
        Infinity
      ) {
        console.error(
          "infinate",
          duration,
          Math.abs(Math.floor(endTimeline - startTimeline))
        );
      }
      if (i >= 0 && i <= 100 && end - start !== 0) {
        overlaps[i] += 1;
      }
    }
  }
  return overlaps.map((overlap) => +(overlap / sessionSize).toFixed(5));
};

export const getOverlapScrollsData = function (sequences, mode, sessionSize) {
  const overlaps = getOverlaps(sequences, mode, sessionSize);
  return overlaps.map((value, index) => ({
    timeline: index - PROGRESS_BUFFER,
    playfulness: value,
  }));
};

// TODO - remove this function. It's not used anywhere.
// normalize data from a 0 to 100 scale
export const normalizeData = (data, startProgress, endProgress, dataKey) => {
  const dataClone = [...data].slice(startProgress, endProgress);
  const max = dataClone.reduce((prev, current) =>
    prev[dataKey] > current[dataKey] ? prev : current
  )[dataKey];

  const min = dataClone.reduce(function (prev, current) {
    return prev[dataKey] < current[dataKey] ? prev : current;
  })[dataKey];
  const gap = max - min;
  return data.map((value) => {
    const newValue = { ...value };
    if (value[dataKey] >= min && value[dataKey] <= max) {
      newValue[dataKey] = (value[dataKey] - min) * (100 / gap);
    } else if (value[dataKey] > max) {
      newValue[dataKey] = 100;
    } else {
      newValue[dataKey] = 0;
    }
    return newValue;
  });
};
