import { GraphLegends } from "@components/GraphLegends/GraphLegends";
import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { ScrubLabels } from "../Charts/ScrubLabels";
import { InfoTooltipText } from "../Chakra/InfoTooltipText";
import { Flex } from "@chakra-ui/react";

const normalizeBaselineAndActual = (
  incomingData,
  showBaseline,
  startProgress,
  endProgress
) => {
  const dataClone = [...incomingData].slice(startProgress, endProgress + 1);
  const sequenceMax = dataClone.reduce((prev, current) =>
    prev.playfulness > current.playfulness ? prev : current
  ).playfulness;

  const sequenceMin = dataClone.reduce((prev, current) =>
    prev.playfulness < current.playfulness ? prev : current
  ).playfulness;

  const playfulnessBMax = dataClone[0].playfulnessB
    ? dataClone.reduce((prev, current) =>
        prev.playfulnessB > current.playfulnessB ? prev : current
      ).playfulnessB
    : 0;

  const playfulnessBMin = dataClone[0].playfulnessB
    ? dataClone.reduce((prev, current) =>
        prev.playfulness < current.playfulness ? prev : current
      ).playfulnessB
    : 1;

  const baselineMax = dataClone.reduce((prev, current) =>
    prev.scrubBaseline > current.scrubBaseline ? prev : current
  ).scrubBaseline;

  const baselineMin = dataClone.reduce((prev, current) =>
    prev.scrubBaseline < current.scrubBaseline ? prev : current
  ).scrubBaseline;

  let max = Math.max(sequenceMax, baselineMax, playfulnessBMax);
  let min = Math.min(sequenceMin, baselineMin, playfulnessBMin);

  if (!showBaseline) {
    max = sequenceMax;
    min = sequenceMin;
  }

  const gap = max - min;

  return incomingData.map((value) => {
    const newValue = { ...value };

    const normalizeThese = (
      initialObject,
      newObject,
      keys,
      gap,
      dataLength
    ) => {
      keys.forEach((key) => {
        if (initialObject[key] >= min && initialObject[key] <= max) {
          newObject[key] = (initialObject[key] - min) * (dataLength / gap);
        } else if (initialObject[key] > max) {
          newObject[key] = 100;
        } else {
          newObject[key] = 0;
        }
      });
    };

    normalizeThese(
      value,
      newValue,
      ["playfulness", "playfulnessB", "scrubBaseline"],
      gap,
      incomingData.length
    );

    return newValue;
  });
};

const ScrubChart = ({
  adProgress,
  data,
  startProgress,
  endProgress,
  colors,
  isABGraph = false,
  showBaseline = true,
  demoMode,
  format,
  children,
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!data.length) return;
    setChartData(
      normalizeBaselineAndActual(
        data,
        showBaseline,
        startProgress,
        endProgress,
        demoMode
      )
    );
  }, [data]);

  const queryParams = new URLSearchParams(location.search);

  // -----------------------------------------------------
  // should be in presentation mode?
  const isPresentationMode = queryParams.get("layoutMode") === "presentation";
  // this is the URL param that is passed thorugh when gif-generator
  // is viewing the page to create a gif for the slide deck
  // -----------------------------------------------------

  return (
    <>
      <InfoTooltipText
        text="Scrub Engagement"
        visibility={isPresentationMode ? "hidden" : "visible"}
        tooltipText={
          "We track when users pause, backtrack and play with the ad to understand which parts drive the most attention."
        }
      />
      <ResponsiveContainer
        width={"100%"}
        maxHeight={isPresentationMode ? "600px" : "32vh"}
      >
        <AreaChart
          data={chartData}
          margin={
            isPresentationMode
              ? { top: 40, right: 0, left: 0, bottom: 80 }
              : { top: 0, right: 0, left: 50, bottom: 10 }
          }
        >
          <CartesianGrid stroke="#e8e8e8" strokeDasharray="3 3" />
          <XAxis
            dataKey="timeline"
            type="number"
            tickLine={true}
            domain={[startProgress, endProgress]}
            allowDataOverflow={true}
            axisLine={false}
            ticks={["Scroll in", "Scroll out"]}
            hide={isPresentationMode}
          ></XAxis>

          <YAxis axisLine={false} tickLine={false} hide></YAxis>
          <Area
            type="monotone"
            dataKey="playfulness"
            stroke={isABGraph ? colors[0] || "grey" : `url(#linear)`}
            strokeWidth={4}
            fill="rgba(255,255,255,0)"
            isAnimationActive={false}
          />
          {isABGraph && (
            <Area
              type="monotone"
              dataKey="playfulnessB"
              stroke={colors[1] || "grey"}
              strokeWidth={4}
              fill="rgba(255,255,255,0)"
              isAnimationActive={false}
            />
          )}
          {showBaseline && !isABGraph ? (
            <Area
              type="monotone"
              dataKey="scrubBaseline"
              stroke="#c5c5c5"
              strokeWidth={2}
              fillOpacity={0.1}
              fill="#c5c5c5"
              isAnimationActive={false}
            />
          ) : null}
          <ReferenceLine
            x={Math.round(adProgress)}
            fill="#000"
            stroke="rgba(0,0,0,0.15)"
            strokeWidth="4px"
            isFront={true}
          />
          <defs>
            <linearGradient id="linear">
              <stop offset="25%" stopColor="#00CE7C" />
              <stop offset="100%" stopColor="#4AC1E0" />
            </linearGradient>
          </defs>
        </AreaChart>
      </ResponsiveContainer>

      <ScrubLabels display={isPresentationMode ? "none" : "flex"} />
      {children}
      {/* 
        For now turning this off on AB
        In the future not sure where best place to put this
      */}
      {!isABGraph && (
        <Flex
          w="100%"
          justifyContent={"center"}
          display={isPresentationMode ? "none" : "flex"}
        >
          <Flex
            justifyContent={"space-evenly"}
            width="400px"
            padding="16px 0px"
            border={"1px solid"}
            borderColor={"blackAlpha.300"}
            borderRadius={"10px"}
          >
            <GraphLegends.Actual />
            <GraphLegends.Baseline />
          </Flex>
        </Flex>
      )}
      {isABGraph && (
        <Flex
          w="100%"
          justifyContent={"center"}
          display={isPresentationMode ? "none" : "flex"}
        >
          <Flex
            justifyContent={"space-evenly"}
            width="400px"
            padding="16px 0px"
            border={"1px solid"}
            borderColor={"blackAlpha.300"}
            borderRadius={"10px"}
          >
            <GraphLegends.Old />
            <GraphLegends.New />
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default ScrubChart;
