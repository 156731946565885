import flatten from "flat";

//spec borrowed from https://theme-ui.com/theme-spec
export const cat = {
  borderStyles: ["solid"],
  borderWidths: ["2px"],
  space: ["0", "4px", "8px", "16px", "32px", "64px"],

  fonts: [
    "TTNorms,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'",
  ],
  radii: ["4px"],
  fontSizes: ["12px", "13.5px", "16.5px", "32.5px", "38px"],
  transitions: ["all 150ms ease-out"],
  fontWeights: [400, 500, 600],
  lineHeights: [1.05, 1.23, 1.45, 1.78, 2],
  letterSpacings: ["-0.002em", "-0.004em", "-0.008em"],
  shadows: {
    low: "0px 0px 4px #00000033",
    med: "0px 0px 8px #000000AA",
    high: "0px 0px 16px #000000AA",
    soft: `0 1px 1px rgb(0 0 0 / 5%), 0 2px 2px rgb(0 0 0 / 5%), 0 4px 4px rgb(0 0 0 / 5%), 0 8px 8px rgb(0 0 0 / 5%), 0 16px 16px rgb(0 0 0 / 5%), 0 32px 32px rgb(0 0 0 / 5%)`
  },
  colors: {
    text: "#202020",
    primary: "#00CE7C",
    secondary: "#4AC1E0",
    muted: "#DBDBD9",
    brandGradient:
      "linear-gradient(5.09deg, #00CE7C -4.18%, #4AC1E0 32.84%, var(--colors-primary) 85.79%)",
    neutral: ["#202020", "#6A6868", "#A4A2A2", "#DBDBD9", "#F0F0EE", "#FFFFFF"],
    chart: ["#00CE7C", "#4AC1E0", "#00CE7C", "#4AC1E0", "#00CE7C", "#4AC1E0"],
    trafficlight: ["#F47777", "#88E196", "var(--colors-chart-0)"],
    modes: {
      dark: {
        text: "#FFFFFF",
        neutral: ["#3e474a", "#252B2C"],
      },
    },
  },

  text: {
    heading1: {
      fontWeight: "var(--fontWeights-2)",
      lineHeight: "var(--lineHeights-0)",
      letterSpacing: "var(--letterSpacings-2)",
      fontSize: "var(--fontSizes-4)",
    },
    heading2: {
      fontWeight: "var(--fontWeights-2)",
      lineHeight: "var(--lineHeights-1)",
      letterSpacing: "var(--letterSpacings-2)",
      fontSize: "var(--fontSizes-3)",
    },
    baseline: {
      fontWeight: "var(--fontWeights-0)",
      lineHeight: "var(--lineHeights-2)",
      letterSpacing: "var(--letterSpacings-1)",
      fontSize: "var(--fontSizes-2)",
    },
    body01: {
      fontWeight: "var(--fontWeights-0)",
      lineHeight: "var(--lineHeights-3)",
      letterSpacing: "var(--letterSpacings-0)",
      fontSize: "var(--fontSizes-1)",
    },
    body02: {
      fontWeight: "var(--fontWeights-0)",
      lineHeight: "var(--lineHeights-4)",
      letterSpacing: "var(--letterSpacings-0)",
      fontSize: "var(--fontSizes-0)",
    },
  },
  //We only define colours here for our components
  spinnyCat: {
    outline: "var(--colors-chart-2)",
    fills: ["var(--colors-chart-3)", "var(--colors-neutral-5)"],
  },
  buttons: {
    primary: {
      background: "var(--colors-primary)",
      text: "var(--colors-neutral-5)",
      border: "var(--colors-primary)",
      hover: {
        background: "var(--colors-secondary)",
        border: "var(--colors-secondary)",
      },
      disabled: {
        background: "var(--colors-muted)",
        border: "var(--colors-muted)",
      },
    },
    secondary: {
      background: "transparent",
      text: "var(--colors-primary)",
      border: "var(--colors-primary)",
      hover: {
        text: "var(--colors-secondary)",
        border: "var(--colors-secondary)",
      },
      disabled: {
        text: "var(--colors-muted)",
        border: "var(--colors-muted)",
      },
    },
    tertiary: {
      background: "transparent",
      text: "var(--colors-neutral-0)",
      border: "var(--borderWidths-0) transparent var(--borderStyles-0)",
      hover: {
        text: "var(--colors-neutral-0)",
        border: "var(--colors-neutral-4)",
        background: "var(--colors-neutral-4)",
      },
      disabled: {
        text: "var(--colors-neutral-2)",
        border: "transparent",
      },
    },
  },

  snackBar: {
    success: "var(--colors-trafficlight-1)",
    error: "var(--colors-trafficlight-0)",
    info: "var(--colors-neutral-4)",
    warning: "var(--colors-trafficlight-2)",
  },
};

const theme = cat;

export const cssVars = Object.entries(
  flatten(theme, {
    delimiter: "-",
  })
).reduce((acc, [key, value]) => {
  acc += `--${key}: ${value};
      `;
  return acc;
}, "");
