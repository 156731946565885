import { Text, Flex, IconButton } from "@chakra-ui/react";
import { InputSlider } from "../../components/Chakra/Slider";
import { playHistory } from "../../util/helpers";
import { BiPlay } from "react-icons/bi";
import { CustomTooltip } from "../../components/UI/Tooltip/CustomTooltip";
export const Control = ({
  index,
  setIndex,
  history,
  showButton = true,
  width = "200px",
  showTrack = true,
  Chart = null,
  active = true,
}) => {
  return (
    <Flex direction="row" alignItems={"center"}>
      <Text mr={4} fontSize="sm">
        Optimisation Start
      </Text>

      <Flex position={"relative"}>
        {Chart && Chart}
        <InputSlider
          isDisabled={!active}
          showTrack={showTrack}
          colorScheme="gray"
          width={width}
          min={0}
          max={history.length - 1}
          value={index || 0}
          onChange={(val) => {
            setIndex(val);
          }}
          defaultValue={0}
        />
      </Flex>

      <Text mx={4} fontSize="sm">
        End
      </Text>
      {showButton && (
        <IconButton
          isDisabled={!active}
          size="sm"
          margin="0px 10px 0px 10px"
          borderRadius="full"
          icon={<BiPlay />}
          onClick={() => {
            playHistory(history, setIndex);
          }}
        />
      )}
      <CustomTooltip label="See how performance progressed during the optimisation period." />
    </Flex>
  );
};
