import { getPercentageChange } from "../../util/helpers";
import { valueToColor } from "../../util/helpers";
import { Text } from "@chakra-ui/react";

export const PercentChangeValue = ({ value, startValue, size = "xs" }) => {
  const percentageChange = Math.round(getPercentageChange(value, startValue));
  const color = valueToColor(percentageChange, "dark");
  
  const formatted =
    value > startValue ? `+${percentageChange}%` : `${percentageChange}%`;
  return (
    <Text fontSize={size} color={color}>
      {formatted}
    </Text>
  );
};
