import InlineModal from "@components/UI/Modal/InlineModal";
import { withAlertsRender } from "@controllers/Portals";
import styled from "@emotion/styled";
import {
  faClipboardList,
  faShare,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroup, FalafelLoader, Input, SnackBar } from "@ui-lib";
import { Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { logAnalytics } from "@utils/analyticsUtil";
const SnackAlert = withAlertsRender(SnackBar);

const ShareRoot = styled.div`
  position: relative;
`;

const Share = ({
  url,
  description,
  content,
  onClick,
  buildIDs,
  isLoading = false,
  error,
  leftIcon = faShare,
}) => {
  const [showShare, setShowShare] = useState(false);
  const [showCopyConfirm, setShowCopyConfirm] = useState(false);

  const handleShareModal = (e) => {
    onClick && onClick(e);
    setShowShare(!showShare);
  };

  const handleCopy = () => {
    logAnalytics.featureShareInteraction(buildIDs, "copy_link");
    navigator.clipboard.writeText(url);
    setShowCopyConfirm(true);
    setTimeout(() => {
      setShowShare(false);
      setShowCopyConfirm(false);
    }, 2000);
  };

  const toNewTab = () => {
    logAnalytics.featureShareInteraction(buildIDs, "open_in_new_tab");
    window.open(url, "_blank").focus();
  };

  const handleClose = () => {
    setShowShare(false);
  };

  useEffect(() => {
    if (error) {
      logAnalytics.errorGeneral(error, window.location.href);
      setShowShare(false);
    }
  }, [error]);

  useEffect(() => {
    if (showShare) {
      logAnalytics.featureShareOpened(buildIDs);
    }
  }, [showShare]);

  return (
    <ShareRoot>
      <Button
        size="sm"
        onClick={handleShareModal}
        leftIcon={<FontAwesomeIcon icon={leftIcon} />}
      >
        {content}
      </Button>

      <InlineModal
        title={description}
        show={showShare}
        handleClose={handleClose}
      >
        {url && (
          <Input
            type="text"
            value={url}
            readOnly
            onClick={(e) => e.target.select()}
          />
        )}

        <div>
          {error && (
            <SnackAlert title="Something wrong happened" status="error" />
          )}
          {showCopyConfirm ? (
            <SnackAlert title="Copied" status="success" />
          ) : !isLoading ? (
            <ButtonGroup>
              <Button
                size="sm"
                variant="primary"
                onClick={handleCopy}
                leftIcon={<FontAwesomeIcon icon={faClipboardList} />}
              >
                Copy link
              </Button>
              <Button
                size="sm"
                variant="secondary"
                onClick={toNewTab}
                leftIcon={<FontAwesomeIcon icon={faArrowRight} />}
              >
                Open in new tab
              </Button>
            </ButtonGroup>
          ) : (
            <FalafelLoader />
          )}
        </div>
      </InlineModal>
    </ShareRoot>
  );
};

export default Share;
