// For hiding creatives
// Currently, creatives here are hidden from the homepage and the creative viewer.

import firebase from "@services/firestore";

export const fetchCreativesBlacklist = async () => {
  try {
    const docRef = firebase
      .firestore()
      .collection("Creatives-Blacklist")
      .doc("current");

    const doc = await docRef.get();
    const data = doc.data();

    if (!doc.exists || !data || !data.creatives) {
      console.error("No such document in Creatives-Blacklist!");
      return [];
    }

    // lowercase all the strings in the array
    const result = data.creatives.map((str) => str.toLowerCase());
    return result;
  } catch (error) {
    console.error("Error fetching creatives blacklist:", error);
    return [];
  }
};
