// Should be moved to Firebase?
// M - eventually
export const userMetadata = [
  // ----------------
  // CPT
  {
    email: "pat.mifsud@playgroundxyz.com",
    demoCreativeID: "satobo",
    team: "CPT",
  },
  {
    email: "mathieu.mence@playgroundxyz.com",
    demoCreativeID: "retijo",
    team: "CPT",
  },
  {
    email: "steve.nash@playgroundxyz.com",
    demoCreativeID: "cibire",
    team: "CPT",
  },
  {
    email: "anara.davletaliyeva@playgroundxyz.com",
    demoCreativeID: "mogema",
    team: "CPT",
  },
  {
    email: "andrea.agathocleous@playgroundxyz.com",
    demoCreativeID: "fihisu",
    team: "CPT",
  },
  {
    email: "ryman.white@playgroundxyz.com",
    team: "CPT",
    demoCreativeID: "bitacu",
  },
  // ----------------
  // SALES
  {
    email: "david.ditonto@playgroundxyz.com",
    demoCreativeID: "xonivu",
    team: "sales",
  },
  {
    email: "georgia.falloon@playgroundxyz.com",
    demoCreativeID: "wowixa",
    team: "sales",
  },
  {
    email: "jake.selley@playgroundxyz.com",
    demoCreativeID: "kowile",
    team: "sales",
  },
  {
    email: "karan.kent@playgroundxyz.com",
    demoCreativeID: "fawidi",
    team: "sales",
  },
  {
    email: "krystan.irvine@playgroundxyz.com",
    demoCreativeID: "zipiqa",
    team: "sales",
  },
  {
    email: "alex.hill@playgroundxyz.com",
    demoCreativeID: "fumonu",
    team: "sales",
  },
  {
    email: "amber.mumford@playgroundxyz.com",
    demoCreativeID: "savene",
    team: "sales",
  },
  {
    email: "amelia.lyall@playgroundxyz.com",
    demoCreativeID: "soxowu",
    team: "sales",
  },
  {
    email: "ben.dimond@playgroundxyz.com",
    demoCreativeID: "hibete",
    team: "sales",
  },
  {
    email: "bernard.tam@playgroundxyz.com",
    demoCreativeID: "gaqequ",
    team: "sales",
  },
  {
    email: "james.higson@playgroundxyz.com",
    demoCreativeID: "kiduwu",
    team: "sales",
  },
  {
    email: "jess.aylett@playgroundxyz.com",
    demoCreativeID: "jekefu",
    team: "sales",
  },
  {
    email: "michael.buhagiar@playgroundxyz.com",
    demoCreativeID: "yadujo",
    team: "sales",
  },
  {
    email: "rob.hall@playgroundxyz.com",
    demoCreativeID: "rejozu",
    team: "sales",
  },
  {
    email: "billy.gittins@playgroundxyz.com",
    demoCreativeID: "risocu",
    team: "sales",
  },
  {
    email: "tarlyn.mcstay@playgroundxyz.com",
    demoCreativeID: "nepeju",
    team: "sales",
  },
  {
    email: "sorrel.osborne@playgroundxyz.com",
    demoCreativeID: "jicavo",
    team: "sales",
  },
  {
    email: "kevin.smyth@playgroundxyz.com",
    demoCreativeID: "sivigo",
    team: "sales",
  },
  {
    email: "ben.swaik@playgroundxyz.com",
    demoCreativeID: "gumowo",
    team: "sales",
  },

  // ----------------
  // DESIGN
  {
    email: "jesse.whitmore@playgroundxyz.com",
    demoCreativeID: "wohedo",
    team: "design",
  },
  {
    email: "kristjan.rattus@playgroundxyz.com",
    demoCreativeID: "xiyubi",
    team: "design",
  },
  {
    email: "kristjanfreelance@gmail.com",
    demoCreativeID: "xiyubi",
    team: "design",
  },
  {
    email: "nick.mudge@playgroundxyz.com",
    demoCreativeID: "dapeqa",
    team: "design",
  },
  {
    email: "annie.lemaire-brooks@playgroundxyz.com",
    demoCreativeID: "xogige",
    team: "design",
  },
  {
    email: "suyin.ng@playgroundxyz.com",
    demoCreativeID: "bugiqa",
    team: "design",
  },
  {
    email: "marc.gundesen@playgroundxyz.com",
    demoCreativeID: "hosohu",
    team: "design",
  },
  {
    email: "vinko.kraljevic@playgroundxyz.com",
    demoCreativeID: "vizigo",
    team: "design",
  },
  {
    email: "jean-louis.lamyanfoon@playgroundxyz.com",
    team: "design",
  },

  // Misc staff
  {
    email: "madeleine.navin@playgroundxyz.com",
    team: "staff",
  },
  {
    email: "yohei.naito@gumgum.com",
    team: "staff",
  },
  {
    email: "ehope@gumgum.com",
    team: "staff",
  },
];
