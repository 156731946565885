import { useEffect, useRef, useState } from "react";
export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      const {isVisible, isIntersecting} = entry;
      setIntersecting(isIntersecting);
    }, { threshold: 0.9 });

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
