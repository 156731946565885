import {
  faBalanceScaleRight,
  faLink,
  faPoll,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from "@services/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import CATRouselCard from "../../components/CATRouselCard/CATRouselCard";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import CATComparisonSelector from "../../composites/CATComparisonSelector/CATComparisonSelector";
import { CATRouselViewerStore } from "../../store/CATRouselViewerStore";
import { getOverlapScrollsData } from "../../util/sequencesProcessor";
import styles from "./CATRouselViewer.module.scss";

const CATRouselViewer = (props) => {
  const [recommendations, setRecommendations] = useState([]);
  const [campaignName, setCampaignName] = useState({});
  const [scriptVersion, setScriptVersion] = useState("");

  const [topProductsRange, setTopProductsRange] = useState(7);

  const [currentSection, setCurrentSection] = useState("topProducts");

  const topProductsRef = useRef(null);
  const catComparisonRef = useRef(null);

  const [currentScrollData, setCurrentScrollData] = useState([]);

  const comparisonCreative = CATRouselViewerStore(
    (state) => state.comparisonCreativeID
  );
  const setComparisonCreativeID = CATRouselViewerStore(
    (state) => state.setComparisonCreativeID
  );

  const loadCreativeSequences = CATRouselViewerStore(
    (state) => state.loadCreativeSequences
  );

  const sequences = CATRouselViewerStore((state) => state.sequences);
  const sessionSize = CATRouselViewerStore((state) => state.sessionSize);
  const comparisonSequences = CATRouselViewerStore(
    (state) => state.comparisonSequences
  );
  const comparisonSessionSize = CATRouselViewerStore(
    (state) => state.comparisonSessionSize
  );

  const [creativeData, loadingCreativeData, errorCreativeData] =
    useDocumentDataOnce(
      firebase
        .firestore()
        .collection("CAT-Rousel")
        .doc(props.match.params.buildID)
    );

  const [CATData, loadingCATData, errorCATData] = useDocumentDataOnce(
    firebase.firestore().collection("CAT3").doc(props.match.params.buildID)
  );

  useEffect(() => {
    if (!loadingCreativeData && !errorCreativeData) {
      setComparisonCreativeID(creativeData.comparisonCreative);
      combineRecommendationsFromDifferentDomains(
        creativeData.currentRecommendation.scrubBasedRecommendation
      );
    }
  }, [
    creativeData,
    errorCreativeData,
    loadingCreativeData,
    setComparisonCreativeID,
  ]);

  useEffect(() => {
    if (!loadingCATData && !errorCATData) {
      setCampaignName({
        brand: CATData.brandNames,
        campaign: CATData.campaignNames,
      });
      setScriptVersion(CATData.scriptVersion);
    }
  }, [CATData, loadingCATData, errorCATData]);

  useEffect(() => {
    if (
      props.match.params.buildID &&
      !loadingCreativeData &&
      !errorCreativeData &&
      comparisonCreative
    ) {
      loadCreativeSequences(props.match.params.buildID, 0, "scrub", "original");
      loadCreativeSequences(comparisonCreative, 0, "scrub", "comparison");
    }
  }, [
    props.match.params.buildID,
    comparisonCreative,
    loadingCreativeData,
    errorCreativeData,
  ]);

  useEffect(() => {
    const originalData = getOverlapScrollsData(
      sequences,
      "timeline",
      sessionSize
    );
    const comparisonData = getOverlapScrollsData(
      comparisonSequences,
      "timeline",
      comparisonSessionSize
    );
    setCurrentScrollData(attachBaselineData(originalData, comparisonData));
  }, [comparisonSequences, sequences, sessionSize, comparisonSessionSize]);

  const attachBaselineData = (incomeData, comparisonData) => {
    return incomeData.map((data, i) => {
      return {
        ...data,
        dwellTimeBaseline: 0,
        scrubBaseline: comparisonData[i].playfulness,
      };
    });
  };
  const executeScroll = (targetRef) =>
    targetRef.current.scrollIntoView({ behavior: "smooth" });

  if (errorCreativeData) {
    console.error(errorCreativeData);
  }

  const showMoreHandler = () => {
    const newTopProductsRange = topProductsRange + 8;
    setTopProductsRange(newTopProductsRange);
  };

  const combineRecommendationsFromDifferentDomains = (recommendations) => {
    let recommendationsBySlot = [];
    for (const [key, value] of Object.entries(recommendations)) {
      value.forEach((individualRecommendations, index) => {
        if (recommendationsBySlot[index]) {
          recommendationsBySlot[index] = recommendationsBySlot[index].concat(
            individualRecommendations[`slot${index}`]
          );
        } else {
          recommendationsBySlot[index] = [
            ...individualRecommendations[`slot${index}`],
          ];
        }
        recommendationsBySlot[index].sort((a, b) => b.score - a.score);
      });
    }
    const recommendationsBySlotWithoutZeros = recommendationsBySlot.map(
      (slotRecommendation) => {
        return slotRecommendation.filter(
          (individualRecommendation) => individualRecommendation.score !== 0
        );
      }
    );
    // get the medium score for each slot
    // const slotsMediums = recommendationsBySlotWithoutZeros.map(
    //   (recommendationsPerSlot) => {
    //     const middle = Math.floor(recommendationsPerSlot.length / 2);
    //     return recommendationsPerSlot.length % 2 !== 0
    //       ? recommendationsPerSlot[middle].score
    //       : (recommendationsPerSlot[middle - 1].score +
    //           recommendationsPerSlot[middle].score) /
    //           2;
    //   },
    // );

    // get the mean score for each slot
    const slotsMean = recommendationsBySlotWithoutZeros.map(
      (recommendationsPerSlot, slotIndex) => {
        const totalScore = recommendationsPerSlot.reduce(
          (sum, accum) => sum + accum.score,
          0
        );
        return totalScore / recommendationsPerSlot.length;
      }
    );

    // calculate distances between each score and its relative slot's mean
    const recommendationsBySlotWithDistance =
      recommendationsBySlotWithoutZeros.map(
        (recommendationsPerSlot, slotIndex) => {
          return recommendationsPerSlot.map((individualRecommendation) => {
            individualRecommendation.distance =
              individualRecommendation.score - slotsMean[slotIndex];
            return individualRecommendation;
          });
        }
      );
    // flatten the array of recommendations per slot and sort them by distance to means
    const recommendationsBySlotWithDistanceFlattened =
      recommendationsBySlotWithDistance.flat().sort((a, b) => {
        return b.distance - a.distance;
      });

    // Zheng's note: from the line where recommendationsBySlotWithoutZeros is defined, there's nothing scientific about these.
    // It's my plain attempt to level the scores aggregated by the cloud function in this limited amount of time.
    // which uses the formula of:
    // score = scrub destination * individualScrubWeight + dwellTime  * individualDwellTimeWeight

    // combine duplicated items and average their scores
    const recommendationsWithoutDuplicates = [];
    recommendationsBySlotWithDistanceFlattened.forEach(function (
      recommendation
    ) {
      if (!this[recommendation.id]) {
        this[recommendation.id] = recommendation;
        this[recommendation.id].distance = [recommendation.distance];
        recommendationsWithoutDuplicates.push(this[recommendation.id]);
      } else {
        this[recommendation.id].distance.push(recommendation.distance);
      }
    },
    Object.create(null));

    // calculate average distance between each item
    const recommendationsWithoutDuplicatesAvg =
      recommendationsWithoutDuplicates.map((recommendation) => {
        recommendation.distance =
          recommendation.distance.reduce((a, b) => a + b, 0) /
          recommendation.distance.length;
        return recommendation;
      });
    setRecommendations(
      recommendationsWithoutDuplicatesAvg.sort((a, b) => {
        return b.distance - a.distance;
      })
    );
  };

  const renderEmptyComparisonContainer = () => {
    return (
      <div className={styles.catComparisonContainer}>
        <div className={styles.dummy}></div>
        <div className={styles.noMore}>
          {props.header
            ? "Please select the build id for it to compare to."
            : "Coming Soon..."}
        </div>
      </div>
    );
  };

  const normalizeBaselineAndActual = (incomingData) => {
    if (incomingData.length) {
      const dataClone = [...incomingData].slice(30, 65);
      const sequenceMax = dataClone.reduce((prev, current) =>
        prev.playfulness > current.playfulness ? prev : current
      ).playfulness;

      const sequenceMin = dataClone.reduce(function (prev, current) {
        return prev.playfulness < current.playfulness ? prev : current;
      }).playfulness;

      const baselineMax = dataClone.reduce((prev, current) =>
        prev.scrubBaseline > current.scrubBaseline ? prev : current
      ).scrubBaseline;
      const baselineMin = dataClone.reduce(function (prev, current) {
        return prev.scrubBaseline < current.scrubBaseline ? prev : current;
      }).scrubBaseline;

      const max = Math.max(sequenceMax, baselineMax);
      const min = Math.min(sequenceMin, baselineMin);
      const gap = max - min;

      return incomingData.map((value) => {
        const newValue = { ...value };
        if (value.playfulness >= min && value.playfulness <= max) {
          newValue.playfulness =
            (value.playfulness - min) * (incomingData.length / gap);
        } else if (value.playfulness > max) {
          newValue.playfulness = 100;
        } else {
          newValue.playfulness = 0;
        }

        if (value.scrubBaseline >= min && value.scrubBaseline <= max) {
          newValue.scrubBaseline =
            (value.scrubBaseline - min) * (incomingData.length / gap);
        } else if (value.scrubBaseline > max) {
          newValue.scrubBaseline = 100;
        } else {
          newValue.scrubBaseline = 0;
        }
        return newValue;
      });
    } else {
      return [];
    }
  };

  const renderComparisonContainer = () => {
    return (
      <div className={styles.catComparisonContainer}>
        <ResponsiveContainer width="100%" aspect={16 / 8}>
          <AreaChart
            data={normalizeBaselineAndActual(currentScrollData)}
            margin={{ top: 20, right: 0, bottom: 25, left: 0 }}
            padding={{ top: 20, right: 0, bottom: 20, left: 0 }}
          >
            <CartesianGrid stroke="#e8e8e8" strokeDasharray="3 3" />
            <XAxis
              dataKey="timeline"
              type="number"
              tickLine={true}
              domain={[30, 65]}
              allowDataOverflow={true}
              axisLine={false}
              ticks={["Scroll in", "Scroll out"]}
            >
              <Label
                value="Scroll in"
                offset={0}
                position="insideLeft"
                style={{
                  fontSize: "100%",
                  fill: "#c5c5c5",
                }}
              />
              <Label
                value="Scroll out"
                offset={0}
                position="insideRight"
                style={{
                  fontSize: "100%",
                  fill: "#c5c5c5",
                }}
              />
              <Label
                value="Ad Progress"
                offset={-15}
                position="insideBottom"
                style={{
                  fontSize: "130%",
                  fill: "#c5c5c5",
                }}
              />
            </XAxis>
            <defs>
              <linearGradient id="linear">
                <stop offset="25%" stopColor="#00CE7C" />
                <stop offset="100%" stopColor="#4AC1E0" />
              </linearGradient>
            </defs>
            <YAxis axisLine={false} tickLine={false} hide></YAxis>
            <Area
              type="monotone"
              dataKey="scrubBaseline"
              stroke="#c5c5c5"
              strokeWidth={5}
              fillOpacity={0.5}
              fill="#c5c5c5"
              isAnimationActive={false}
            />
            <Area
              type="monotone"
              dataKey="playfulness"
              stroke="url(#linear)"
              strokeWidth={5}
              fill="rgba(255,255,255,0)"
              isAnimationActive={false}
            />
          </AreaChart>
        </ResponsiveContainer>
        <div className={styles.legends}>
          <div className={styles.actual}>
            <Link to={`/creative/${props.match.params.buildID}`}>
              <FontAwesomeIcon icon={faLink} />
            </Link>
            CAT-rousel Creative:
            <span></span>
          </div>
          <div className={styles.baseline}>
            <Link to={`/creative/${comparisonCreative}`}>
              <FontAwesomeIcon icon={faLink} />
            </Link>
            Standard Creative:
            <span></span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.catrouselViewer}>
      <div className={styles.campaignTitle}>
        <div className={styles.brand}>{campaignName.brand}</div>
        <div className={styles.campaign}>{campaignName.campaign}</div>
      </div>
      <div className={styles.sectionNavigation}>
        <div
          className={`${styles.sectionNavigationItem} ${
            currentSection === "topProducts" ? styles.active : null
          }`}
        >
          <FontAwesomeIcon
            icon={faPoll}
            onClick={() => {
              executeScroll(topProductsRef);
              setCurrentSection("topProducts");
            }}
          />
          <div className={styles.tooltipText}>Top Products</div>
        </div>
        <div
          className={`${styles.sectionNavigationItem} ${
            currentSection === "catComparison" ? styles.active : null
          }`}
        >
          <FontAwesomeIcon
            icon={faBalanceScaleRight}
            onClick={() => {
              executeScroll(catComparisonRef);
              setCurrentSection("catComparison");
            }}
          />
          <div className={styles.tooltipText}>CAT Comparison</div>
        </div>
      </div>
      {recommendations.length > 0 ? (
        <div className={styles.cardsContainer} ref={topProductsRef}>
          <div name="topProducts" className={styles.sectionTitle}>
            <FontAwesomeIcon icon={faPoll} />
            Top Products
          </div>
          {recommendations
            .slice(0, topProductsRange)
            .map((recommendation, index) => {
              return (
                <CATRouselCard
                  image={recommendation.url}
                  title={recommendation.id}
                  key={index}
                  ranking={index + 1}
                />
              );
            })}
          {topProductsRange < recommendations.length ? (
            <div className={styles.showMore} onClick={showMoreHandler}>
              Show More Products
            </div>
          ) : (
            <div className={styles.noMore}>No More Products</div>
          )}
        </div>
      ) : (
        <LoadingBar />
      )}
      <div className={styles.catComparison} ref={catComparisonRef}>
        <div name="catComparison" className={styles.sectionTitle}>
          <FontAwesomeIcon icon={faBalanceScaleRight} />
          CAT Comparison{" "}
          {props.header ? (
            <CATComparisonSelector
              buildID={props.match.params.buildID}
              scriptVersion={scriptVersion}
            />
          ) : null}
        </div>
        {comparisonCreative
          ? renderComparisonContainer()
          : renderEmptyComparisonContainer()}
      </div>
    </div>
  );
};

const CATRouselViewerWithRouter = withRouter(CATRouselViewer);

export default CATRouselViewerWithRouter;
