import styled from "@emotion/styled";
import React from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

export const RelativeScoreCompareChart = ({
  data = [],
  onItemHoverLeave = () => {},
  width = 250,
  height = 180,
}) => (
  <RelativeScoreCompareChartRoot>
    <ResponsiveContainer minWidth={width} minHeight={height}>
      <PieChart width={width} height={height}>
        <Legend
          iconSize={15}
          width={130}
          height={200}
          layout="vertical"
          verticalAlign="bottom"
          align="right"
        />
        <Pie
          data={data}
          cx={125}
          cy={180}
          startAngle={180}
          endAngle={0}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map(({ fill }, index) => (
            <Cell
              key={`cell-${index}`}
              fill={fill}
              onMouseEnter={() => onItemHoverLeave(data[index].name)}
              onMouseLeave={() => onItemHoverLeave()}
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  </RelativeScoreCompareChartRoot>
);

const RelativeScoreCompareChartRoot = styled.div`
  margin-left: 25px;
  .recharts-legend-wrapper {
    pointer-events: none;
    position: relative !important;
    margin-top: 40px;
    margin-left: 55px;
    ul {
      li {
        margin-top: 10px;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
`;
