import { UIStore } from "@stores/UIStore";
import { isVideoPlaying } from "@utils/creativeUtil";
import { useEffect, useMemo, useState } from "react";
import { Chart } from "./Chart";
import {
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { BiPlay, BiPause } from "react-icons/bi";
import { VideoFrames } from "./VideoFrames";
import { GUIStore } from "../../store/GUIStore";

const SingleVideoChart = ({
  showBaseline = false,
  iframeVideo = null,
  videoSequences,
  videoDropOffBaseline = Array.from({ length: 101 }, () => 0),
  byDurationVideoDropOffBaselines = null,
}) => {
  const iframeVideoProgress = UIStore((state) => state.iframeVideoProgress);
  const setIframeVideoProgress = UIStore(
    (state) => state.setIframeVideoProgress
  );
  const showLegacyVideoChart = GUIStore((state) => state.showLegacyVideoChart);
  const useLegacyBaseline = GUIStore((state) => state.useLegacyBaseline);
  const turnOffBaselineCompensation = GUIStore(
    (state) => state.turnOffBaselineCompensation
  );

  const [videoIsPlaying, setVideoIsPlaying] = useState(true);
  const videoRangeChange = (event) => {
    setIframeVideoProgress(event.target.value);
    let newTime = (iframeVideo.duration / 100) * event.target.value;
    if (newTime) {
      iframeVideo.currentTime = newTime;
    }
  };
  useEffect(() => {
    const isPlaying = isVideoPlaying(iframeVideo);
    setVideoIsPlaying(isPlaying);
  }, [iframeVideo, isVideoPlaying(iframeVideo)]);

  function percentChange(oldValue, newValue) {
    let change = newValue - oldValue;
    let percentChange = (change / oldValue) * 100;
    return percentChange;
  }

  const videoUrl = iframeVideo
    ? iframeVideo.getElementsByTagName("source")[0]?.src
    : null;
  const videoDuration = iframeVideo ? Math.floor(iframeVideo.duration) : null;
  const formatForChart = (data, key, baseline) => {
    if (!data[key].length) return;
    return data[key].map((value, index) => {
      return {
        inView: value,
        timeline: index,
        baseline: baseline[index],
        difference: percentChange(baseline[index], value), // value - baseline[index] 👈🏻 value to use for straight difference
      };
    });
  };

  function findClosest(target, arr, key) {
    return arr.reduce((prev, curr) =>
      Math.abs(curr[key] - target) < Math.abs(prev[key] - target) ? curr : prev
    );
  }

  let chartData = useMemo(() => {
    if (
      !byDurationVideoDropOffBaselines ||
      byDurationVideoDropOffBaselines.length === 0
    )
      return;

    // get closest baseline
    const videoBaseline = findClosest(
      videoDuration,
      byDurationVideoDropOffBaselines,
      "duration"
    );

    // here we're reducing the video baseline
    // the amount we reduce it by increases as the index increases slightly
    const modifiedDropoffBaseline = videoBaseline.videoDropOff.map(
      (value, index) => {
        const clampedIndex = Math.min(index, 50);
        const modifier = 1 - (clampedIndex / 100) * 0.2;
        return value * modifier;
      }
    );
    let baselineToFormat = modifiedDropoffBaseline;
    if (turnOffBaselineCompensation)
      baselineToFormat = videoBaseline.videoDropOff;
    if (useLegacyBaseline) baselineToFormat = videoDropOffBaseline;

    return formatForChart(
      videoSequences,
      "videoDropOffSequence",
      baselineToFormat // videoDropOffBaseline 👈🏻 old baseline array
    );
  }, [
    videoSequences["videoDropOffSequence"],
    byDurationVideoDropOffBaselines,
    useLegacyBaseline,
    turnOffBaselineCompensation,
  ]);

  // -----------------------------------------------------
  // should be in presentation mode?
  const queryParams = new URLSearchParams(location.search);
  const isPresentationMode = queryParams.get("layoutMode") === "presentation";
  // this is the URL param that is passed thorugh when gif-generator
  // is viewing the page to create a gif for the slide deck
  // console.log('SingleVideoChart.js: isPresentationMode:', isPresentationMode);
  return (
    <>
      <Chart
        id="SingleVideoChart"
        data={chartData}
        progress={iframeVideoProgress}
        showBaseline={showBaseline}
        videoDuration={videoDuration}
        videoChange={(val) => videoRangeChange({ target: { value: val } })}
        showLegacyVideoChart={showLegacyVideoChart}
      />
      <Flex
        marginLeft="10px"
        marginRight={"6px"}
        direction={"column"}
        marginTop="-66px"
      >
        <Flex direction="row">
          <IconButton
            opacity={isPresentationMode ? "0" : "1"}
            size="sm"
            isRound={true}
            variant={"ghost"}
            icon={
              videoIsPlaying ? <BiPause size="20px" /> : <BiPlay size="20px" />
            }
            onClick={() => {
              videoIsPlaying ? iframeVideo.pause() : iframeVideo.play();
              setVideoIsPlaying(!videoIsPlaying);
            }}
          />

          <Flex direction={"column"} marginLeft={"5px"} width="100%">
            <Slider
              value={iframeVideoProgress}
              onChange={(val) => videoRangeChange({ target: { value: val } })}
              colorScheme="blackAlpha"
              mt="9px"
              mb="0"
            >
              <SliderTrack>
                <SliderFilledTrack id="imagesVideo" />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Box width="100%" position={"relative"} mt="-5px">
              <VideoFrames videoUrl={videoUrl} videoDuration={videoDuration} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default SingleVideoChart;
