import styled from "@emotion/styled";

export const GraphRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: var(--space-4);
  width: 100%;
`;

export const GraphBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MetricsRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
`;
