@import "colors.scss";

.app {
  background: $white;
  min-height: 100%;
  display: flex;
  justify-items: center;
  align-content: center;
  font-size: 12px;
  font-family: "Roboto";
}
