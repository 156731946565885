import { HStack, Text } from '@chakra-ui/react';

export const SecondRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <HStack w="100%">
      <Text
        fontWeight="400"
        fontSize="xs"
        noOfLines={1}
        color="text-default-muted"
      >
        {children}
      </Text>
    </HStack>
  );
};
