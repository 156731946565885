import create from "zustand";
import { getSequences } from "../services/firestore";
import { fetchIframeSourceDoc } from "../services/studio";

export const useCreatives = create((set, get) => ({
  items: [],
  get: (buildId) => get().items.find((item) => item.buildId === buildId),
  update: (buildId, data) => {
    set((state) => ({
      items: state.items.map((item) =>
        item.buildId === buildId ? { ...item, ...data } : item
      ),
    }));
  },
  create: async (buildIds) => {
    const newCreatives = await Promise.all(
      buildIds.map(async (buildId) => {
        return {
          buildId,
          iframeSrcDoc: await fetchIframeSourceDoc(buildId),
          iframeLoaded: false,
          dwellTimes: [],
          sessionSize: null,
          scrollMode: null,
          sequences: null,
          videoSequences: null,
          creativeScrollProgress: null,
          domainsList: [],
          format: null,
        };
      })
    );

    set((state) => ({ items: [...state.items, ...newCreatives] }));
  },
  delete: (buildId) => {
    set((state) => ({
      items: state.items.filter((item) => item.buildId !== buildId),
    }));
  },
  loadCreativeSequences: async (
    minSequenceLength = 1,
    triggerType = "scrub",
    domainWhiteList = []
  ) => {
    const buildIds = get().items.map(({ buildId }) => buildId);
    await Promise.all(
      buildIds.map(async (buildId) => {
        let sequenceData = await getSequences({
          buildID: buildId,
          minSequenceLength,
          triggerType,
          filterDomain: domainWhiteList,
        });
        const {
          dwellTimes,
          sessionSize,
          scrollMode,
          sequences,
          videoSequences,
          scrubBaseline,
          dwellTimeBaseline = [],
          scriptVersion,
          brandNames,
          campaignNames,
          creativeNames,
          format,
          domainsList,
        } = sequenceData.data;
        get().update(buildId, {
          dwellTimes,
          sessionSize,
          scrollMode,
          sequences,
          videoSequences,
          scrubBaseline,
          dwellTimeBaseline,
          scriptVersion,
          brandNames,
          campaignNames,
          creativeNames,
          format,
          domainsList,
        });
      })
    );
  },
  cleanUp: () => {
    set(() => ({ items: [] }));
  },
}));

// if (process.env.NODE_ENV === "development") {
//   mountStoreDevtool("CreativeStore", useCreatives);
// }
