import ScrubChart from "@components/ScrubChart/ScrubChart";
import { GUIStore } from "@stores/GUIStore";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styles from "./ABGraph.module.scss";

export const ABGraph = ({ creatives, colors }) => {
  const startProgress = GUIStore((state) => state.startProgress);
  const endProgress = GUIStore((state) => state.endProgress);

  //const domainWhiteList = GUIStore((state) => state.domainWhiteList);

  const [sequenceData, setSequenceData] = useState([]);

  const allSequencesLoaded =
    !!creatives.length &&
    creatives.map(({ sequences }) => sequences).every((seq) => seq !== null);

  const graphContainerRef = useRef(null);

  // Sequence Data processing
  useEffect(() => {
    if (!allSequencesLoaded) {
      return;
    }
    const dataWithBaseline = creatives[0].sequences.map((data, i) => {
      return {
        ...data,
        dwellTimeBaseline:
          // need to clean this up
          creatives[0].dwellTimeBaseline
            ? creatives[0].dwellTimeBaseline[i]
            : [],
        scrubBaseline: creatives[0].scrubBaseline
          ? creatives[0].scrubBaseline[i]
          : [],
        playfulnessB: creatives[1].sequences?.[i].playfulness
          ? creatives[1].sequences?.[i].playfulness
          : [],
      };
    });
    setSequenceData(dataWithBaseline);
  }, [allSequencesLoaded, creatives]);

  return (
    <div className={styles.graphParentContainer}>
      <div className={styles.graphContainer} ref={graphContainerRef}>
        <ScrubChart
          adProgress={creatives[0]?.creativeScrollProgress}
          data={sequenceData}
          startProgress={startProgress}
          endProgress={endProgress}
          isABGraph={true}
          colors={colors}
          format={creatives[0]?.format}
        />
      </div>
    </div>
  );
};

ABGraph.propTypes = {
  buidIds: PropTypes.arrayOf(PropTypes.string),
  progress: PropTypes.number,
};
