import { ChakraProps, Grid, Text } from "@chakra-ui/react";
import { CreativeCard } from "@composites/Card/CreativeCard";
import { MetaV2Document } from "@controllers/Home";
import FavoutitesCardGrid from "./FavouritesCardGrid";
import { useHistory } from "react-router-dom";

interface CreativeCardGridProps extends ChakraProps {
  creatives: MetaV2Document[];
  userFavourites: MetaV2Document[];
  toggleFavourite: (id: string) => Promise<void>;
}

const CreativeCardGrid = ({
  creatives,
  userFavourites,
  toggleFavourite,
  ...rest
}: CreativeCardGridProps) => {
  const history = useHistory();

  const handleCardClick = (e, id: string) => {
    if (e.ctrlKey || e.metaKey || e.button === 1) {
      window.open(`/creative/${id}`, "_blank");
    } else {
      history.push(`/creative/${id}`);
    }
  };

  const handleFavouriteClick = async (e, buildID) => {
    e.stopPropagation();
    await toggleFavourite(buildID);
  };

  return (
    <Grid
      templateColumns="repeat(auto-fit, 180px)"
      height="auto"
      width="100%"
      gap="16px"
      {...rest}
    >
      {creatives.map((creative) => (
        <CreativeCard
          key={creative.id}
          creative={creative}
          isFavourited={userFavourites.some((fav) => fav.id === creative.id)}
          handleFavouriteClick={(e) => handleFavouriteClick(e, creative.id)}
          handleClick={(e) => handleCardClick(e, creative.id)}
        />
      ))}
      {!creatives.length && <Text>No creatives found</Text>}
    </Grid>
  );
};

export { CreativeCardGrid, FavoutitesCardGrid };
