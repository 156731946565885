import { Center, Icon, AspectRatio } from "@chakra-ui/react";
export const IconInBox = ({
  children,
  CustomIcon,
  color = "#718096", // Chakra gray.500
  width = "24px",
  height = "24px",
  padding = "4px",
  ...props
}) => {
  return (
    <Center padding={padding} borderRadius="3" backgroundColor={`${color}20`}>
      <Icon as={CustomIcon} color={color} w={width} h={height} />
    </Center>
  );
};
