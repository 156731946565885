import {
  ChakraProps,
  HStack,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { SecondRow } from "./SecondRow";
import { titleBarParentStyle } from "./titleBarParentStyle";

// ----------------------------------------------------------------------
// TitleBar Component
// Used for anything that has a Title and needs to be displayed
// in a card / list or bar.
export interface TitleBarProps extends ChakraProps {
  children?: ReactNode;
  title?: string;

  lefticon?: ReactNode;
  righticon?: ReactNode;
  secondRow?: ReactNode;

  isSelected?: boolean;
  color?: string;

  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
  tooltip?: string | boolean; // if NOT FALSE will show a tooltip on hover
  // pulling either from the 'tooltip' prop or the 'title' prop if there is no tooltip prop
}
// Remember to port across changes to this component to the ExpandableTitleBar component

export const TitleBar = ({
  children,
  title = "",
  secondRow,
  isSelected,
  tooltip = true,
  color = "text-default",
  onClick,
  ...props
}: TitleBarProps) => {
  const selectedBg = useColorModeValue("gray.200", "gray.700");
  const hoverColor = useColorModeValue("black", "white");
  const isClickable = Boolean(onClick);
  const showTooltip = tooltip !== false;
  const toolTipLabel = typeof tooltip === "string" ? tooltip : title;

  return (
    <VStack
      cursor={isClickable ? "pointer" : "default"}
      onClick={onClick}
      {...titleBarParentStyle}
      {...(isSelected && { bg: selectedBg })}
      {...props}
    >
      <HStack w="100%" spacing={1.5}>
        {props.lefticon}

        <Tooltip
          key={title}
          label={toolTipLabel}
          placement="right"
          openDelay={500}
          isDisabled={!showTooltip}
        >
          <Text
            w="100%"
            fontWeight="500"
            fontSize="sm"
            noOfLines={1}
            wordBreak={"break-all"}
            color={color}
            {...(isClickable && {
              _hover: { color: hoverColor },
              _groupHover: { color: hoverColor },
            })}
          >
            {title}
          </Text>
        </Tooltip>

        {children}
        {props.righticon}
      </HStack>

      {secondRow && <SecondRow>{secondRow}</SecondRow>}
    </VStack>
  );
};
