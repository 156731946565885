import { Flex, Text } from "@chakra-ui/react";
export const ScrubLabels = ({
  xAxisLabel = "Ad Progress",
  yAxisLabel = "Ad Engagement",
  bottomLeftLabel = "Scroll in",
  bottomRightLabel = "Scroll out",
  ...rest
}) => {
  const queryParams = new URLSearchParams(location.search);
  const isPresentationMode = queryParams.get("layoutMode") === "presentation";

  return (
    <>
      <Flex
        w="360px"
        justifyContent={"center"}
        position={"relative"}
        transformOrigin={"top left"}
        transform={"rotate(270deg)"}
        {...rest}
      >
        <Text
          fontSize={isPresentationMode ? "26px" : "sm"}
          color={"blackAlpha.500"}
        >
          {yAxisLabel}
        </Text>
      </Flex>
      <Flex
        {...rest}
        position="relative"
        top="-50px"
        w="100%"
        justifyContent={"space-between"}
      >
        <Text
          pl={12}
          fontSize={isPresentationMode ? "md" : "xs"}
          color={"blackAlpha.500"}
        >
          {bottomLeftLabel}
        </Text>
        <Text
          fontSize={isPresentationMode ? "lg" : "sm"}
          color={"blackAlpha.500"}
        >
          {xAxisLabel}
        </Text>
        <Text
          mr={2}
          fontSize={isPresentationMode ? "lg" : "xs"}
          color={"blackAlpha.500"}
        >
          {bottomRightLabel}
        </Text>
      </Flex>
    </>
  );
};
