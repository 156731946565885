import { faChartPie, faFilm, faPoll } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SpinnyCat, Text } from "@ui-lib";
import React, { useEffect, useState } from "react";
import { prepTopVideo, prepVideoTreeData } from "../../util/dcoaUtils";
import styles from "./CATRouselViewer.module.scss";
import { RelativeScoreCompareChart } from "./RelativeScoreCompare";
import { VideoDropOffCompare } from "./VideoDropOffCompare";
import { VideoSelector } from "./VideoSelector";

export const DCOAVideo = ({
  currentDomainRecommendations,
  creativeData,
  buildID,
}) => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(1);
  const [selectedVideo, setSelectedVideo] = useState();
  const [hoveredVideo, setHoveredVideo] = useState();

  const onAreaSelected = ({ start, end }) => {
    setStart(start);
    setEnd(end);
  };

  const [chartData, setChartData] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  useEffect(() => {
    if (creativeData?.DCOA && currentDomainRecommendations) {
      const recoPrep = prepTopVideo(currentDomainRecommendations);
      setRecommendations(recoPrep);

      const chartData = prepVideoTreeData(recoPrep);
      setChartData(chartData);
    }
  }, [creativeData, currentDomainRecommendations]);

  const onHoveredItem = (id) => {
    setHoveredVideo(id);
  };

  const onSelectedItem = (id) => {
    setSelectedVideo(id);
    setEnd(1);
    setStart(0);
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div>
        <div name="topProducts" className={styles.sectionTitle}>
          <FontAwesomeIcon icon={faFilm} />
          DCOA Video Assets ({recommendations.length})
          <Text type="body02" as={"p"}>
            These are all the videos that we tested for this campaign
          </Text>
        </div>

        <VideoSelector
          recommendations={recommendations}
          onSelect={onSelectedItem}
          onMouseEnterLeave={onHoveredItem}
          highlightedId={hoveredVideo}
          start={start}
          end={end}
          selectedId={selectedVideo}
          winnerId={chartData?.[0]?.name}
        />
        <div name="topProducts" className={styles.sectionTitle}>
          <FontAwesomeIcon icon={faPoll} />
          Drop Off Visualisation
          <Text type="body02" as={"p"}>
            Compare how your videos performed
          </Text>
        </div>

        <VideoDropOffCompare
          buildId={buildID}
          onAreaSelected={onAreaSelected}
          selectedVideo={selectedVideo ?? hoveredVideo}
          recommendations={recommendations}
        />
      </div>
      <div>
        <div name="topProducts" className={styles.sectionTitleWeight}>
          <FontAwesomeIcon icon={faChartPie} />
          Weight Distribution
        </div>
        {!!chartData ? (
          <RelativeScoreCompareChart
            data={chartData}
            onItemHoverLeave={onHoveredItem}
          />
        ) : (
          <SpinnyCat size="s" />
        )}
      </div>
    </div>
  );
};
