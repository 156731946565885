import firebase from "@services/firestore";
import { mountStoreDevtool } from "simple-zustand-devtools";
import create from "zustand";

const CATRouselViewerStore = create((set, get) => ({
  comparisonCreativeID: null,
  databaseError: false,
  errorMessage: "",
  scrollMode: "",
  dwellTimes: [],
  sequences: [],
  comparisonDwellTimes: [],
  comparisonSequences: [],
  sessionSize: 0,
  comparisonSessionSize: 0,
  scriptVersion: "",
  comparisonCreativeChanged: false,
  setComparisonCreativeID: (buildID) => {
    set({ comparisonCreativeID: buildID });
  },
  setDatabaseError: (value) => {
    set({ databaseError: value });
  },
  loadCreativeSequences: (buildID, minSequenceLength, triggerType, type) => {
    set({ sequences: [], dwellTimes: [] });
    const dataRef = firebase
      .firestore()
      .collection("CAT3")
      .doc(buildID)
      .collection(
        buildID === "nayoyo"
          ? "sessions"
          : triggerType === "scrub"
          ? "scrubSessions"
          : "dwellTimeSessions"
      );
    dataRef
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          set({
            errorMessage:
              "Creative hasn't registered any data in CAT yet. Please wait for the creative to go live.",
            databaseError: true,
          });
        } else {
          let sequencesCollector = [];
          let dwellTimeCollector = Array(101).fill(0);
          let dwellTimeCounter = Array(101).fill(0);
          let sessionSize = 0;
          let sequencesLength = 0;
          querySnapshot.forEach((doc) => {
            const sequences = doc.data().sequences ? doc.data().sequences : [];
            const dwellTime = doc.data().dwellTime ? doc.data().dwellTime : [];
            sequencesLength += sequences.length;
            if (sequences.length >= minSequenceLength) {
              sequencesCollector = [...sequencesCollector.concat(sequences)];
              sessionSize++;
            }
            if (dwellTime.length)
              dwellTime.forEach((time, i) => {
                dwellTimeCollector[i] += time;
                dwellTimeCounter[i]++;
              });
          });

          if (
            sequencesCollector.length &&
            sequencesCollector[0].startProgress
          ) {
            set({
              scrollMode: "progress",
            });
          } else {
            set({ scrollMode: "timeline" });
          }
          const dwellTimes = dwellTimeCollector.map((time, i) => {
            return {
              timeline: i,
              dwellTime: +(time / dwellTimeCounter[i]).toFixed(2),
              counter: dwellTimeCounter[i],
            };
          });

          if (type === "original") {
            set({ dwellTimes: dwellTimes, sessionSize: sessionSize });
            set((prevState) => {
              return {
                ...prevState,
                sequences: sequencesCollector,
                errorMessage: "",
                databaseError: false,
              };
            });
          } else {
            set({
              comparisonDwellTimes: dwellTimes,
              comparisonSessionSize: sessionSize,
            });
            set((prevState) => {
              return {
                ...prevState,
                comparisonSequences: sequencesCollector,
                errorMessage: "",
                databaseError: false,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error getting document:", error);
        set({
          errorMessage: error,
          databaseError: true,
        });
      });
    // const rootDataRef = firebase.firestore().collection("CAT3").doc(buildID);
    // rootDataRef.get().then((querySnapshot) => {
    //   const scriptVersion = querySnapshot.data().scriptVersion
    //     ? querySnapshot.data().scriptVersion
    //     : "unknown";
    //   set({ scriptVersion: scriptVersion });
    // });
  },
  setComparisonCreativeChanged: (value) => {
    set({ comparisonCreativeChanged: value });
  },
  clearSequences: () => {
    set({ sequences: [], creativeScrollProgress: 0 });
  },
  clearBuildID: () => {
    set({ buildID: "" });
  },
}));
if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("CATRouselViewerStore", CATRouselViewerStore);
}
export { CATRouselViewerStore };
