import { TriangleUpIcon, WarningIcon } from "@chakra-ui/icons";
import {
  ChakraProps,
  Button,
  HStack,
  Icon,
  Text,
  Tooltip,
  Image,
  Flex,
  Box,
} from "@chakra-ui/react";
import { TitleBar } from "@composites/TitleBar";
import { MetaV2Document } from "@controllers/Home";
import {
  METABABY_IMAGE_URLS,
  SESSION_SIZE_WARNING_BENCHMARK,
} from "@controllers/Home/consts";
import { GGCard } from "./index";
import { AnimatedAdPreviewThumb } from "./AnimatedAdPreviewThumb";
import { BiSolidStar, BiSolidVideo, BiStar } from "react-icons/bi";
import { useState } from "react";
import gumgumPlaceholder from "@assets/images/gumgum-placeholder.svg";

// Used whenever we want to display a creative in a card format
// might also work for other things like components and templates.
// Based on the GGCard component, which handles size and image layout

interface CreativeCardProps extends ChakraProps {
  creative: MetaV2Document;
  isFavourited?: boolean;
  handleClick?: () => void;
  handleFavouriteClick?: (id: string) => Promise<void>;
}

export function CreativeCard({
  isFavourited = false,
  handleClick,
  handleFavouriteClick,
  creative,
  ...rest
}: CreativeCardProps) {
  const {
    id,
    aggregatedSessionSize,
    brand,
    campaign,
    format,
    vertical,
    favCount,
  } = creative;
  const mockPreviewImage = getPreviewImageUrl(id, format);
  return (
    <GGCard
      topRightIcon={
        <FavoriteIcon
          isFavourited={isFavourited}
          favCount={favCount}
          onClick={handleFavouriteClick}
        />
      }
      thumbnailComponent={
        mockPreviewImage ? (
          <AnimatedAdPreviewThumb filmstripUrl={mockPreviewImage} />
        ) : (
          <Image src={gumgumPlaceholder} alt="placeholder" w="100%" h="100%" />
        )
      }
      onClick={handleClick}
      {...rest}
    >
      <>
        <TitleBar
          title={campaign}
          secondRow={brand}
          righticon={
            <Flex direction="row" alignItems="center">
              <VideoIcon containsVideo={creative.containsVideo} />
              <SessionSizeWarningIcon sessionSize={aggregatedSessionSize} />
            </Flex>
          }
        />

        {/* THIRD ROW ----------------*/}
        <HStack
          w="100%"
          color="text-default-muted"
          justifyContent="space-between"
          px={2}
          pb={0.5}
          gap="0"
        >
          {/* -----------------------*/}
          {/* FORMAT           */}
          <Text
            fontSize="xs"
            noOfLines={1}
            textTransform={"capitalize"}
            color="gray.500"
            w="100%"
          >
            {vertical}
          </Text>
          <HStack spacing={1.5}>
            <Text
              fontSize="xs"
              textTransform={"uppercase"}
              fontFamily={"mono"}
              color="gray.500"
            >
              {id}
            </Text>
            <Text
              fontSize="xs"
              textTransform={"capitalize"}
              fontWeight={"bold"}
              color={"gray.400"}
              fontFamily={"mono"}
            >
              {formatIcon[format] || "??"}
            </Text>
          </HStack>
        </HStack>
      </>
    </GGCard>
  );
}

const SessionSizeWarningIcon = ({ sessionSize }) => {
  if (sessionSize > SESSION_SIZE_WARNING_BENCHMARK) return null;

  return (
    <Tooltip
      label={`Not enough impressions to show a meaningful result yet. Check back later.`}
      borderRadius={4}
      bg="red.400"
      w="180px"
      hasArrow
      p={3}
    >
      <WarningIcon boxSize={3} color="red.300" ml={1} />
    </Tooltip>
  );
};

const VideoIcon = ({ containsVideo }) => {
  if (!containsVideo) return null;
  // Needed to add an extra Box to wrap the Icon to fix the alignment and tooltip
  return (
    <Tooltip
      label={`Creative contains video`}
      borderRadius={4}
      bg="text-default"

      hasArrow
      p={3}
    >
      <Box boxSize={3}  ml={1}>
        <Icon as={BiSolidVideo} boxSize={3} color="text-default" />
      </Box>
    </Tooltip>
  );
};

const FavoriteIcon = ({ isFavourited, favCount, onClick }) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async (e) => {
    e.stopPropagation();
    setLoading(true);
    await onClick(e);
    setLoading(false);
  };

  return (
    <Button
      pl="0.5"
      pr="0"
      py="1"
      h="auto"
      top={0.5}
      right="0"
      borderRadius="full"
      position="absolute"
      variant="outline"
      borderColor="gray.100"
      isLoading={loading}
      _hover={{
        bg: "gray.200",
        border: "1px solid",
        borderColor: "gray.300",
      }}
      _active={{
        bg: "gray.300",
        borderColor: "gray.400",
      }}
      onClick={handleClick}
      rightIcon={
        <Icon
          as={isFavourited ? BiSolidStar : BiStar}
          color={isFavourited ? "#ffc20b" : "gray.400"}
          filter={isFavourited ? "drop-shadow(0 1px 0 #82610054)" : ""}
          fontSize="1.2em"
        />
      }
    >
      <Text
        as="span"
        mr="-1.5" // trying to fix annoying padding-inline-start thing
        fontSize="xs"
        color="gray.500"
      >
        {favCount > 0 ? favCount : ""}
      </Text>
    </Button>
  );
};

const formatIcon = {
  "hang-time": "HT",
};

const getPreviewImageUrl = (id, format) => {
  if (format === "hang-time") {
    return (
      METABABY_IMAGE_URLS.hangtime[0] + id + METABABY_IMAGE_URLS.hangtime[1]
    );
  }

  return false;
};
