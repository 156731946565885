import { useRef, useState } from "react";
import { LineChart } from "@components/Charts/LineChart";
import {
  Box,
  Flex,
  Text,
  Divider,
  Grid,
  GridItem,
  Image,
  useTheme,
  HStack,
} from "@chakra-ui/react";
import { PreviewWrapper } from "./PreviewWrapper";
import { Control } from "./Control";
import { PercentChangeValue } from "../../components/Charts/PercentChangeValue.jsx";
import { CustomTooltip } from "../../components/UI/Tooltip/CustomTooltip";

export const DcoItem = ({ parentBuildID, history, variationId, active }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [historyIndex, setHistoryIndex] = useState(history.length - 1);

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        direction: "row",
        with: "100%",
        position: "relative",
      }}
    >
      <Grid gridTemplateColumns="250px 1fr" gap={2}>
        <GridItem>
          <PreviewWrapper
            buildID={parentBuildID}
            dcoVariation={variationId}
            title={history[historyIndex].name}
            color={history[historyIndex].color}
            setScrollProgress={setScrollProgress}
            scale="0.6"
            position={{ top: "0px", right: "0px" }}
          />
        </GridItem>
        <GridItem>
          <Flex direction="row" justifyContent={"space-between"}>
            <HStack
              margin="10px 10px 10px 0px"
              width="150px"
              justify="space-between"
              direction="row"
            >
              <Text fontSize="sm">Weighting:</Text>
              <Text fontWeight={500} fontSize="lg">
                {(history[historyIndex].weighting * 100).toFixed(1)}%
              </Text>
              <Text ml={4} fontSize="sm">
                Change:
              </Text>
              <PercentChangeValue
                size="lg"
                value={history[historyIndex].weighting}
                startValue={history[0].weighting}
              />
              <CustomTooltip label="The weighting of impressions this variation is recommended to receive." />
            </HStack>
            <Flex position={"relative"}>
              <Control
                active={active}
                index={historyIndex}
                setIndex={setHistoryIndex}
                history={history}
                showButton={true}
                showTrack={false}
                Chart={
                  <Flex
                    height={"30px"}
                    borderRadius={"sm"}
                    position="absolute"
                    top="0px"
                    left="0px"
                  >
                    <LineChart
                      fill={true}
                      height={60}
                      animationDuration={0}
                      width={200}
                      xAxisDomain={[0, history.length - 1]}
                      yAxisDomain={[0, 1]}
                      series={[
                        {
                          key: "weighting",
                          data: history
                            .map((h) => h.weighting)
                            .splice(0, historyIndex + 1),
                          color: theme.colors.red["600"],
                          fill: true,
                        },
                      ]}
                    />
                  </Flex>
                }
              />
            </Flex>
          </Flex>
          <Flex direction="column" height="100%" width="800px">
            <Divider margin="10px 0px 30px 0px" />
            <Box overflow={"hidden"} borderRadius={"md"} height={"275px"}>
              <LineChart
                active={active}
                header={{
                  title: "Scrub Interactions",
                  keys: [{ title: "Scrubs", color: "#E53E3E" }],
                  tooltip:
                    "We track as users scroll, pause and back-track on the ad to understand which parts are most engaging.",
                }}
                fill={true}
                height={170}
                xAxisDomain={[20, 75]}
                yAxisDomain={[0, 1]}
                series={[
                  {
                    key: "scrub",
                    data: history[historyIndex].data,
                    color: theme.colors.red["600"],
                    fill: true,
                  },
                ]}
                adProgress={scrollProgress}
              />

              <div
                style={{
                  overflow: "hidden",
                  height: "100%",
                  position: "relative",
                  top: "-30px",
                }}
              >
                <Image
                  pos="relative"
                  top="-7px"
                  src={`https://meta-baby.uc.r.appspot.com/${parentBuildID}/375x745/hang-time/filmstrip?variationID=${variationId}&styles=[".content{opacity:0.3;}\n.page-style-article%20header{display:none;}"]&scrollToElement.offset=-362&screenshotSize=14&scrollIncrement=60`} // ?styles=[".content{opacity:0.3;}\n.page-style-article%20header{display:none;}"]&scrollToElement.offset=-362&screenshotSize=14&scrollIncrement=60
                />
              </div>
            </Box>
            {/* NOTE: leaving this dwell time chart commented out as it's working but not currently needed
            <Text marginBottom="2" fontSize="sm">
              Dwell Time
            </Text>
            <LineChart
              fill={true}
              height={50}
              xAxisDomain={[2.5, 7.5]}
              yAxisDomain={[0, 1]}
              series={[
                {
                  key: "scrub",
                  // TODO - Dodgy! tidy this hack up. Need to remove the values that
                  // are out of view (on the x axis) so they're not computed
                  // when the heat chart colours are added.
                  data: history[historyIndex].dwellTime.map((d, i) => {
                    if ([0, 1, 2, 8, 9, 10].includes(i)) {
                      return 0;
                    }
                    return d;
                  }),
                  color: "#E53E3E", // recharts won't take the chakra color name
                },
              ]}
              type="heat"
              // adProgress={scrollProgress / 10}
            /> */}
          </Flex>
        </GridItem>
      </Grid>
    </div>
  );
};
