import { mountStoreDevtool } from "simple-zustand-devtools";
import create from "zustand";
import { fetchMetrics } from "../services/theplayground";

export const compareRules = {
  ER: "ER",
  EAT: "EAT",
};

export const useMetrics = create((set, get) => ({
  items: [],
  update: (buildId, metricsUpdates) => {
    set((state) => ({
      items: state.items.map((item) => {
        if (item.buildId === buildId) {
          item = { ...item, ...metricsUpdates };
        }
        return item;
      }),
    }));
  },
  get: (buildId) => get().items.find((item) => item.buildId === buildId),
  fetchMetrics: async (buildId, domains = []) => {
    const {
      attentionTime,
      benchmarkEngagementRate,
      benchmarkEngagedAttentionTime,
      engagementRate,
      engagedAttentionTime,
    } = await fetchMetrics(buildId, domains);

    let items = get().items;
    const currentMetrics = items.find((item) => item.buildId === buildId);
    if (!currentMetrics) {
      items.push({
        buildId,
        attentionTime,
        benchmarkEngagementRate,
        benchmarkEngagedAttentionTime,
        engagementRate,
        engagedAttentionTime,
      });
    } else {
      items = items.map((item) =>
        item.buildId === buildId
          ? {
              ...item,
              attentionTime,
              benchmarkEngagementRate,
              benchmarkEngagedAttentionTime,
              engagementRate,
              engagedAttentionTime,
            }
          : item
      );
    }

    set(() => ({
      items: [...items],
    }));
  },
  create: async (buildIds) => {
      for (let buildId of buildIds) {
        try {
          await get().fetchMetrics(buildId);
        } catch (error) {
          //Todo: surface errors if any
          set((state) => ({
            items: [
              ...state.items,
              {
                buildId,
                attentionTime: null,
                benchmarkEngagementRate: null,
                benchmarkEngagedAttentionTime: null,
                engagementRate: null,
                engagedAttentionTime: null,

                error: {
                  attentionTime: "Not enough data",
                  engagementRate: "Not enough data",
                  engagedAttentionTime: "Not enough data",
                },
              },
            ],
          }));
        }
      }
  },

  getBestPerforming: () => {
    const metrics = [...get().items];

    const params = new URLSearchParams(window.location.href.split("?")[1]);
    const rule = params.get("compareRule");

    if (metrics.length < 2) {
      return;
    }
    switch (rule) {
      default:
      case compareRules.EAT: {
        const { buildId } = metrics.sort(
          (a, b) =>
            Number(b.engagedAttentionTime) - Number(a.engagedAttentionTime)
        )[0];
        return {
          rule: "EAT",
          buildId,
        };
      }
      case compareRules.ER: {
        const { buildId } = metrics.sort(
          (a, b) => Number(b.engagementRate) - Number(a.engagementRate)
        )[0];
        return {
          rule: "ER",
          buildId,
        };
      }
    }
  },
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("MetricsStore", useMetrics);
}
