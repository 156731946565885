import styled from "@emotion/styled";
import { Text } from "@ui-lib";
import React, { useEffect, useRef, useState } from "react";
export const VideoPlayer = ({
  id,
  src,
  onMouseEnterLeave,
  onSelect,
  isSelected = null,
  color,
  start = 0,
  end = 1,
  isUserMuted = true,
  isHighlighted = null,
  isWinner = false,
}) => {
  const ref = useRef();

  const onMouseEnter = (id) => {
    onMouseEnterLeave(id);
    if (!isSelected) {
      ref.current.muted = true;
      ref.current.playbackRate = 5;
      ref.current.play();
    }
  };

  const onMouseLeave = () => {
    onMouseEnterLeave();
    if (!isSelected) {
      ref.current.currentTime = 0;
      ref.current.pause();
    }
  };

  const playClip = (e) => {
    const { duration, currentTime } = e.target;
    if (currentTime >= duration * end) {
      e.target.currentTime = duration * start;
    }

    if (currentTime < duration * start) {
      e.target.currentTime = duration * start;
    }
  };

  useEffect(() => {
    ref.current.currentTime = 0;
    if (isSelected) {
      ref.current.muted = isUserMuted || false;
      ref.current.playbackRate = 1;
      ref.current.play();
    } else {
      ref.current.pause();
    }

    return () => {
      ref.current.pause();
    };
  }, [isSelected, isUserMuted]);

  useEffect(() => {
    if (isSelected) {
      ref.current.currentTime = 0;
      ref.current.addEventListener("timeupdate", playClip, false);
      ref.current.muted = true;
      ref.current.play();
    } else {
      ref.current.removeEventListener("timeupdate", playClip);
      ref.current.pause();
    }

    return () => {
      ref.current.removeEventListener("timeupdate", playClip);
      ref.current.pause();
    };
  }, [end]);

  const [duration, setDuration] = useState();

  return (
    <Card isSelected={isSelected} isHighlighted={isHighlighted}>
      {isWinner && <WinnerTag>🏆</WinnerTag>}
      <Video
        ref={ref}
        width={200}
        src={src}
        onMouseEnter={() => onMouseEnter(id)}
        onMouseLeave={() => onMouseLeave()}
        onClick={() => onSelect(isSelected ? null : id)}
        muted={true}
        loop={true}
        accentColor={color}
        onLoadedMetadata={(e) => setDuration(e.target.duration)}
      />
      <Text type="body02" as={"p"}>
        Duration: {duration ? `${duration.toFixed(2)}s` : "..."}
      </Text>
    </Card>
  );
};

const WinnerTag = styled.span`
  font-size: 35px;
  position: absolute;
  top: calc(100% - 60px);
  right: -10px;
  transform: rotate(5deg);
  transition: transform 0.25s ease-in-out;
`;

const Video = styled.video`
  opacity: ${({ fade }) => (fade ? "0.6" : "1")};
  background-color: ${({ accentColor }) => accentColor ?? "transparent"};
  border: 4px solid ${({ accentColor }) => accentColor ?? "transparent"};
  cursor: pointer;
`;

const Card = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  perspective: 1000px;

  transition: transform 0.25s ease-in-out;

  ${({ isHighlighted }) =>
    isHighlighted
      ? "transform: scale(1.02) rotateX(6deg) translateY(-6px);"
      : ""}

  ${({ isSelected }) =>
    isSelected ? "transform: scale(1.1) rotateX(0deg) translateY(0px); " : ""}
  

  &:hover {
    ${({ isSelected }) =>
      !isSelected
        ? "transform: scale(1.02) rotateX(6deg) translateY(-6px);"
        : ""}

    span {
      transform: rotate(9deg);
    }
  }
`;
