@import "colors.scss";

.dontCry {
  font-family: "Roboto";
  .wrapper {
    width: 100vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    .gameWrapper {
      font-family: "Roboto Mono";
      width: 100vw;
      display: block;
      position: relative;
      text-align: center;
    }
    h1 {
      margin-top: 70px;
    }
    h1,
    h2,
    p {
      color: $grey;
      font-family: "Roboto Mono";
    }
  }
}
