@import "colors.scss";

.comparisonSelector {
  color: $lightgrey;
  display: inline-flex;
  float: right;
  font-size: 12px;
  justify-content: flex-end;
  align-items: center;
  .title {
    padding: 8px;
  }
  .select {
    min-width: 300px;
  }
  .actionButton {
    align-items: center;
    background-color: $lightergrey;
    border-radius: 4px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    padding: 0 20px;
    margin-left: 10px;
    cursor: not-allowed;
    &.save {
      background: $pink;
      color: $white;
      cursor: pointer;
      transition: all 100ms;
      &:hover {
        box-shadow: $shadow50med;
        background: $darkgrey;
        color: $white;
      }
    }
  }
}
