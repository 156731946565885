import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";

export const ProgressSlider = ({
  currentProgress,
  playFromPercent,
  sliderTrackStyle = {},
}) => {
  return (
    <Slider
      key="scroll-slider"
      position="absolute!important"
      focusThumbOnChange={false}
      overflow="hidden"
      top="0"
      display="flex"
      alignItems="flex-end"
      h="100%"
      value={(currentProgress / 100) * 100}
      onChange={(value) => {
        console.log(value);
        playFromPercent(value);
      }}
      colorScheme="blackAlpha"
    >
      <SliderTrack bottom="0" bg="none" h="100%">
        <SliderFilledTrack
          bg="#00BA77"
          opacity={0.02}
          style={{ boxShadow: "1px 0 10px 0px #00BA77" }}
          {...sliderTrackStyle}
        />
      </SliderTrack>
      <SliderThumb h="100%" w="1px" bg="#00BA77" />
    </Slider>
  );
};
