import ReactDOM from "react-dom";

export const withAlertsRender = (Component) => (props) => {
  return ReactDOM.createPortal(
    <Component {...props} />,
    document.getElementById("alerts")
  );
};

export const withShootRender = (Component) => (props) => {
  return ReactDOM.createPortal(
    <Component {...props} />,
    document.getElementById("shoot")
  );
};
