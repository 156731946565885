import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import { useEffect, useState } from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { getOverlapScrollsData } from "../util/sequencesProcessor";
import { theme } from "@chakra-ui/react";

const firebaseConfig = {
  apiKey: "AIzaSyDpUhDd45mMt7ziJb1lH65qgEKKjVKW0Uw",
  authDomain: "creative-analytics-tool-2.firebaseapp.com",
  databaseURL: "https://creative-analytics-tool-2.firebaseio.com",
  projectId: "creative-analytics-tool-2",
  storageBucket: "creative-analytics-tool-2.appspot.com",
  messagingSenderId: "635812627036",
  appId: "1:635812627036:web:95a138785f35da32e7dc99",
  measurementId: "G-BH0QRR4MHX",
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}
export default firebase;

// While testing un-deployed callable functions using the firebase emulator
// the below line needs to be uncommented to direct requests to the emulator!
// firebase.functions().useEmulator("localhost", 5001);

export const getSequences = (...restArgs) =>
  firebase.functions().httpsCallable("getBuildSequences")(...restArgs);

export const reportCreative = firebase
  .functions()
  .httpsCallable("CATReportCreative");

export const getRawSessionsData = firebase
  .functions()
  .httpsCallable("getRawSessionsData");

export const getHomepageCache = firebase
  .functions()
  .httpsCallable("getHomepageCache");

export const aggregateCatData = firebase
  .functions()
  .httpsCallable("aggregateCatData");

/**
 * A function to fetch the aggregated data for a given buildId
 * @param {string} buildId
 */
export const fetchAggregatedCreativeData = async (buildId) => {
  const docRef = firebase.firestore().collection("CAT3").doc(buildId);
  const snapshot = await docRef.get();
  if (snapshot.exists) {
    let data = snapshot.data();
    // if there's no aggregated data, we'll generate it and save it for next time
    if (!data.aggregatedSessionSize) {
      console.log("Need to aggregate data for buildId: ", buildId);
      const aggregatedData = await aggregateCatData({ buildId });
      data = { ...data, ...aggregatedData.data };
    }
    const domainRef = firebase
      .firestore()
      .collection("CAT3")
      .doc(buildId)
      .collection("domains");
    const domainSnapshot = await domainRef.get();
    data.domains = domainSnapshot.docs.map((doc) => ({
      domain: doc.id,
      ...doc.data(),
    }));
    return data;
  } else {
    throw "No data for that build id.";
  }
};

export const fetchBaseline = async (format = "hang-time") => {
  const dataRef = firebase
    .firestore()
    .collection("Vertical-Baselines")
    .doc("baseline")
    .collection(format)
    .doc("current");

  const querySnapshot = await dataRef.get();

  if (querySnapshot.exists) {
    return querySnapshot.data();
  } else {
    throw "No baseline data.";
  }
};

export const fetchVideoBaselines = async (format = "hang-time") => {
  const dataRef = firebase
    .firestore()
    .collection("Video-Baselines")
    .doc(format);

  const querySnapshot = await dataRef.get();

  if (querySnapshot.exists) {
    const data = querySnapshot.data();
    return data.byDurationVideoDropOffBaselines;
  } else {
    throw "No baseline data.";
  }
};

export const fetchMetas = async (buildId) => {
  const rootDataRef = firebase.firestore().collection("CAT3").doc(buildId);
  return await rootDataRef.get().then((querySnapshot) => {
    const scriptVersion = querySnapshot.data().scriptVersion
      ? querySnapshot.data().scriptVersion
      : "unknown";
    const brandNames = querySnapshot.data().brandNames
      ? querySnapshot.data().brandNames
      : "";
    const campaignNames = querySnapshot.data().campaignNames
      ? querySnapshot.data().campaignNames
      : "";
    const creativeNames = querySnapshot.data().creativeNames
      ? querySnapshot.data().creativeNames.split("- Approved")[0]
      : "";
    const format = querySnapshot.data().format
      ? querySnapshot.data().format
      : "hang-time";

    return {
      scriptVersion,
      brandNames,
      campaignNames,
      creativeNames,
      format,
    };
  });
};

export const loadCreativeSequences = async (
  buildId,
  minSequenceLength,
  triggerType
) => {
  const dataRef = firebase
    .firestore()
    .collection("CAT3")
    .doc(buildId)
    .collection(
      buildId === "nayoyo"
        ? "sessions"
        : triggerType === "scrub"
        ? "scrubSessions"
        : "dwellTimeSessions"
    );

  let querySnapshot;

  try {
    querySnapshot = await dataRef.get();
  } catch (error) {
    console.error("Error getting document");
    throw error;
  }

  if (querySnapshot.empty) {
    throw "Creative hasn't registered any data in CAT yet. Please wait for the creative to go live.";
  }

  const response = querySnapshot.docs
    .map((doc) => doc.data())
    .reduce(
      (acc, { sequences, dwellTime }, i, array) => {
        if (sequences.length >= minSequenceLength) {
          acc.sequences = [...acc.sequences, ...sequences];
          acc.sessionSize++;
        }
        if (dwellTime.length) {
          dwellTime.forEach((time, i) => {
            acc.dwellTimes[i].dwellTime += time / array.length; //Purposely not rounding here as we'd lose accuracy
            acc.dwellTimes[i].counter = array.length; //TODO: check if that is used
          });
        }

        return acc;
      },
      {
        dwellTimes: Array.from({ length: 101 }, (_, timeline) => ({
          timeline,
          dwellTime: 0,
          counter: 0,
        })),
        sessionSize: 0,
        sequences: [],
      }
    );

  const scrollMode = response.sequences[0]?.startProgress
    ? "progress"
    : "timeline";

  return { ...response, scrollMode };
};

export const saveABPresentation = firebase
  .functions()
  .httpsCallable("saveABPresentation");

export const useCreativeData = (buildId) => {
  const [data, isLoading, error] = useDocumentDataOnce(
    firebase.firestore().collection("CAT3").doc(buildId)
  );

  const [devData = null, isLoadingDev = false, devError = null] =
    useDocumentDataOnce(
      firebase.firestore().collection("CAT-Test-Creative").doc(buildId)
    );

  const [returnData, setReturnData] = useState();

  useEffect(() => {
    devData && console.log(devData);
    setReturnData({ ...data, ...devData });
  }, [data, devData]);

  return [
    returnData,
    isLoading || isLoadingDev,
    error ? error : devError ? devError : null,
  ];
};

export const useCurrentDomainRecommendations = (buildId) => {
  let [data, setData] = useState([]);
  let [isLoaded, setIsLoaded] = useState(true);
  let [error, setError] = useState(null);

  useEffect(() => {
    firebase
      .firestore()
      .collection(
        `CAT3/${buildId}/DCOARecommendations/current/domainRecommendations`
      )
      .get()
      .then((snap) => {
        setIsLoaded(false);
        setData(snap.docs.map((doc) => doc.data()));
      })
      .catch(setError);
  }, []);

  return [data, isLoaded, error];
};

// ----------------- Catalyst/DCO ----------------- //

/**
 * Fetches data from the DCO collection in Firestore then parses and groups it
 * according to requirements on the /dco report page.
 * @param {string} buildId - the Studio buildId of the creative
 * @returns {object} - the DCO data for the /dco report
 */
export const getDCOData = async (buildId) => {
  const dcoRef = firebase.firestore().collection("DCO").doc(buildId);
  const dcoHistoryRef = dcoRef.collection("history");
  const dcoSnapshot = await dcoRef.get();
  const dcoHistorySnapshot = await dcoHistoryRef.get();
  const dcoMetaData = dcoSnapshot.data();
  const dcoHistoryData = dcoHistorySnapshot.docs.map((doc) => {
    const data = doc.data();
    data.variations.sort((a, b) => b.weighting - a.weighting);
    return data;
  });
  dcoHistoryData.sort((a, b) => a.created - b.created);

  // when we import the data here we'll assign a colour to each variation so it's consistent
  // across the report – these are a selection of the default colours from Chakra UI Theme
  // TODO - refactor this as the color and an Icon should come from Remix
  // when the DCO is created and be saved to the db (DCO variations collection)
  const colors = [
    "purple",
    "orange",
    "blue",
    "green",
    "teal",
    "cyan",
    "pink",
    "yellow",
  ];

  // the score will just be the total scrubs average (scrubsTotal / sessions)
  const overallScrubPerformance = dcoHistoryData.reduce(
    (acc, curr) => {
      const score = curr?.overall?.scrubs
        ? curr.overall.scrubs.reduce((a, c) => {
            return a + c;
          }, 0)
        : 0;
      const controlScore = curr?.control?.scrubs
        ? curr.control.scrubs.reduce((a, c) => {
            return a + c;
          }, 0)
        : 0;
      acc.overall.push(score);
      acc.control.push(controlScore);
      return acc;
    },
    { overall: [], control: [] }
  );

  // group by variation
  const historyByVariation = dcoHistoryData[
    dcoHistoryData.length - 1
  ].variations.map((item, i) => {
    const color = i > colors.length - 1 ? "blue" : colors[i];
    const variationHistory = dcoHistoryData
      .map((h) => {
        return h.variations.find((v) => v.id === item.id);
      })
      .map((v, _) => {
        return {
          color: color,
          name: item.name || "Variation " + (i + 1),
          data: v.variationSequences
            ? v.variationSequences.map((i) => i / v.sessions)
            : new Array(101).fill(0.2),
          dwellTime: groupArray(
            v.variationDwellTimes
              ? v.variationDwellTimes.map((i) => i / v.sessions)
              : new Array(101).fill(0.2)
          ),
          weighting: v.weighting > 1 ? v.weighting / 100 : v.weighting,
        };
      });

    return {
      id: item.id,
      history: variationHistory,
      color: color,
      name: item.name || "Variation " + (i + 1),
    };
  });

  return {
    dcoMetaData,
    dcoHistoryData,
    historyByVariation,
    overallScrubPerformance,
  };
};

function groupArray(originalArray) {
  const newArray = originalArray.reduce((acc, curr, index) => {
    const groupIndex = Math.floor(index / 10);
    if (!acc[groupIndex]) {
      acc[groupIndex] = 0;
    }
    acc[groupIndex] += curr;
    return acc;
  }, []);
  return newArray;
}
