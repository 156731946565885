import firebase from "./firestore";
export const getDemoSequences = (buildID, cb) => {
  const dataRef = firebase.database().ref(`catDemo/${buildID}`);
  dataRef.on("value", (snapshot) => {
    const data = snapshot.val();
    if (data) {
      cb(data);
    } else {
      throw new Error("No data found.");
    }
  });
};

export const clearDemoData = (buildID) => {
  firebase.analytics().logEvent("clear_demo_data");
  const dataRef = firebase.database().ref(`catDemo/${buildID}`);
  dataRef.set({ status: "removed" });
};

export const detachDemoDatabase = (buildID) => {
  firebase.database().ref(`catDemo/${buildID}`).off();
};
